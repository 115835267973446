import React from "react";
import {connect} from "react-redux";
import {cProject, ProjectType} from "../../models/Project";
import {cProjectReducer} from "../../api/ProjectApi/constants";
import {deleteProjectById, getProjectById} from "../../api/ProjectApi/actions";
import AttributeDetailBlock from "../../components/AttributeDetailBlock";
import {Button, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {SuccessEventCallback} from "../../models/Event";
import {push} from "connected-react-router";
import ProtectedContainer from "../ProtectedContainer";
import {Role} from "../../models/Role";

interface ProjectDetailTabContainerPropsInterface {
    projectId: string;
    project: ProjectType;

    getProjectById: (id: string) => void;
    deleteProjectById: (id: string, successCallbacks: SuccessEventCallback<void>[]) => void;
}

interface ProjectDetailTabContainerStateInterface {
    isDeleteConfirmationDialogOpen: boolean;
}

class ProjectDetailTabContainer extends React.PureComponent<ProjectDetailTabContainerPropsInterface, ProjectDetailTabContainerStateInterface> {

    state = {
        isDeleteConfirmationDialogOpen: false
    }

    componentDidMount() {
        this.props.getProjectById(this.props.projectId);
    }

    handleDeleteConfirmationDialogToggle = () => {
        this.setState((prevState) => ({
            ...prevState,
            isDeleteConfirmationDialogOpen: !prevState.isDeleteConfirmationDialogOpen
        }));
    }

    handleDelete = () => {
        this.props.deleteProjectById(
            this.props.projectId,
            [
                () => push("/project-list")
            ]
        )
    }

    render() {
        return (
            <div className="m-5">
                <Row>
                    <Col>
                        <AttributeDetailBlock title="Project name" value={this.props.project.get(cProject.name)} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <AttributeDetailBlock title="Description" value={this.props.project.get(cProject.description)} />
                    </Col>
                </Row>
                <ProtectedContainer requiredRole={Role.ADMIN}>
                    <Row className="mt-3">
                        <Col className="d-flex justify-content-end flex-grow-1">
                            <Button color="danger" className="mt-4 mr-2" onClick={this.handleDeleteConfirmationDialogToggle}>
                                <FontAwesomeIcon icon={faTrash} className="mr-3" />
                                Delete
                            </Button>
                            <Link to={`/project-form/${this.props.projectId}`}>
                                <Button color="secondary" className="mt-4">
                                    <FontAwesomeIcon icon={faEdit} className="mr-3" />
                                    Edit
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </ProtectedContainer>
                {
                    this.state.isDeleteConfirmationDialogOpen
                        ? <ConfirmationDialog
                            header="Project delete"
                            body={`Delete project ${this.props.project.get(cProject.name)}?`}
                            isOpen={this.state.isDeleteConfirmationDialogOpen}
                            handleConfirm={this.handleDelete}
                            handleCancel={this.handleDeleteConfirmationDialogToggle}
                        />
                        : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    project: state.projectApiReducer.get(cProjectReducer.project)
});

const mapDispatchToProps = (dispatch: any) => ({
    getProjectById: (id: string) => dispatch(getProjectById(id)),
    deleteProjectById: (id: string, successCallbacks: SuccessEventCallback<void>[]) => dispatch(
        deleteProjectById(id, {onSuccess: successCallbacks})
    )
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectDetailTabContainer);