import {all, call, put, takeLatest} from "redux-saga/effects";
import {DASHBOARD_ITEM_DELETE_BY_ID, DASHBOARD_ITEM_GET_ALL_BY_DASHBOARD_ID, DASHBOARD_ITEM_SAVE} from "./constants";
import {
    DeleteDashboardItemByIdActionInterface,
    GetAllDashboardItemsByDashboardIdActionInterface,
    SaveDashboardItemActionInterface
} from "./actions";
import {
    cDashboardItem,
    DashboardItemInterface,
    mapDashboardItemFromJS,
    mapDashboardItemToJS
} from "../../models/DashboardItem";
import {Network} from "../../internal/network";
import Links from "../../global/links";
import {Actions} from "../../global/actions";
import {Alert} from "../../containers/AlertContainer/actions";
import {Callback} from "../../models/Event";
import {List} from "immutable";
import {getExceptionMessage} from "../../models/ExceptionCode";

function* save(action: SaveDashboardItemActionInterface) {
    try {
        let dashboardItemId: number | undefined = action.dashboardItem.get(cDashboardItem.id);
        const payload = {body: JSON.stringify(mapDashboardItemToJS(action.dashboardItem))};
        if (dashboardItemId) {
            yield call(Network.put(Links.dashboardItem(), payload));
        } else {
            dashboardItemId = yield call(Network.post(Links.dashboardItem(), payload));
        }
        yield put(Actions.fulfilled(action.type, dashboardItemId));
        yield put(Alert.success("Dashboard item has been successfully saved."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks, dashboardItemId));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* deleteById(action: DeleteDashboardItemByIdActionInterface) {
    try {
        yield call(Network.del(Links.dashboardItemById(action.id)));
        yield put(Actions.fulfilled(action.type));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* getAllByDashboardId(action: GetAllDashboardItemsByDashboardIdActionInterface) {
    try {
        const dashboardItems: DashboardItemInterface[] = yield call(Network.get(Links.dashboardItemsByDashboardId(action.dashboardId)));
        yield put(Actions.fulfilled(action.type, List(dashboardItems.map(mapDashboardItemFromJS))))
    } catch (e) {
        yield put(Actions.rejected(action.type));
        yield put(Alert.error());
    }
}

export default function* saga() {
    yield all([
        yield takeLatest(DASHBOARD_ITEM_SAVE, save),
        yield takeLatest(DASHBOARD_ITEM_DELETE_BY_ID, deleteById),
        yield takeLatest(DASHBOARD_ITEM_GET_ALL_BY_DASHBOARD_ID, getAllByDashboardId)
    ])
}