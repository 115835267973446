import {AlertInterface, AlertMessageType, cAlert} from "../../models/Alert";
import {ALERT_ADD_FIRST, ALERT_REMOVE_LAST} from "./constants";

export interface AddFirstAlertActionInterface {
    type: string;
    alert: AlertInterface
}

export interface RemoveLastAlertActionInterface {
    type: string;
}

export const removeLastAlert = (): RemoveLastAlertActionInterface => ({
   type: ALERT_REMOVE_LAST
});

export const success = (message: string): AddFirstAlertActionInterface => ({
    type: ALERT_ADD_FIRST,
    alert: {
        [cAlert.type]: AlertMessageType.success,
        [cAlert.message]: message
    }
})

export const error = (message: string = "Something went wrong!"): AddFirstAlertActionInterface => ({
    type: ALERT_ADD_FIRST,
    alert: {
        [cAlert.type]: AlertMessageType.error,
        [cAlert.message]: message
    }
})

export const warning = (message: string): AddFirstAlertActionInterface => ({
    type: ALERT_ADD_FIRST,
    alert: {
        [cAlert.type]: AlertMessageType.warning,
        [cAlert.message]: message
    }
})

export const info = (message: string): AddFirstAlertActionInterface => ({
    type: ALERT_ADD_FIRST,
    alert: {
        [cAlert.type]: AlertMessageType.info,
        [cAlert.message]: message
    }
})

export const Alert = {
    success, error, warning, info
}