import React from "react";
import {Button, Col, Row} from "reactstrap";
import AttributeDetailBlock from "../../components/AttributeDetailBlock";
import {cTaskReducer} from "../../api/TaskApi/constants";
import {deleteTaskById, getTaskById} from "../../api/TaskApi/actions";
import {connect} from "react-redux";
import {convertAllocationTimeToMinutes, cTask, TaskType} from "../../models/Task";
import {DATE_TIME_WITHOUT_TIMEZONE_FORMAT} from "../../global/constants";
import moment from "moment";
import {getTaskStateName, TaskState} from "../../models/TaskState";
import {getTaskTargetTypeName, TaskTargetType} from "../../models/TaskTargetType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {SuccessEventCallback} from "../../models/Event";
import {push} from "connected-react-router";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {cUser} from "../../models/User";

interface TaskDetailTabContainerPropsInterface {
    taskId: string;

    task: TaskType;

    getTaskById: (id: string) => void;
    deleteTaskById: (id: string, successCallbacks: SuccessEventCallback<void>[]) => void;
}

interface TaskDetailTabContainerStateInterface {
    isDeleteConfirmationDialogOpen: boolean;
}

class TaskDetailTabContainer extends React.PureComponent<TaskDetailTabContainerPropsInterface, TaskDetailTabContainerStateInterface> {

    state = {
        isDeleteConfirmationDialogOpen: false
    }

    componentDidMount() {
        this.props.getTaskById(this.props.taskId);
    }

    handleDeleteConfirmationDialogToggle = () => {
        this.setState((prevState) => ({
            ...prevState,
            isDeleteConfirmationDialogOpen: !prevState.isDeleteConfirmationDialogOpen
        }));
    }

    handleDelete = () => {
        this.props.deleteTaskById(
            this.props.taskId,
            [
                () => push(`/project/${this.props.task.get(cTask.projectId)}`)
            ]
        );
    }

    renderDeleteConfirmationDialogBody = () => {
        return (
            <p className="text-center">
                <p>
                    {`Delete task ${this.props.task.get(cTask.name)}?`}
                </p>
                <p>
                    Please make sure that this task is <b className="text-danger">NOT in use</b>!
                    <br/>
                    Otherwise this operation may cause that target environment will <b className="text-danger">NOT</b> be cleared properly!
                </p>
            </p>
        )
    }

    render() {
        const task = convertAllocationTimeToMinutes(this.props.task);

        return (
            <div className="m-5">
                <Row>
                    <Col>
                        <AttributeDetailBlock title="Name" value={task.get(cTask.name)} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <AttributeDetailBlock title="Target type" value={getTaskTargetTypeName(task.get(cTask.targetType) || TaskTargetType.LOCAL)} />
                    </Col>
                    <Col xs={6}>
                        <AttributeDetailBlock title="State" value={getTaskStateName(task.get(cTask.state) || TaskState.READY)} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <AttributeDetailBlock title="External project ID" value={this.props.task.get(cTask.externalProjectId) || "-"} />
                    </Col>
                    <Col xs={6}>
                        <AttributeDetailBlock title="Queue type" value={task.get(cTask.queueType) || "-"} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={6}>
                        <AttributeDetailBlock title="Created on" value={moment(task.get(cTask.createdOn)).format(DATE_TIME_WITHOUT_TIMEZONE_FORMAT)} />
                    </Col>
                    <Col xs={6}>
                        <AttributeDetailBlock title="# of nodes" value={task.get(cTask.numberOfNodes) || "-"} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={6}>
                        <AttributeDetailBlock title="Updated on" value={moment(task.get(cTask.updatedOn)).format(DATE_TIME_WITHOUT_TIMEZONE_FORMAT)} />
                    </Col>
                    <Col xs={6}>
                        <AttributeDetailBlock title="# of cores / node" value={task.get(cTask.numberOfCoresPerNode) || "-"} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={6}>
                        <AttributeDetailBlock
                            title="Creator"
                            value={
                                task.get(cTask.creatorId)
                                    ? task.getIn([cTask.creator, cUser.firstName]) + " " + task.getIn([cTask.creator, cUser.lastName])
                                    : "-"}
                        />
                    </Col>
                    <Col xs={6}>
                        <AttributeDetailBlock title="Maximum allocated time" value={task.get(cTask.maximumAllocatedTime) || "-"} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={6}>
                        <AttributeDetailBlock title="Job ID" value={task.get(cTask.jobId) || "-"} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="d-flex justify-content-end flex-grow-1">
                        <Button color="danger" className="mt-4 mr-2" onClick={this.handleDeleteConfirmationDialogToggle}>
                            <FontAwesomeIcon icon={faTrash} className="mr-3" />
                            Delete
                        </Button>
                        <Link to={`/task-form/${this.props.taskId}`}>
                            <Button className="mt-4">
                                <FontAwesomeIcon icon={faEdit} className="mr-3" />
                                Edit
                            </Button>
                        </Link>
                    </Col>
                </Row>
                {
                    this.state.isDeleteConfirmationDialogOpen
                        ? <ConfirmationDialog
                            header="Task delete"
                            body={this.renderDeleteConfirmationDialogBody()}
                            isOpen={this.state.isDeleteConfirmationDialogOpen}
                            handleConfirm={this.handleDelete}
                            handleCancel={this.handleDeleteConfirmationDialogToggle}
                        />
                        : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
   task: state.taskApiReducer.get(cTaskReducer.task)
});

const mapDispatchToProps = (dispatch: any) => ({
    getTaskById: (id: string) => dispatch(getTaskById(id)),
    deleteTaskById: (id: string, successCallbacks: SuccessEventCallback<void>[]) => dispatch(
        deleteTaskById(id, {onSuccess: successCallbacks})
    )
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)
(TaskDetailTabContainer);