import React from "react";
import {connect} from "react-redux";
import {cProjectAssignmentReducer} from "../../api/ProjectAssignmentApi/constatns";
import {
    deleteProjectAssignmentById,
    getAllProjectAssignmentsByProjectId,
    saveAllProjectAssignments
} from "../../api/ProjectAssignmentApi/actions";
import {getPaginationFromPage, Pagination} from "../../models/Pagination";
import {cPage, PageType} from "../../models/Page";
import {cProjectAssignment, ProjectAssignmentType} from "../../models/ProjectAssignment";
import {cUser, UserType} from "../../models/User";
import {RouteComponentProps, withRouter} from "react-router-dom";
import PaginationControl from "../../components/PaginationControl";
import moment from "moment";
import {DATE_TIME_WITHOUT_TIMEZONE_FORMAT} from "../../global/constants";
import TableView, {ColumnDefinition} from "../../components/TableView";
import {Button, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import ProjectAssignmentContainerModal from "../../components/ProjectAssignmentModal";
import {cProjectStudentsTabReducer} from "./constants";
import {searchUserBySearchString, toggleProjectAssignmentModal} from "./actions";
import {SuccessEventCallback} from "../../models/Event";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {Set} from "immutable";
import {cUserReducer} from "../../api/UserApi/constants";
import ProtectedContainer from "../ProtectedContainer";
import {Role} from "../../models/Role";
import {cAuthReducer} from "../../api/AuthApi/constants";

interface ProjectStudentsTabContainerPropsInterface {
    projectId: string;
    projectAssignments: PageType<ProjectAssignmentType>;
    isProjectAssignmentModalOpen: boolean;
    foundUsers: PageType<UserType>;
    authUser: UserType;

    saveAllProjectAssignments: (projectAssignments: Set<ProjectAssignmentType>, successCallbacks?: SuccessEventCallback<void>[]) => void;
    deleteProjectAssignmentById: (id: string, successCallback?: SuccessEventCallback<any>) => void;
    getAllProjectAssignmentsByProjectId: (id: string, page?: Pagination) => void;
    toggleProjectAssignmentModal: () => void;
    searchUsersBySearchString: (searchString: string) => void;
}

interface ProjectStudentsTabContainerStateInterface {
    isDeleteConfirmationOpen: boolean;
    projectAssignmentToDelete?: string;
}

class ProjectStudentsTabContainer extends React.PureComponent<ProjectStudentsTabContainerPropsInterface & RouteComponentProps, ProjectStudentsTabContainerStateInterface> {

    state: ProjectStudentsTabContainerStateInterface = {
        isDeleteConfirmationOpen: false,
        projectAssignmentToDelete: undefined
    }

    componentDidMount() {
        this.props.getAllProjectAssignmentsByProjectId(this.props.projectId);
    }

    handleConfirmationDialogToggle = (id?: string) => {
        this.setState((prevState: ProjectStudentsTabContainerStateInterface) => ({
            ...prevState,
            isDeleteConfirmationOpen: !prevState.isDeleteConfirmationOpen,
            projectAssignmentToDelete: id
        }));
    }

    handleSave = (projectAssignments: Set<ProjectAssignmentType>) => {
        this.props.saveAllProjectAssignments(
                projectAssignments,
                [
                    () => toggleProjectAssignmentModal(),
                    () => getAllProjectAssignmentsByProjectId(this.props.projectId, getPaginationFromPage(this.props.projectAssignments))
                ]
            )
    }

    handleDeleteConfirm = () => {
        if (!this.state.projectAssignmentToDelete) return;

        this.props.deleteProjectAssignmentById(
            this.state.projectAssignmentToDelete || "",
            () => getAllProjectAssignmentsByProjectId(this.props.projectId)
        );
        this.handleConfirmationDialogToggle();
    }

    renderActionsColumn = (rowObject: ProjectAssignmentType) => {
        return (
            <span className="d-flex justify-content-end mr-4">
                <FontAwesomeIcon
                    icon={faTrash}
                    className="mr-4 cursor-pointer"
                    onClick={() => this.handleConfirmationDialogToggle(rowObject.get(cProjectAssignment.id))}
                />
            </span>
        )
    }

    getColumnDefinitions = (): ColumnDefinition<ProjectAssignmentType>[] => {
        const columnDefinitions: ColumnDefinition<ProjectAssignmentType>[] = [
            {
                attributeName: cUser.firstName,
                renderHeader: () => "First name",
                renderCell: rowObject => rowObject.getIn([cProjectAssignment.user, cUser.firstName])
            },
            {
                attributeName: cUser.lastName,
                renderHeader: () => "Last name",
                renderCell: rowObject => rowObject.getIn([cProjectAssignment.user, cUser.lastName])
            },
            {
                attributeName: cUser.login,
                renderHeader: () => "Login",
                renderCell: rowObject => rowObject.getIn([cProjectAssignment.user, cUser.login])
            },
            {
                attributeName: cProjectAssignment.createdOn,
                renderHeader: () => "Created on",
                renderCell: rowObject => moment.utc(rowObject.get(cProjectAssignment.createdOn)).local().format(DATE_TIME_WITHOUT_TIMEZONE_FORMAT)
            }
        ];

        if (this.props.authUser.get(cUser.role) === Role.ADMIN) {
            columnDefinitions.push({
                attributeName: "actions",
                renderHeader: () =>  <span className="d-flex justify-content-end mr-4">Actions</span>,
                renderCell: this.renderActionsColumn
            });
        }

        return columnDefinitions;
    }

    render() {
        return (
            <div className="m-5">
                <Row>
                    <Col xs={12}>
                        <TableView
                            rows={this.props.projectAssignments.get(cPage.content)}
                            rowKeyAttribute={cProjectAssignment.id}
                            // onRowClick={rowObject => this.props.history.push(`/users/${rowObject.getIn([cProjectAssignment.user, cUser.id])}`)}
                            columnDefinitions={this.getColumnDefinitions()}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <PaginationControl
                            page={this.props.projectAssignments}
                            onPageChange={(page) => this.props.getAllProjectAssignmentsByProjectId(this.props.projectId, {page})}
                        />
                    </Col>
                </Row>
                <ProtectedContainer requiredRole={Role.ADMIN}>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-end flex-grow-1">
                            <Button color="success" className="pr-5" onClick={this.props.toggleProjectAssignmentModal}>
                                <FontAwesomeIcon icon={faPlus} className="mr-5" />
                                Add
                            </Button>
                        </Col>
                    </Row>
                </ProtectedContainer>
                {
                    this.props.isProjectAssignmentModalOpen
                        ? <ProjectAssignmentContainerModal
                            projectId={this.props.projectId}
                            isOpen={this.props.isProjectAssignmentModalOpen}
                            foundUsers={this.props.foundUsers}
                            onToggle={this.props.toggleProjectAssignmentModal}
                            onSearchUsersBySearchString={this.props.searchUsersBySearchString}
                            onSaveAllProjectAssignments={this.handleSave}
                        />
                        : null
                }
                {
                    this.state.isDeleteConfirmationOpen
                        ? <ConfirmationDialog
                            header="Student removal"
                            body="Remove selected user from this project?"
                            isOpen={this.state.isDeleteConfirmationOpen}
                            handleConfirm={this.handleDeleteConfirm}
                            handleCancel={this.handleConfirmationDialogToggle}
                        />
                        : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    projectAssignments: state.projectAssignmentApiReducer.get(cProjectAssignmentReducer.projectAssignments),
    isProjectAssignmentModalOpen: state.projectStudentsTabContainerReducer.get(cProjectStudentsTabReducer.isProjectAssignmentModalOpen),
    foundUsers: state.userApiReducer.get(cUserReducer.users),
    authUser: state.authApiReducer.get(cAuthReducer.currentUser)
});

const mapDispatchToProps = (dispatch: any) => ({
    saveAllProjectAssignments: (projectAssignments: Set<ProjectAssignmentType>, successCallbacks?: SuccessEventCallback<void>[]) => dispatch(
        saveAllProjectAssignments(projectAssignments, {onSuccess: successCallbacks})
    ),
    deleteProjectAssignmentById: (id: string, successCallback?: SuccessEventCallback<any>) => dispatch(
        deleteProjectAssignmentById(id, {onSuccess: successCallback ? [successCallback] : [ ]})
    ),
    getAllProjectAssignmentsByProjectId: (id: string, page?: Pagination) => dispatch(getAllProjectAssignmentsByProjectId(id, page)),
    toggleProjectAssignmentModal: () => dispatch(toggleProjectAssignmentModal()),
    searchUsersBySearchString: (searchString: string) => dispatch(searchUserBySearchString(searchString))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)
(withRouter(ProjectStudentsTabContainer));