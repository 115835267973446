import {Immutable} from "./Immutable";
import {fromJS} from "immutable";
import * as Yup from "yup";

export enum cParameter {
    id = "id",
    name = "name",
    value = "value",
    taskId = "taskId",
    task = "task"
}

export interface ParameterInterface {
    [cParameter.id]?: number;
    [cParameter.name]: string;
    [cParameter.value]: string;
    [cParameter.taskId]?: string;
    [cParameter.task]?: string;
}

export type ParameterType = Immutable<ParameterInterface>;

export const parameterValidationSchema = Yup.object().shape({
   [cParameter.name]: Yup.string().required().matches(/^[a-zA-Z0-9_]+$/),
   [cParameter.value]: Yup.string().required()
});

export const mapParameterFromJS = (parameter: ParameterInterface): ParameterType => {
    return fromJS({
        [cParameter.id]: parameter[cParameter.id],
        [cParameter.name]: parameter[cParameter.name],
        [cParameter.value]: parameter[cParameter.value],
        [cParameter.taskId]: parameter[cParameter.taskId],
        [cParameter.task]: parameter[cParameter.task],
    })
};

export const mapParameterToJS = (parameter: ParameterType): ParameterInterface => ({
    [cParameter.id]: parameter.get(cParameter.id),
    [cParameter.name]: parameter.get(cParameter.name),
    [cParameter.value]: parameter.get(cParameter.value),
    [cParameter.taskId]: parameter.get(cParameter.taskId),
    [cParameter.task]: parameter.get(cParameter.task)
});

export const parameterDefault = (): ParameterType => fromJS({
    [cParameter.id]: null,
    [cParameter.name]: "",
    [cParameter.value]: "",
    [cParameter.taskId]: null,
    [cParameter.task]: null
});