import Cookies from "js-cookie";
import {getAuth} from "../../internal/endpoints";
import Links from "../../global/links";

export interface TokenInterface {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    scope: string;
    token_type: string;
}

export const setToken = (token: TokenInterface) => {
    const expirationInDays = token.expires_in / (60 * 60 * 24);
    return Cookies.set("token", token, {
        expires: expirationInDays,
        path: "/"
    });
}

export const hasToken = (): boolean => {
    return !!Cookies.get("token");
}

export const getToken = (): TokenInterface => {
    const token = Cookies.get("token");
    return hasToken() && token && JSON.parse(token);
}

export const removeToken = (): void => {
    return Cookies.remove("token", { path: "/" });
}

export const handleRedirectToAuthServiceLogin = () => {
    const auth = getAuth();
    const url = Links.authorization(auth.redirectUri, auth.clientId);
    // console.log("Redirecting to: " + url);
    window.location.href = url;
}