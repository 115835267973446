import * as Yup from "yup";
import {Immutable} from "./Immutable";
import {fromJS} from "immutable";
import {userDefault, UserType} from "./User";
import {projectDefault, ProjectType} from "./Project";

export enum cProjectAssignment {
    id = "id",
    userId = "userId",
    projectId = "projectId",
    user = "user",
    project = "project",
    createdOn = "createdOn"
}

export interface ProjectAssignmentInterface {
    [cProjectAssignment.id]?: string;
    [cProjectAssignment.userId]: string;
    [cProjectAssignment.projectId]: string;
    [cProjectAssignment.user]?: UserType;
    [cProjectAssignment.project]?: ProjectType;
    [cProjectAssignment.createdOn]?: string;
}

export type ProjectAssignmentType = Immutable<ProjectAssignmentInterface>;

export const projectAssignmentValidationSchema = Yup.object().shape({
    [cProjectAssignment.userId]: Yup.string().required(),
    [cProjectAssignment.projectId]: Yup.string().required()
})

export const mapProjectAssignmentFromJS = (src: ProjectAssignmentInterface): ProjectAssignmentType => {
    return fromJS({
        [cProjectAssignment.id]: src[cProjectAssignment.id],
        [cProjectAssignment.userId]: src[cProjectAssignment.userId],
        [cProjectAssignment.projectId]: src[cProjectAssignment.projectId],
        [cProjectAssignment.user]: src[cProjectAssignment.user],
        [cProjectAssignment.project]: src[cProjectAssignment.project],
        [cProjectAssignment.createdOn]: src[cProjectAssignment.createdOn],
    })
}

export const mapProjectAssignmentToJS = (src: ProjectAssignmentType): ProjectAssignmentInterface => {
    return {
        [cProjectAssignment.id]: src.get(cProjectAssignment.id),
        [cProjectAssignment.userId]: src.get(cProjectAssignment.userId),
        [cProjectAssignment.projectId]: src.get(cProjectAssignment.projectId),
        [cProjectAssignment.user]: src.get(cProjectAssignment.user),
        [cProjectAssignment.project]: src.get(cProjectAssignment.project),
        [cProjectAssignment.createdOn]: src.get(cProjectAssignment.createdOn),
    }
}

export const projectAssignmentDefault = (): ProjectAssignmentType => fromJS({
    [cProjectAssignment.id]: null,
    [cProjectAssignment.userId]: null,
    [cProjectAssignment.projectId]: null,
    [cProjectAssignment.user]: userDefault(),
    [cProjectAssignment.project]: projectDefault(),
    [cProjectAssignment.createdOn]: null
});