import {Immutable} from "./Immutable";
import {fromJS} from "immutable";

export enum cInputProcessingRequest {
    taskId = "taskId",
    fileId = "fileId"
}

export interface InputProcessingRequestInterface {
    [cInputProcessingRequest.taskId]: string;
    [cInputProcessingRequest.fileId]: string;
}

export type InputProcessingRequestType = Immutable<InputProcessingRequestInterface>;

export const mapInputProcessingRequestFromJS = (request: InputProcessingRequestInterface): InputProcessingRequestType => {
    return fromJS({
        [cInputProcessingRequest.taskId]: request[cInputProcessingRequest.taskId],
        [cInputProcessingRequest.fileId]: request[cInputProcessingRequest.fileId]
    });
}

export const mapInputProcessingRequestToJS = (request: InputProcessingRequestType): InputProcessingRequestInterface => ({
    [cInputProcessingRequest.taskId]: request.get(cInputProcessingRequest.taskId),
    [cInputProcessingRequest.fileId]: request.get(cInputProcessingRequest.fileId)
});

export const inputProcessingRequestDefault = (): InputProcessingRequestType => fromJS({
    [cInputProcessingRequest.taskId]: null,
    [cInputProcessingRequest.fileId]: null
});