import {
    PROJECT_TASKS_TAB_CONTAINER_CANCEL_REQUEST_RESULT_DOWNLOAD,
    PROJECT_TASKS_TAB_CONTAINER_DOWNLOAD_REQUEST_RESULT
} from "./constants";

export interface DownloadProcessingRequestResultActionInterface {
    type: string;
    requestId: string;
    projectId: string;
}

export interface CancelDownloadProcessingRequestResultActionInterface {
    type: string;
}

export const downloadProcessingRequestResult = (requestId: string, projectId: string): DownloadProcessingRequestResultActionInterface => ({
    type: PROJECT_TASKS_TAB_CONTAINER_DOWNLOAD_REQUEST_RESULT,
    requestId,
    projectId
});

export const cancelProcessingRequestResultDownload = (): CancelDownloadProcessingRequestResultActionInterface => ({
    type: PROJECT_TASKS_TAB_CONTAINER_CANCEL_REQUEST_RESULT_DOWNLOAD
});