import React from "react";
import createPlotlyComponent from "react-plotly.js/factory"
import {capitalize} from "../../global/utils";
import {
    ChartType,
    cMetricQuery,
    getPlotTypeFromChartType,
    MetricAggregationField,
    MetricQueryInterface,
    metricValidationSchema
} from "../../models/MetricQuery";
import {HistogramMetricQueryInterface, mapHistogramQueryFromMetricQuery} from "../../models/HistogramMetricQuery";
import {cPlain2DPoint, Plain2DPointType} from "../../models/Plain2DPoint";
import {List} from "immutable";
import Plotly from "plotly.js-cartesian-dist";
import {mapTimeQueryFromMetricQuery, TimeMetricQueryInterface} from "../../models/TimeMetricQuery";
import {EpochMetricQueryInterface, mapEpochQueryFromMetricQuery} from "../../models/EpochMetricQuery";
import {ContextMetricQueryInterface, mapContextQueryFromMetricQuery} from "../../models/ContextMetricQuery";
import {connect} from "react-redux";
import {
    getAllMetricsGroupedByContext,
    getAllMetricsGroupedByEpoch,
    getAllMetricsGroupedByTime,
    getMetricHistogramByQuery,
    removeMetricValue,
    resetMetricValue
} from "../../api/MetricApi/actions";
import {cMetricReducer} from "../../api/MetricApi/constants";
import moment from "moment";
import {DATE_TIME_WITHOUT_TIMEZONE_FORMAT} from "../../global/constants";

const equal = require('fast-deep-equal');
const Plot = createPlotlyComponent(Plotly);

interface MetricPlotContainerPropsInterface {
    metricQuery: MetricQueryInterface;
    dataSeriesLabel: string;

    lineMetrics: Map<number, List<Plain2DPointType>>;
    metricHistogram: Map<number, number[]>;

    getAllMetricsGroupedByTime: (criteria: TimeMetricQueryInterface) => void;
    getAllMetricsGroupedByEpoch: (criteria: EpochMetricQueryInterface) => void;
    getAllMetricsGroupedByContext: (criteria: ContextMetricQueryInterface) => void;
    getMetricHistogramByCriteria: (criteria: HistogramMetricQueryInterface) => void;

    resetMetricValue: (field: string) => void;
    removeMetricValue: (path: any[]) => void;
}

class MetricPlotContainer extends React.Component<MetricPlotContainerPropsInterface> {

    componentDidMount() {
        this.handleRefreshData(this.props.metricQuery);
    }

    componentDidUpdate(prevProps: Readonly<MetricPlotContainerPropsInterface>, prevState: Readonly<{}>, snapshot?: any) {
        if (!equal(this.props.metricQuery, prevProps.metricQuery)) {
            this.handleRefreshData(this.props.metricQuery);
        }
    }

    shouldComponentUpdate(nextProps: Readonly<MetricPlotContainerPropsInterface>, nextState: Readonly<{}>, nextContext: any): boolean {
        const visualizationId = nextProps.metricQuery[cMetricQuery.id];
        if (visualizationId == null || !equal(this.props.metricQuery, nextProps.metricQuery)) {
            return true;
        }
        return !equal(this.props.lineMetrics.get(visualizationId), nextProps.lineMetrics.get(visualizationId)) ||
            !equal(this.props.metricHistogram.get(visualizationId), nextProps.metricHistogram.get(visualizationId));
    }

    componentWillUnmount() {
        const visualizationId = this.props.metricQuery[cMetricQuery.id];
        if (visualizationId) {
            this.props.removeMetricValue([cMetricReducer.metricHistogram, visualizationId]);
            this.props.removeMetricValue([cMetricReducer.plain2DMetrics, visualizationId]);
        } else {
            this.props.resetMetricValue(cMetricReducer.metricHistogram);
            this.props.resetMetricValue(cMetricReducer.plain2DMetrics);
        }
    }

    handleRefreshData = (metricQuery: MetricQueryInterface) => {
        metricValidationSchema.isValid(metricQuery)
            .then((valid) => {
                if (valid) {
                    if (metricQuery[cMetricQuery.chartType] === ChartType.HISTOGRAM) {
                        this.props.getMetricHistogramByCriteria(mapHistogramQueryFromMetricQuery(metricQuery));
                    } else {
                        switch (metricQuery[cMetricQuery.groupBy]) {
                            case MetricAggregationField.TIME:
                                this.props.getAllMetricsGroupedByTime(mapTimeQueryFromMetricQuery(metricQuery));
                                break;
                            case MetricAggregationField.EPOCH:
                                this.props.getAllMetricsGroupedByEpoch(mapEpochQueryFromMetricQuery(metricQuery));
                                break;
                            case MetricAggregationField.CONTEXT:
                                this.props.getAllMetricsGroupedByContext(mapContextQueryFromMetricQuery(metricQuery));
                                break;
                        }
                    }
                }
            });
    }

    getDataSeries = (metricQuery: MetricQueryInterface): object => {
        const selectedChartType = metricQuery[cMetricQuery.chartType];
        const visualizationId = metricQuery[cMetricQuery.id];

        if (selectedChartType === ChartType.HISTOGRAM) {
            const {histogramFrom, histogramTo, histogramBucketCount} = mapHistogramQueryFromMetricQuery(metricQuery);
            const step = (histogramTo - histogramFrom) / histogramBucketCount;
            let x = [];
            let element;

            let metricHistogram: number[];
            if (visualizationId) {
                metricHistogram = this.props.metricHistogram.get(visualizationId) || [];
            } else {
                metricHistogram = this.props.metricHistogram.get(0) || [];
            }
            for (let i = 0; i < histogramBucketCount; i++) {
                element = (histogramFrom + step / 2) + i * step;
                for (let j = 0; j < metricHistogram[i + 1]; j++) {
                    x.push(element)
                }
            }

            return {
                x: x,
                xbins: {start: histogramFrom, end: histogramTo, size: step},
                type: getPlotTypeFromChartType(selectedChartType),
                mode: 'lines+markers',
                marker: {color: '#0C8AFF'},
                showlegend: true
            };
        }

        let lineMetrics: List<Plain2DPointType>;
        if (visualizationId) {
            lineMetrics = this.props.lineMetrics.get(visualizationId) || List();
        } else {
            lineMetrics = this.props.lineMetrics.get(0) || List();
        }

        if (this.props.metricQuery[cMetricQuery.groupBy] === MetricAggregationField.TIME) {
            lineMetrics = lineMetrics.map(e => e.set(cPlain2DPoint.x, moment(e.get(cPlain2DPoint.x)).local().format(DATE_TIME_WITHOUT_TIMEZONE_FORMAT)));
        }

        return {
            x: lineMetrics.map(e => e.get(cPlain2DPoint.x)).toArray(),
            y: lineMetrics.map(e => e.get(cPlain2DPoint.y)).toArray(),
            type: getPlotTypeFromChartType(selectedChartType),
            mode: 'lines+markers',
            marker: {color: '#0C8AFF'},
            showlegend: true
        };
    }

    getDataSeriesRange = (metricQuery: MetricQueryInterface) => {
        if (metricQuery[cMetricQuery.chartType] === ChartType.HISTOGRAM) {
            const { histogramFrom, histogramTo } = mapHistogramQueryFromMetricQuery(metricQuery);
            return {
                range: [histogramFrom - 1, histogramTo + 1]
            }
        }
        return {};
    }

    render() {
        return (
            <Plot
                data={[
                    this.getDataSeries(this.props.metricQuery)
                ]}
                layout={{
                    title: this.props.metricQuery[cMetricQuery.name] ? this.props.metricQuery[cMetricQuery.name] : 'Metric visualization',
                    xaxis: {
                        title: capitalize(this.props.metricQuery[cMetricQuery.groupBy].toLowerCase()),
                        ...this.getDataSeriesRange(this.props.metricQuery)
                    },
                    yaxis: {
                        title: this.props.dataSeriesLabel
                    }
                }}
                config={{
                    responsive: true
                }}
                style={{width: "100%", height: "100%"}}
            />
        );
    }
}

const mapStateToProps = (state: any) => ({
    lineMetrics: state.metricApiReducer.get(cMetricReducer.plain2DMetrics),
    metricHistogram: state.metricApiReducer.get(cMetricReducer.metricHistogram)
});

const mapDispatchToProps = (dispatch: any) => ({
    getAllMetricsGroupedByTime: (criteria: TimeMetricQueryInterface) => dispatch(getAllMetricsGroupedByTime(criteria)),
    getAllMetricsGroupedByEpoch: (criteria: EpochMetricQueryInterface) => dispatch(getAllMetricsGroupedByEpoch(criteria)),
    getAllMetricsGroupedByContext: (criteria: ContextMetricQueryInterface) => dispatch(getAllMetricsGroupedByContext(criteria)),
    getMetricHistogramByCriteria: (criteria: HistogramMetricQueryInterface) => dispatch(getMetricHistogramByQuery(criteria)),
    resetMetricValue: (field: string) => dispatch(resetMetricValue(field)),
    removeMetricValue: (path: any[]) => dispatch(removeMetricValue(path))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MetricPlotContainer);