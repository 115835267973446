import {all, call, put, takeEvery} from "redux-saga/effects";
import {
    METRIC_GET_ALL_METRICS_GROUPED_BY_CONTEXT,
    METRIC_GET_ALL_METRICS_GROUPED_BY_EPOCH,
    METRIC_GET_ALL_METRICS_GROUPED_BY_TIME,
    METRIC_GET_METRIC_HISTOGRAM_BY_QUERY
} from "./constants";
import {
    GetAllMetricsGroupedByContextActionInterface,
    GetAllMetricsGroupedByEpochActionInterface,
    GetAllMetricsGroupedByTimeActionInterface,
    GetMetricHistogramByQueryActionInterface
} from "./actions";
import {Network} from "../../internal/network";
import Links from "../../global/links";
import {Actions} from "../../global/actions";
import {mapPlain2DPointFromJS, Plain2DPointInterface} from "../../models/Plain2DPoint";
import {List} from "immutable";
import {Alert} from "../../containers/AlertContainer/actions";
import {TimeMetricQueryInterface} from "../../models/TimeMetricQuery";
import {HistogramMetricQueryInterface} from "../../models/HistogramMetricQuery";
import {mapToURLParams} from "../../global/utils";
import {cMetricQuery} from "../../models/MetricQuery";

function* getAllGroupedByTime(action: GetAllMetricsGroupedByTimeActionInterface) {
    try {
        const {id, taskId, tagId, bucketWidth, timeFrom, timeTo}: TimeMetricQueryInterface = action.metricQuery;
        const criteria = mapToURLParams({
            taskId,
            tagId,
            timeFrom,
            timeTo
        });

        const metrics: Plain2DPointInterface[] = yield call(Network.get(Links.metricsGroupedByTime(criteria, bucketWidth)));
        yield put(Actions.fulfilled(action.type, { result: List(metrics).map(mapPlain2DPointFromJS), visualizationId: id }));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

function* getAllGroupedByEpoch(action: GetAllMetricsGroupedByEpochActionInterface) {
    try {
        const criteria = mapToURLParams(action.metricQuery);

        const metrics: Plain2DPointInterface[] = yield call(Network.get(Links.metricsGroupedByEpoch(criteria)));
        yield put(Actions.fulfilled(action.type, { result: List(metrics).map(mapPlain2DPointFromJS), visualizationId: action.metricQuery[cMetricQuery.id] }));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

function* getAllGroupedByContext(action: GetAllMetricsGroupedByContextActionInterface) {
    try {
        const criteria = mapToURLParams(action.metricQuery);

        const metrics: Plain2DPointInterface[] = yield call(Network.get(Links.metricsGroupedByContext(criteria)));
        yield put(Actions.fulfilled(action.type, { result: List(metrics).map(mapPlain2DPointFromJS), visualizationId: action.metricQuery[cMetricQuery.id] }));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

function* getHistogramByQuery(action: GetMetricHistogramByQueryActionInterface) {
    try {
        const {taskId, tagId, histogramFrom, histogramTo, histogramBucketCount}: HistogramMetricQueryInterface = action.metricQuery;
        const criteria = mapToURLParams({
            taskId,
            tagId
        });

        const histogram: number[] = yield call(Network.get(Links.metricHistogram(criteria, histogramFrom, histogramTo, histogramBucketCount)));
        yield put(Actions.fulfilled(action.type, { result: Array.from(histogram), visualizationId: action.metricQuery[cMetricQuery.id] }));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

export default function* saga() {
    return yield all([
        yield takeEvery(METRIC_GET_ALL_METRICS_GROUPED_BY_TIME, getAllGroupedByTime),
        yield takeEvery(METRIC_GET_ALL_METRICS_GROUPED_BY_EPOCH, getAllGroupedByEpoch),
        yield takeEvery(METRIC_GET_ALL_METRICS_GROUPED_BY_CONTEXT, getAllGroupedByContext),
        yield takeEvery(METRIC_GET_METRIC_HISTOGRAM_BY_QUERY, getHistogramByQuery)
    ])
}