export enum TaskState {
    READY = "READY",
    STARTING = "STARTING",
    RUNNING = "RUNNING",
    COMPLETED = "COMPLETED",
    STOPPING = "STOPPING",
    STOPPED = "STOPPED",
    FAILED = "FAILED"
}

export const getTaskStateName = (state: TaskState): string => {
    switch (state) {
        case TaskState.READY: return "Ready";
        case TaskState.STARTING: return "Starting";
        case TaskState.RUNNING: return "Running";
        case TaskState.COMPLETED: return "Completed";
        case TaskState.STOPPING: return "Stopping";
        case TaskState.STOPPED: return "Stopped";
        case TaskState.FAILED: return "Failed";
        default:
            return "";
    }
}