import {
    cProjectTagReducer,
    PROJECT_TAG_GET_ALL_BY_PROJECT_ID,
    PROJECT_TAG_GET_ALL_BY_TASK_ID,
    PROJECT_TAG_GET_BY_ID,
    PROJECT_TAG_RESET
} from "./constants";
import {projectTagDefault, ProjectTagType} from "../../models/ProjectTag";
import {pageDefault, PageType} from "../../models/Page";
import {Immutable} from "../../models/Immutable";
import {FULFILLED} from "../../global/constants";

interface InitialStateInterface {
    [cProjectTagReducer.projectTag]: ProjectTagType;
    [cProjectTagReducer.projectTags]: PageType<ProjectTagType>;
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
    [cProjectTagReducer.projectTag]: projectTagDefault(),
    [cProjectTagReducer.projectTags]: pageDefault()
})

const reducer = (state: InitialStateType = initialState, action: any) => {
    switch (action.type) {
        case `${PROJECT_TAG_GET_BY_ID}${FULFILLED}`: {
            return state.set(cProjectTagReducer.projectTag, action.payload);
        }
        case `${PROJECT_TAG_GET_ALL_BY_PROJECT_ID}${FULFILLED}`: {
            return state.set(cProjectTagReducer.projectTags, action.payload);
        }
        case `${PROJECT_TAG_GET_ALL_BY_TASK_ID}${FULFILLED}`: {
            return state.set(cProjectTagReducer.projectTags, action.payload);
        }
        case PROJECT_TAG_RESET: {
            return state.set(action.field, initialState.get(action.field));
        }
        default:
            return state;
    }
}

export default reducer;