export enum cDashboardReducer {
    dashboard = "dashboard",
    dashboards = "dashboards"
}

export const DASHBOARD_SAVE = "Dashboard/SAVE";
export const DASHBOARD_DELETE_BY_ID = "Dashboard/DELETE_BY_ID";
export const DASHBOARD_ADD_METRIC_VISUALIZATION = "Dashboard/ADD_METRIC_VISUALIZATION";
export const DASHBOARD_REMOVE_METRIC_VISUALIZATION = "Dashboard/REMOVE_METRIC_VISUALIZATION";
export const DASHBOARD_GET_BY_ID = "Dashboard/GET_BY_ID";
export const DASHBOARD_GET_ALL_BY_PROJECT_ID = "Dashboard/GET_ALL_BY_PROJECT_ID";
export const DASHBOARD_RESET_VALUE = "Dashboard/RESET_VALUE";