import {Immutable} from "../../models/Immutable";
import {INPUT_PROCESSING_REQUEST_SAVE} from "./constants";
import {FULFILLED} from "../../global/constants";

interface InitialStateInterface {

}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({

});

const reducer = (state: InitialStateType = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case `${INPUT_PROCESSING_REQUEST_SAVE}${FULFILLED}`: {
            return state;
        }
        default:
            return state;
    }
}

export default reducer;