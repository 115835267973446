import {cMetricQuery, MetricQueryInterface} from "./MetricQuery";

export interface HistogramMetricQueryInterface {
    [cMetricQuery.id]?: number;
    [cMetricQuery.taskId]: string;
    [cMetricQuery.tagId]: number;
    [cMetricQuery.histogramFrom]: number;
    [cMetricQuery.histogramTo]: number;
    [cMetricQuery.histogramBucketCount]: number;
}

export const mapHistogramQueryFromMetricQuery = (src: MetricQueryInterface): HistogramMetricQueryInterface => ({
    [cMetricQuery.id]: src[cMetricQuery.id],
    [cMetricQuery.taskId]: src[cMetricQuery.taskId],
    [cMetricQuery.tagId]: src[cMetricQuery.tagId],
    [cMetricQuery.histogramFrom]: src[cMetricQuery.histogramFrom]|| 0,
    [cMetricQuery.histogramTo]: src[cMetricQuery.histogramTo] || 1,
    [cMetricQuery.histogramBucketCount]: src[cMetricQuery.histogramBucketCount] || 1
});