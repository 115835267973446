import {cProjectStudentsTabReducer as cReducer, PROJECT_STUDENTS_TAB_TOGGLE_ASSIGNMENT_MODAL} from "./constants";
import {Immutable} from "../../models/Immutable";

interface InitialStateInterface {
    [cReducer.isProjectAssignmentModalOpen]: boolean
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
    [cReducer.isProjectAssignmentModalOpen]: false
});

const reducer = (state: InitialStateType = initialState, action: any) => {
    switch (action.type) {
        case PROJECT_STUDENTS_TAB_TOGGLE_ASSIGNMENT_MODAL: {
            return state.set(cReducer.isProjectAssignmentModalOpen, !state.get(cReducer.isProjectAssignmentModalOpen))
        }
        default:
            return state;
    }
}

export default reducer;