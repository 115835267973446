import React from "react";
import ContentCard from "../../components/ContentCard";
import Content from "../../components/Content";
import {Nav, TabContent, TabPane} from "reactstrap";
import TabLink from "../../components/TabLink";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import TaskDetailTabContainer from "../TaskDetailTabContainer";
import TaskParametersTabContainer from "../TaskParametersTabContainer";
import TaskFilesTabContainer from "../TaskFilesTabContainer";
import {cTask, TaskType} from "../../models/Task";
import {cTaskReducer} from "../../api/TaskApi/constants";
import {connect} from "react-redux";
import {cProject} from "../../models/Project";
import TaskMetricsTabContainer from "../TaskMetricsTabContainer";

interface TaskManagementContainerPropsInterface {
    task: TaskType;
}

interface TaskManagementContainerStateInterface {
    activeTab: number;
}

class TaskManagementContainer extends React.PureComponent<RouteComponentProps & TaskManagementContainerPropsInterface, TaskManagementContainerStateInterface> {

    state = {
        activeTab: 1
    }

    setActiveTab = (id: number) => {
        this.setState((prevState) => ({
            ...prevState,
            activeTab: id
        }));
    }

    renderBreadcrumb = () => {
        return (
            <span>
                <Link to="/" className="plain-link">Home</Link>
                {" / "}
                <Link to="/project-list" className="plain-link">Projects</Link>
                {" / "}
                <Link to={`/project/${this.props.task.getIn([cTask.project, cProject.id])}`} className="plain-link">
                    {this.props.task.getIn([cTask.project, cProject.name])}
                </Link>
                {" / "}
                {this.props.task.get(cTask.name)}
            </span>
        )
    }

    renderTabContent = (tabId: number, component: JSX.Element): JSX.Element => {
        if (this.state.activeTab === tabId) {
            return component;
        }

        return <></>;
    }

    render() {
        const taskId: string = new URLSearchParams(this.props.match.params).get("id") || "";

        return (
            <Content title="Task" subtitle={this.renderBreadcrumb()}>
                <ContentCard className="ml-5 mr-5 mb-5" style={{minHeight: "calc(100vh - 26vh)"}}>
                    <Nav tabs className="mt-2 ml-2">
                        <TabLink id={1} title="Detail" activeTab={this.state.activeTab} setActiveTab={this.setActiveTab} />
                        <TabLink id={2} title="Parameters" activeTab={this.state.activeTab} setActiveTab={this.setActiveTab} />
                        <TabLink id={3} title="Task files" activeTab={this.state.activeTab} setActiveTab={this.setActiveTab} />
                        <TabLink id={4} title="Visualizations" activeTab={this.state.activeTab} setActiveTab={this.setActiveTab} />
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId={1}>
                            {this.renderTabContent(1, <TaskDetailTabContainer taskId={taskId} />)}
                        </TabPane>
                        <TabPane tabId={2}>
                            {this.renderTabContent(2, <TaskParametersTabContainer taskId={taskId} />)}
                        </TabPane>
                        <TabPane tabId={3}>
                            {this.renderTabContent(3, <TaskFilesTabContainer taskId={taskId} />)}
                        </TabPane>
                        <TabPane tabId={4}>
                            {this.renderTabContent(4, <TaskMetricsTabContainer taskId={taskId} />)}
                        </TabPane>
                    </TabContent>
                </ContentCard>
            </Content>
        );
    }
}

const mapStateToProps = (state: any) => ({
    task: state.taskApiReducer.get(cTaskReducer.task)
});

const mapDispatchToProps = (dispatch: any) => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TaskManagementContainer));