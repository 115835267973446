import React from "react";
import {Route, Switch} from "react-router-dom";
import App from "./containers/App/App";
import About from "./components/About";
import {withSidebarNavigation} from "./components/SidebarNavigation";
import {withHeaderNavigation} from "./components/HeaderNavigationHOC";
import withAuthChecker from "./containers/AuthChecker";
import ProjectListContainer from "./containers/ProjectListContainer";
import AlertContainer from "./containers/AlertContainer";
import ProjectFormContainer from "./containers/ProjectFormContainer";
import ProjectManagementContainer from "./containers/ProjectManagementContainer";
import TaskFormContainer from "./containers/TaskFormContainer";
import TaskManagementContainer from "./containers/TaskManagementContainer";
import MetricsPlotBuilderContainer from "./containers/MetricsPlotBuilderContainer";
import ConfigManagementContainer from "./containers/ConfigManagementContainer";
import UserFormContainer from "./containers/UserFormContainer";
import DashboardContainer from "./containers/DashboardContainer";
import {handleRedirectToAuthServiceLogin} from "./api/AuthApi/utils";

const Router = () => (
    <React.Fragment>
        <Switch>
            <Route exact path="/" component={withAuthChecker(withHeaderNavigation(withSidebarNavigation(App)))} />
            <Route exact path="/about" component={withAuthChecker(withHeaderNavigation(withSidebarNavigation(About)))} />
            <Route exact path="/project-list" component={withAuthChecker(withHeaderNavigation(withSidebarNavigation(ProjectListContainer)))} />
            <Route exact path="/project-form" component={withAuthChecker(withHeaderNavigation(withSidebarNavigation(ProjectFormContainer)))} />
            <Route exact path="/project-form/:id" component={withAuthChecker(withHeaderNavigation(withSidebarNavigation(ProjectFormContainer)))} />
            <Route exact path="/project/:id" component={withAuthChecker(withHeaderNavigation(withSidebarNavigation(ProjectManagementContainer)))} />
            <Route exact path="/task-form/project/:projectId" component={withAuthChecker(withHeaderNavigation(withSidebarNavigation(TaskFormContainer)))} />
            <Route exact path="/task-form/:id" component={withAuthChecker(withHeaderNavigation(withSidebarNavigation(TaskFormContainer)))} />
            <Route exact path="/task/:id" component={withAuthChecker(withHeaderNavigation(withSidebarNavigation(TaskManagementContainer)))} />
            <Route exact path="/metrics-plot/task/:taskId" component={withAuthChecker(withHeaderNavigation(withSidebarNavigation(MetricsPlotBuilderContainer)))} />
            <Route exact path="/metrics-plot/:id" component={withAuthChecker(withHeaderNavigation(withSidebarNavigation(MetricsPlotBuilderContainer)))} />
            <Route exact path="/configuration" component={withAuthChecker(withHeaderNavigation(withSidebarNavigation(ConfigManagementContainer)))} />
            <Route exact path="/user-form" component={withAuthChecker(withHeaderNavigation(withSidebarNavigation(UserFormContainer)))} />
            <Route exact path="/user-form/:id" component={withAuthChecker(withHeaderNavigation(withSidebarNavigation(UserFormContainer)))} />
            <Route exact path="/dashboard/:id" component={withAuthChecker(withHeaderNavigation(withSidebarNavigation(DashboardContainer)))} />
            <Route exact path="/login" render={() => <>{handleRedirectToAuthServiceLogin()}</>} />
            <Route exact path="/login/idp" render={() => null} />
        </Switch>
        <AlertContainer />
    </React.Fragment>
)

export default Router;