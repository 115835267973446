import React from "react";
import {connect} from "react-redux";
import {Set} from "immutable";
import {cPage, PageType} from "../../models/Page";
import {cTaskFile, mapTaskFileFromJS, TaskFileType} from "../../models/TaskFile";
import {cTaskFileReducer} from "../../api/TaskFileApi/constants";
import {getPaginationFromPage, Pagination} from "../../models/Pagination";
import {deleteTaskFileById, getAllTaskFilesByTaskId, saveAllTaskFiles} from "../../api/TaskFileApi/actions";
import {Button, Col, Row} from "reactstrap";
import TableView, {ColumnDefinition} from "../../components/TableView";
import {cGenericFile, GenericFileType, getFileSizeWithUnit} from "../../models/GenericFile";
import {DATE_TIME_WITHOUT_TIMEZONE_FORMAT} from "../../global/constants";
import moment from "moment";
import PaginationControl from "../../components/PaginationControl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {cModalReducer} from "../ModalContainer/constants";
import {toggleModal} from "../ModalContainer/actions";
import TaskFileTableModal from "../../components/TaskFileTableModal";
import {cFileReducer} from "../../api/FileApi/constants";
import {searchProjectFilesByTaskIdAndSearchString} from "./actions";
import {SuccessEventCallback} from "../../models/Event";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {resetFileValue} from "../../api/FileApi/actions";
import {FileType} from "../../models/FileType";

interface TaskFilesTabContainerPropsInterface {
    taskId: string;

    taskFiles: PageType<TaskFileType>;
    isTaskFormModalOpen: boolean;
    foundProjectFiles: PageType<GenericFileType>;

    getAllTaskFilesByTaskId: (taskId: string, page?: Pagination) => void;
    toggleTaskFormModal: () => void;
    searchProjectFilesByTaskIdAndSearchString: (taskId: string, searchString: string, page?: Pagination) => void;
    saveAllTaskFiles: (taskFiles: Set<TaskFileType>, successCallbacks?: SuccessEventCallback<void>[]) => void;
    deleteTaskFileById: (id: number, successCallbacks?: SuccessEventCallback<void>[]) => void;
    resetFileValue: (field: string) => void;
}

interface TaskFilesTabContainerStateInterface {
    isDeleteConfirmationDialogOpen: boolean;
    taskFileToDelete?: number;
}

class TaskFilesTabContainer extends React.PureComponent<TaskFilesTabContainerPropsInterface, TaskFilesTabContainerStateInterface> {

    state = {
        isDeleteConfirmationDialogOpen: false,
        taskFileToDelete: undefined
    }

    componentDidMount() {
        this.props.getAllTaskFilesByTaskId(this.props.taskId);
    }

    handleConfirmationDialogToggle = (id?: number) => {
        this.setState(prevState => ({
            ...prevState,
            isDeleteConfirmationDialogOpen: !prevState.isDeleteConfirmationDialogOpen,
            taskFileToDelete: id
        }))
    }

    handleDeleteConfirm = () => {
        if (!this.state.taskFileToDelete) return;

        this.props.deleteTaskFileById(
            this.state.taskFileToDelete || 0,
            [
                () => getAllTaskFilesByTaskId(this.props.taskId, getPaginationFromPage(this.props.taskFiles))
            ]
        );
        this.handleConfirmationDialogToggle();
    }

    handleSaveTaskFiles = (files: Set<GenericFileType>) => {
        const taskFiles: Set<TaskFileType> = files
            .map(e => mapTaskFileFromJS({
                [cTaskFile.fileId]: e.get(cGenericFile.id) || "",
                [cTaskFile.taskId]: this.props.taskId,
                [cTaskFile.fileType]: FileType.ATTACHMENT
            }));

        this.props.saveAllTaskFiles(
            taskFiles,
            [
                () => toggleModal(),
                () => getAllTaskFilesByTaskId(this.props.taskId, getPaginationFromPage(this.props.taskFiles))
            ]
        );
    }

    renderActionsColumn = (rowObject: TaskFileType) => {
        return (
            <FontAwesomeIcon icon={faTimes} className="cursor-pointer ml-3" onClick={() => this.handleConfirmationDialogToggle(rowObject.get(cTaskFile.id))} />
        )
    }

    columnDefinitions: ColumnDefinition<TaskFileType>[] = [
        {
            attributeName: cGenericFile.name,
            renderHeader: () => "File name",
            renderCell: rowObject => rowObject.getIn([cTaskFile.file, cGenericFile.name])
        },
        {
            attributeName: cGenericFile.size,
            renderHeader: () => "Size",
            renderCell: rowObject => getFileSizeWithUnit(rowObject.getIn([cTaskFile.file, cGenericFile.size]))
        },
        {
            attributeName: cGenericFile.createdOn,
            renderHeader: () => "Created on",
            renderCell: rowObject => moment(rowObject.getIn([cTaskFile.file, cGenericFile.createdOn])).format(DATE_TIME_WITHOUT_TIMEZONE_FORMAT)
        },
        {
            attributeName: cTaskFile.fileType,
            renderHeader: () => "Type",
            renderCell: rowObject => rowObject.get(cTaskFile.fileType)
        },
        {
            attributeName: "actions",
            renderHeader: () => "",
            renderCell: this.renderActionsColumn
        }
    ]

    render() {
        return (
            <div className="m-5">
                <Row>
                    <Col>
                        <TableView
                            rows={this.props.taskFiles.get(cPage.content)}
                            rowKeyAttribute={cTaskFile.id}
                            columnDefinitions={this.columnDefinitions}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PaginationControl page={this.props.taskFiles} onPageChange={page => this.props.getAllTaskFilesByTaskId(this.props.taskId, {page: page, size: this.props.taskFiles.get(cPage.size)})} />
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end flex-grow-1">
                        <Button color="success" className="pr-5" onClick={this.props.toggleTaskFormModal}>
                            <FontAwesomeIcon icon={faPlus} className="mr-5" />
                            Add
                        </Button>
                    </Col>
                </Row>
                {
                    this.props.isTaskFormModalOpen
                        ? <TaskFileTableModal
                            headerText="Add project files"
                            isMultiselect={true}
                            isOpen={this.props.isTaskFormModalOpen}
                            foundFiles={this.props.foundProjectFiles}
                            onToggle={this.props.toggleTaskFormModal}
                            onSearchProjectFilesBySearchString={
                                (searchString, page) =>
                                    this.props.searchProjectFilesByTaskIdAndSearchString(this.props.taskId, searchString, page)}
                            onSaveAllTaskFiles={this.handleSaveTaskFiles}
                            onResetFoundFiles={() => this.props.resetFileValue(cFileReducer.files)}
                        />
                        : null
                }
                {
                    this.state.isDeleteConfirmationDialogOpen
                        ? <ConfirmationDialog
                            header="Task file removal"
                            body="Remove file from task?"
                            isOpen={this.state.isDeleteConfirmationDialogOpen}
                            handleConfirm={this.handleDeleteConfirm}
                            handleCancel={this.handleConfirmationDialogToggle}
                        />
                        : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    taskFiles: state.taskFileApiReducer.get(cTaskFileReducer.taskFiles),
    isTaskFormModalOpen: state.modalContainerReducer.get(cModalReducer.isModalOpen),
    foundProjectFiles: state.fileApiReducer.get(cFileReducer.files)
});

const mapDispatchToProps = (dispatch: any) => ({
    getAllTaskFilesByTaskId: (taskId: string, page?: Pagination) => dispatch(getAllTaskFilesByTaskId(taskId, page)),
    toggleTaskFormModal: () => dispatch(toggleModal()),
    searchProjectFilesByTaskIdAndSearchString: (taskId: string, searchString: string, page?: Pagination) => dispatch(searchProjectFilesByTaskIdAndSearchString(taskId, searchString, page)),
    saveAllTaskFiles: (taskFiles: Set<TaskFileType>, successCallbacks?: SuccessEventCallback<void>[]) => dispatch(saveAllTaskFiles(taskFiles, {
        onSuccess: successCallbacks
    })),
    deleteTaskFileById: (id: number, successCallbacks?: SuccessEventCallback<void>[]) => dispatch(deleteTaskFileById(id, {
        onSuccess: successCallbacks
    })),
    resetFileValue: (field: string) => dispatch(resetFileValue(field))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskFilesTabContainer);