export enum cVisualizationReducer {
    visualization = "visualization",
    visualizations = "visualizations"
}

export const VISUALIZATION_SAVE = "Visualization/SAVE";
export const VISUALIZATION_DELETE_BY_ID = "Visualization/DELETE_BY_ID";
export const VISUALIZATION_GET_BY_ID = "Visualization/GET_BY_ID";
export const VISUALIZATION_GET_ALL_BY_TASK_ID = "Visualization/GET_ALL_BY_TASK_ID";
export const VISUALIZATION_GET_ALL_BY_PROJECT_ID_AND_SEARCH_STRING = "Visualization/GET_ALL_BY_PROJECT_ID_AND_SEARCH_STRING";
export const VISUALIZATION_RESET_VALUE = "Visualization/RESET_VALUE";