import {InputProcessingRequestType} from "../../models/InputProcessingRequest";
import {CallbackInterface} from "../../models/Event";
import {INPUT_PROCESSING_REQUEST_SAVE} from "./constants";

export interface SaveInputProcessingRequestActionInterface {
    type: string;
    request: InputProcessingRequestType;
    callbacks?: CallbackInterface<string>;
}

export const saveInputProcessingRequest = (request: InputProcessingRequestType, callbacks?: CallbackInterface<string>): SaveInputProcessingRequestActionInterface => ({
    type: INPUT_PROCESSING_REQUEST_SAVE,
    request,
    callbacks
});