import React from "react";
import {connect} from "react-redux";
import {
    convertAllocationTimeFromMinutes,
    convertAllocationTimeToMinutes,
    cTask,
    mapTaskFromJS,
    mapTaskToJS,
    TaskType,
    taskValidationSchema
} from "../../models/Task";
import Content from "../../components/Content";
import ContentCard from "../../components/ContentCard";
import {Formik} from "formik";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Row
} from "reactstrap";
import {cTaskReducer} from "../../api/TaskApi/constants";
import {TaskTargetType} from "../../models/TaskTargetType";
import {QueueType} from "../../models/QueueType";
import "react-datepicker/dist/react-datepicker.css";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {getAllAvailableTaskRunners, getTaskById, resetTaskValue, saveTask} from "../../api/TaskApi/actions";
import {goBack} from "connected-react-router";
import {PageType} from "../../models/Page";
import {cGenericFile, genericFileDefault, GenericFileType, mapGenericFileToJS} from "../../models/GenericFile";
import {Pagination} from "../../models/Pagination";
import {cFileReducer} from "../../api/FileApi/constants";
import {searchProjectFilesByTaskIdAndSearchString} from "./actions";
import TaskFileTableModal from "../../components/TaskFileTableModal";
import {resetFileValue} from "../../api/FileApi/actions";
import NavigationBackwardLink from "../../components/NavigationBackwardLink";
import {Set} from "immutable";

interface TaskFormContainerPropsInterface {
    task: TaskType;
    foundFiles: PageType<GenericFileType>;
    availableTaskRunners: Set<TaskTargetType>;

    saveTask: (task: TaskType) => void;
    searchProjectFilesByTaskIdAndSearchString: (taskId: string, searchString: string, page: Pagination) => void;
    getTaskById: (id: string) => void;
    getAllAvailableTaskRunners: () => void;
    resetTaskValue: () => void;
    resetFileValue: () => void;
}

interface TaskFormContainerStateInterface {
    projectId?: string;
    isDatePickerOpen: boolean;
    isFilePickerOpen: boolean;
}

class TaskFormContainer extends React.PureComponent<TaskFormContainerPropsInterface & RouteComponentProps, TaskFormContainerStateInterface> {

    state = {
        projectId: undefined,
        isDatePickerOpen: false,
        isFilePickerOpen: false
    }

    componentDidMount() {
        const taskId: string | null = new URLSearchParams(this.props.match.params).get("id");
        this.props.getAllAvailableTaskRunners();
        if (taskId) {
            this.props.getTaskById(taskId);
        } else {
            const projectId: string | null = new URLSearchParams(this.props.match.params).get("projectId");
            if (projectId) {
                this.setState({projectId: projectId});
            }
            this.props.resetTaskValue();
        }
    }

    handleSearchFiles = (textValue: string, page: Pagination) => {
        const searchString = `name:${textValue},`;
        let projectId;
        if (this.props.task.get(cTask.projectId)) {
            projectId = this.props.task.get(cTask.projectId);
        } else {
            projectId = this.state.projectId || "";
        }
        this.props.searchProjectFilesByTaskIdAndSearchString(projectId, searchString, page);
    }

    handleFilePickerToggle = () => {
        this.setState((prevState) => ({
            ...prevState,
            isFilePickerOpen: !prevState.isFilePickerOpen
        }));
    }

    handleDatePickerToggle = () => {
        this.setState((prevState) => ({
            ...prevState,
            isDatePickerOpen: !prevState.isDatePickerOpen
        }))
    }

    handleGoBack = () => {
        if(this.props.history.action === "PUSH") {
            this.props.history.goBack();
        } else {
            this.props.history.push("/project-list");
        }
    }

    render() {
        const projectId: string | null = new URLSearchParams(this.props.match.params).get("projectId");

        return (
            <Content
                title="Create task"
                subtitle={<NavigationBackwardLink subtitle="Task details" onGoBack={this.handleGoBack} />}
            >
                <ContentCard className="ml-5 mr-5">
                    <div className="m-5">
                        {
                            (projectId === null && this.props.task.get(cTask.id)) || (projectId && this.props.task.get(cTask.id) === null)
                            ? <Formik
                                initialValues={mapTaskToJS(convertAllocationTimeToMinutes(this.props.task))}
                                validationSchema={taskValidationSchema}
                                validateOnChange={false}
                                validateOnBlur={false}
                                enableReinitialize={false}
                                onSubmit={(values, {setSubmitting}) => {
                                    let task = convertAllocationTimeFromMinutes(mapTaskFromJS(values));
                                    task = task.set(cTask.projectId, this.state.projectId)
                                        .set(cTask.executableFileId, task.getIn([cTask.executableFile, cGenericFile.id]))
                                        .set(cTask.executableFile, null);

                                    this.props.saveTask(task);
                                }}
                            >
                                {
                                    ({values, errors, handleChange, handleSubmit, handleBlur, isSubmitting, setFieldValue}) => {
                                        return (
                                            <React.Fragment>
                                                <Form onSubmit={handleSubmit}>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="nameField">Name</Label>
                                                                <Input id="nameField" name="name"
                                                                       placeholder="Task name"
                                                                       value={values[cTask.name]}
                                                                       onChange={handleChange}
                                                                       onBlur={handleBlur}
                                                                       invalid={!!errors[cTask.name]}/>
                                                                <FormFeedback>{errors[cTask.name]}</FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="taskExecutableFileField">Task
                                                                    entrypoint</Label>
                                                                <InputGroup>
                                                                    <Input
                                                                        id="taskExecutableFileField"
                                                                        name="executableFile.name"
                                                                        placeholder="Select executable project file..."
                                                                        onClick={this.handleFilePickerToggle}
                                                                        value={values[cTask.executableFile]?.[cGenericFile.name] || ""}
                                                                        readOnly
                                                                    />
                                                                    <InputGroupAddon addonType="append">
                                                                        <Button color="secondary"
                                                                                onClick={this.handleFilePickerToggle}>Select</Button>
                                                                    </InputGroupAddon>
                                                                </InputGroup>
                                                                <FormFeedback>{errors[cTask.executableFile]}</FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="targetTypeField">Target type</Label>
                                                                <Input id="targetTypeField" name="targetType"
                                                                       type="select"
                                                                       value={values[cTask.targetType]}
                                                                       onChange={handleChange} onBlur={handleBlur}
                                                                       disabled={!!values[cTask.id]}
                                                                       invalid={!!errors[cTask.targetType]}>
                                                                    <option value={""} defaultValue={""} />
                                                                    {Object.keys(TaskTargetType).map(e => (
                                                                        <option key={e}
                                                                                value={e}
                                                                                disabled={!this.props.availableTaskRunners.contains(e as TaskTargetType)}>
                                                                            {e.toLowerCase()}
                                                                        </option>
                                                                    ))}
                                                                </Input>
                                                                <FormText>Availability of target environments depends on current server settings.</FormText>
                                                                <FormFeedback>{errors[cTask.targetType]}</FormFeedback>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        values[cTask.targetType] === TaskTargetType.HPC
                                                            ? <>
                                                                <Row>
                                                                    <Col>
                                                                        <FormGroup>
                                                                            <Label for="externalProjectIdField">External project ID</Label>
                                                                            <Input id="externalProjectIdField" name="externalProjectId" placeholder="External project ID" value={values[cTask.externalProjectId]} onChange={handleChange} onBlur={handleBlur} invalid={!!errors[cTask.externalProjectId]} />
                                                                            <FormFeedback>{errors[cTask.externalProjectId]}</FormFeedback>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col>
                                                                        <FormGroup>
                                                                            <Label for="queueTypeField">Queue type</Label>
                                                                            <Input id="queueTypeField" name="queueType"
                                                                                   type="select"
                                                                                   value={values[cTask.queueType] || undefined}
                                                                                   onChange={handleChange}
                                                                                   onBlur={handleBlur}
                                                                                   invalid={!!errors[cTask.queueType]}>
                                                                                <option key="none" value={undefined} />
                                                                                {
                                                                                    Object.keys(QueueType).map(e => (
                                                                                        <option key={e}
                                                                                                value={e}>{e.toLowerCase()}</option>
                                                                                    ))
                                                                                }
                                                                            </Input>
                                                                            <FormFeedback>{errors[cTask.queueType]}</FormFeedback>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col>
                                                                        <FormGroup>
                                                                            <Label for="maximumAllocatedTimeField">Maximum
                                                                                allocation
                                                                                time [minutes]</Label>
                                                                            <Input id="maximumAllocatedTimeField"
                                                                                   name="maximumAllocatedTime"
                                                                                   placeholder="1"
                                                                                   type="number"
                                                                                   step={1}
                                                                                   min={1}
                                                                                   value={values[cTask.maximumAllocatedTime] || ""}
                                                                                   onChange={handleChange}
                                                                                   onBlur={handleBlur}
                                                                                   invalid={!!errors[cTask.maximumAllocatedTime]}/>
                                                                            <FormFeedback>{errors[cTask.maximumAllocatedTime]}</FormFeedback>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <FormGroup>
                                                                            <Label for="numberOfNodesField">Number of
                                                                                nodes</Label>
                                                                            <Input id="numberOfNodesField"
                                                                                   name="numberOfNodes"
                                                                                   placeholder="1"
                                                                                   type="number"
                                                                                   step={1}
                                                                                   min={1}
                                                                                   value={values[cTask.numberOfNodes] || ""}
                                                                                   onChange={handleChange}
                                                                                   onBlur={handleBlur}
                                                                                   invalid={!!errors[cTask.numberOfNodes]}/>
                                                                            <FormFeedback>{errors[cTask.numberOfNodes]}</FormFeedback>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col>
                                                                        <FormGroup>
                                                                            <Label for="numberOfCoresPerNodeField">Number of
                                                                                cores per
                                                                                node</Label>
                                                                            <Input id="numberOfCoresPerNodeField"
                                                                                   name="numberOfCoresPerNode"
                                                                                   placeholder="1"
                                                                                   type="number"
                                                                                   step={1}
                                                                                   min={1}
                                                                                   value={values[cTask.numberOfCoresPerNode] || ""}
                                                                                   onChange={handleChange}
                                                                                   onBlur={handleBlur}
                                                                                   invalid={!!errors[cTask.numberOfCoresPerNode]}/>
                                                                            <FormFeedback>{errors[cTask.numberOfCoresPerNode]}</FormFeedback>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            : null
                                                    }
                                                    <Row>
                                                        <Col className="d-flex justify-content-end">
                                                            <Button className="mr-2"
                                                                    onClick={this.handleGoBack}>Cancel</Button>
                                                            <Button color="primary" type="submit"
                                                                    disabled={isSubmitting}>Save</Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                                {
                                                    this.state.isFilePickerOpen
                                                        ? <TaskFileTableModal
                                                            headerText="Choose project file"
                                                            isMultiselect={false}
                                                            confirmButtonText="OK"
                                                            isOpen={this.state.isFilePickerOpen}
                                                            foundFiles={this.props.foundFiles}
                                                            onToggle={this.handleFilePickerToggle}
                                                            onSearchProjectFilesBySearchString={this.handleSearchFiles}
                                                            onSaveAllTaskFiles={(taskFiles) => {
                                                                if (!taskFiles.isEmpty()) {
                                                                    setFieldValue(cTask.executableFile, mapGenericFileToJS(taskFiles.first(genericFileDefault())));
                                                                } else {
                                                                    setFieldValue(cTask.executableFile, null);
                                                                }
                                                                this.handleFilePickerToggle();
                                                            }}
                                                            onResetFoundFiles={this.props.resetFileValue}
                                                        />
                                                        : null
                                                }
                                            </React.Fragment>
                                        );
                                    }
                                }
                            </Formik>
                            : null
                        }
                    </div>
                </ContentCard>
            </Content>
        );
    }
}

const mapStateToProps = (state: any) => ({
    foundFiles: state.fileApiReducer.get(cFileReducer.files),
    task: state.taskApiReducer.get(cTaskReducer.task),
    availableTaskRunners: state.taskApiReducer.get(cTaskReducer.taskRunners)
});

const mapDispatchToProps = (dispatch: any) => ({
    saveTask: (task: TaskType) => dispatch(
        saveTask(
            task,
            {
                onSuccess: [() => goBack()]
            })
    ),
    searchProjectFilesByTaskIdAndSearchString: (taskId: string, searchString: string, page: Pagination) => dispatch(searchProjectFilesByTaskIdAndSearchString(taskId, searchString, page)),
    getTaskById: (id: string) => dispatch(getTaskById(id)),
    getAllAvailableTaskRunners: () => dispatch(getAllAvailableTaskRunners()),
    resetTaskValue: () => dispatch(resetTaskValue(cTaskReducer.task)),
    resetFileValue: () => dispatch(resetFileValue(cFileReducer.files))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TaskFormContainer));