import {fromJS} from "immutable";
import {Immutable} from "./Immutable";
import * as Yup from "yup";

export enum cProject {
    id = "id",
    name = "name",
    description = "description",
    createdOn = "createdOn",
}

export interface ProjectInterface {
    [cProject.id]: string;
    [cProject.name]: string;
    [cProject.description]?: string;
    [cProject.createdOn]?: string;
}

export type ProjectType = Immutable<ProjectInterface>;

export const projectValidationSchema = Yup.object().shape({
    [cProject.name]: Yup.string().required("Project name is required!"),
    [cProject.description]: Yup.string()
})

export const mapProjectFromJS = (src: ProjectInterface): ProjectType => {
    return fromJS({
        [cProject.id]: src[cProject.id],
        [cProject.name]: src[cProject.name],
        [cProject.description]: src[cProject.description] || '',
        [cProject.createdOn]: src[cProject.createdOn],
    })
};

export const mapProjectToJS = (src: ProjectType): ProjectInterface => {
    return {
        [cProject.id]: src.get(cProject.id),
        [cProject.name]: src.get(cProject.name),
        [cProject.description]: src.get(cProject.description)?.length !== 0 ? src.get(cProject.description) : undefined
    }
}

export const projectDefault = (): ProjectType => fromJS({
    [cProject.id]: null,
    [cProject.name]: '',
    [cProject.description]: ''
});