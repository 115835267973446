import React from "react";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import {cParameter, mapParameterFromJS, ParameterType, parameterValidationSchema} from "../../models/Parameter";
import {Formik} from "formik";

interface TaskParameterModalPropsInterface {
    taskId: string;
    className?: string;

    isOpen: boolean;
    parameter: ParameterType;

    onToggle: () => void;
    onSaveParameter: (parameter: ParameterType) => void;
}

class TaskParameterFormModal extends React.PureComponent<TaskParameterModalPropsInterface> {

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle} className={this.props.className}>
                <Formik
                    initialValues={this.props.parameter.toJS()}
                    validationSchema={parameterValidationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize
                    onSubmit={(values, {setSubmitting}) => {
                        let parameter = mapParameterFromJS(values);
                        if (parameter.get(cParameter.id)) {
                            parameter = parameter.set(cParameter.taskId, null);
                        } else {
                            parameter = parameter.set(cParameter.taskId, this.props.taskId);
                        }
                        this.props.onSaveParameter(parameter);
                    }}
                >
                    {
                        ({values, errors, handleChange, handleSubmit, handleBlur, isSubmitting, setFieldValue}) => (
                            <React.Fragment>
                                <Form onSubmit={handleSubmit}>
                                    <ModalHeader>{this.props.parameter.get(cParameter.id) ? "Edit parameter" : "Add parameter"}</ModalHeader>
                                    <ModalBody>

                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="nameField">Name</Label>
                                                    <Input id="nameField" name="name" placeholder="Parameter name"
                                                           value={values[cParameter.name]} onChange={handleChange}
                                                           onBlur={handleBlur} invalid={!!errors[cParameter.name]}/>
                                                    <FormFeedback>{errors[cParameter.name]}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="valueField">Value</Label>
                                                    <Input id="valueField" name="value" placeholder="Parameter value"
                                                           value={values[cParameter.value]} onChange={handleChange}
                                                           onBlur={handleBlur} invalid={!!errors[cParameter.value]}/>
                                                    <FormFeedback>{errors[cParameter.value]}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </ModalBody>

                                    <ModalFooter>
                                        <Button onClick={this.props.onToggle}>Cancel</Button>
                                        <Button color="primary" type="submit" disabled={isSubmitting}>Save</Button>
                                    </ModalFooter>
                                </Form>
                            </React.Fragment>
                        )
                    }
                </Formik>
            </Modal>
        );
    }
}

export default TaskParameterFormModal;