export enum TaskTargetType {
    LOCAL = "LOCAL",
    REMOTE = "REMOTE",
    HPC = "HPC"
}

export const getTaskTargetTypeName = (targetType: TaskTargetType): string => {
    switch (targetType) {
        case TaskTargetType.LOCAL: return "Local";
        case TaskTargetType.REMOTE: return "Remote";
        case TaskTargetType.HPC: return "HPC";
        default:
            return "";
    }
}