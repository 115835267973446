import {composeWithDevTools} from "redux-devtools-extension";
import {applyMiddleware, createStore} from "redux";
import {createBrowserHistory} from "history";
import createRootReducer from "./reducers";
import createSagaMiddleware from 'redux-saga';
import rootSaga from "./sagas";
import {routerMiddleware} from "connected-react-router";
import middleware from "./middleware";

export const history = createBrowserHistory();

export default function configureStore() {
    const sagaMiddleware = createSagaMiddleware();
    const routerHistoryMiddleware = routerMiddleware(history);
    // @ts-ignore
    const middlewareEnhancer = applyMiddleware(routerHistoryMiddleware, sagaMiddleware, ...middleware);
    const composedEnhancers = composeWithDevTools(middlewareEnhancer);
    const rootReducer = createRootReducer(history);
    const store = createStore(rootReducer, composedEnhancers);

    sagaMiddleware.run(rootSaga);

    return store;
}