import {cMetricQuery, MetricQueryInterface} from "./MetricQuery";

export interface ContextMetricQueryInterface {
    [cMetricQuery.id]?: number;
    [cMetricQuery.taskId]: string;
    [cMetricQuery.tagId]: number;
}

export const mapContextQueryFromMetricQuery = (src: MetricQueryInterface): ContextMetricQueryInterface => ({
    [cMetricQuery.id]: src[cMetricQuery.id],
    [cMetricQuery.taskId]: src[cMetricQuery.taskId],
    [cMetricQuery.tagId]: src[cMetricQuery.tagId]
});