import {
    METRIC_GET_ALL_METRICS_GROUPED_BY_CONTEXT,
    METRIC_GET_ALL_METRICS_GROUPED_BY_EPOCH,
    METRIC_GET_ALL_METRICS_GROUPED_BY_TIME,
    METRIC_GET_METRIC_HISTOGRAM_BY_QUERY,
    METRIC_REMOVE_VALUE,
    METRIC_RESET_VALUE
} from "./constants";
import {TimeMetricQueryInterface} from "../../models/TimeMetricQuery";
import {HistogramMetricQueryInterface} from "../../models/HistogramMetricQuery";
import {EpochMetricQueryInterface} from "../../models/EpochMetricQuery";
import {ContextMetricQueryInterface} from "../../models/ContextMetricQuery";

export interface GetAllMetricsGroupedByTimeActionInterface {
    type: string;
    metricQuery: TimeMetricQueryInterface;
}

export interface GetAllMetricsGroupedByEpochActionInterface {
    type: string;
    metricQuery: EpochMetricQueryInterface;
}

export interface GetAllMetricsGroupedByContextActionInterface {
    type: string;
    metricQuery: ContextMetricQueryInterface;
}

export interface GetMetricHistogramByQueryActionInterface {
    type: string;
    metricQuery: HistogramMetricQueryInterface;
}

export interface ResetMetricValueActionInterface {
    type: string;
    field: string;
}

export interface RemoveMetricValueActionInterface {
    type: string;
    path: any[];
}

export const getAllMetricsGroupedByTime = (criteria: TimeMetricQueryInterface): GetAllMetricsGroupedByTimeActionInterface => ({
    type: METRIC_GET_ALL_METRICS_GROUPED_BY_TIME,
    metricQuery: criteria
});

export const getAllMetricsGroupedByEpoch = (criteria: EpochMetricQueryInterface): GetAllMetricsGroupedByEpochActionInterface => ({
    type: METRIC_GET_ALL_METRICS_GROUPED_BY_EPOCH,
    metricQuery: criteria
});

export const getAllMetricsGroupedByContext = (criteria: ContextMetricQueryInterface): GetAllMetricsGroupedByContextActionInterface => ({
    type: METRIC_GET_ALL_METRICS_GROUPED_BY_CONTEXT,
    metricQuery: criteria
});

export const getMetricHistogramByQuery = (criteria: HistogramMetricQueryInterface): GetMetricHistogramByQueryActionInterface => ({
   type: METRIC_GET_METRIC_HISTOGRAM_BY_QUERY,
   metricQuery: criteria
});

export const resetMetricValue = (field: string): ResetMetricValueActionInterface => ({
    type: METRIC_RESET_VALUE,
    field
});

export const removeMetricValue = (path: any[]): RemoveMetricValueActionInterface => ({
    type: METRIC_REMOVE_VALUE,
    path
});