import {
    cTaskReducer as cReducer,
    TASK_GET_ALL_AVAILABLE_TASK_RUNNERS,
    TASK_GET_ALL_BY_PROJECT_ID,
    TASK_GET_BY_ID,
    TASK_RESET_VALUE
} from "./constants";
import {taskDefault, TaskType} from "../../models/Task";
import {pageDefault, PageType} from "../../models/Page";
import {Immutable} from "../../models/Immutable";
import {FULFILLED} from "../../global/constants";
import {TaskTargetType} from "../../models/TaskTargetType";
import {Set} from "immutable";

interface InitialStateInterface {
    [cReducer.task]: TaskType;
    [cReducer.tasks]: PageType<TaskType>;
    [cReducer.taskRunners]: Set<TaskTargetType>;
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
    [cReducer.task]: taskDefault(),
    [cReducer.tasks]: pageDefault<TaskType>(),
    [cReducer.taskRunners]: Set<TaskTargetType>()
});

const reducer = (state: InitialStateType = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case `${TASK_GET_BY_ID}${FULFILLED}`: {
            return state.set(cReducer.task, action.payload);
        }
        case `${TASK_GET_ALL_BY_PROJECT_ID}${FULFILLED}`: {
            return state.set(cReducer.tasks, action.payload);
        }
        case `${TASK_GET_ALL_AVAILABLE_TASK_RUNNERS}${FULFILLED}`: {
            return state.set(cReducer.taskRunners, action.payload);
        }
        case TASK_RESET_VALUE: {
            return state.set(action.field, initialState.get(action.field));
        }
        default: {
            return state;
        }
    }
}

export default reducer;