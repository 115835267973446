import React from 'react';
import './App.css';
import Content from "../../components/Content";
import ContentCard from "../../components/ContentCard";
import {Col, Row} from "reactstrap";

function App() {

  return (
      <Content
        title="Home"
        subtitle=""
      >
          <ContentCard className="ml-5 mr-5 mb-5" style={{minHeight: "calc(100vh - 26vh)"}}>
              <div className="m-5">
                  <Row>
                      <Col>
                          <h5>What is ML project?</h5>
                          <p className="text-left">
                              ML Project is application intended for neural network task management and training
                              monitoring.
                              This application allows you to create and organize your neural network training in
                              tasks
                              which can be therefore executed on remote cluster or supercomputer.
                          </p>
                      </Col>
                      <Col>
                          <h5>How to start?</h5>
                          <p className="text-left">
                              If you are here for the first time please start by selecting "Projects" in left
                              menu and
                              then choose project in which you want to create your experiments. In each project
                              you can
                              upload files, create tags and configure tasks which can be therefore used for
                              training your neural network.
                          </p>
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                          <h5>What is needed for running tasks in remote environments?</h5>
                          <p className="text-left">
                              Task execution and its requirements in remote environments depends on current server settings.
                              Usually you will need to have private SSH key protected by passphrase for login into remote server.
                              This key therefore can be used in this application which will ask for it during task start and stop process.
                          </p>
                      </Col>
                      <Col>
                          <h5>How can I obtain login credentials for access on supercomputer?</h5>
                          <p className="text-left">
                              Access credentials for supercomputer can be obtained by creating request. Please refer to this documentation: <a href="https://docs.it4i.cz/general/obtaining-login-credentials/obtaining-login-credentials">https://docs.it4i.cz/general/obtaining-login-credentials/obtaining-login-credentials</a>
                          </p>
                      </Col>
                  </Row>
              </div>
          </ContentCard>
      </Content>
  );
}

export default App;
