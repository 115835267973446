import React from "react";
import {connect} from "react-redux";
import {cPage, PageType} from "../../models/Page";
import {cGenericFile, GenericFileType, getFileSizeWithUnit} from "../../models/GenericFile";
import {getPaginationFromPage, Pagination} from "../../models/Pagination";
import {cFileReducer} from "../../api/FileApi/constants";
import {SuccessEventCallback} from "../../models/Event";
import {
    deleteFileById,
    downloadFileById,
    getAllFilesByProjectId,
    getFileById,
    resetFileValue,
    saveFile
} from "../../api/FileApi/actions";
import {Button, Col, Row} from "reactstrap";
import TableView, {ColumnDefinition} from "../../components/TableView";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faEdit, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {DATE_TIME_WITHOUT_TIMEZONE_FORMAT} from "../../global/constants";
import PaginationControl from "../../components/PaginationControl";
import {cModalReducer} from "../ModalContainer/constants";
import {toggleModal} from "../ModalContainer/actions";
import ProjectFileFormModal from "../../components/ProjectFileFormModal";
import Dropzone, {DropzoneRef} from "react-dropzone";
import ConfirmationDialog from "../../components/ConfirmationDialog";

interface ProjectFilesTabContainerPropsInterface {
    projectId: string;

    files: PageType<GenericFileType>;
    file: GenericFileType;
    isProjectFileFormModalOpen: boolean;

    toggleProjectFileFormModal: () => void;
    saveFile: (file: GenericFileType, fileContent?: File, successCallbacks?: SuccessEventCallback<string>[]) => void;
    deleteFileById: (fileId: string, successCallbacks?: SuccessEventCallback<void>[]) => void;
    getAllFilesByProjectId: (projectId: string, page?: Pagination) => void;
    getFileById: (fileId: string) => void;
    downloadFileById: (fileId: string, filename: string) => void;
    resetFileValue: () => void;
}

interface ProjectFilesTabContainerStateInterface {
    isDeleteConfirmationDialogOpen: boolean;
    fileToDelete?: string;
}

class ProjectFilesTabContainer extends React.PureComponent<ProjectFilesTabContainerPropsInterface, ProjectFilesTabContainerStateInterface> {

    dropzoneRef = React.createRef<DropzoneRef>();

    state = {
        isDeleteConfirmationDialogOpen: false,
        fileToDelete: undefined
    }

    componentDidMount() {
        this.props.getAllFilesByProjectId(this.props.projectId);
    }

    handleOpenFileChooser = () => {
        if (this.dropzoneRef.current) {
            this.dropzoneRef.current.open();
        }
        this.props.resetFileValue();
    }

    handleFileSave = (file: GenericFileType, fileContent?: File) => {
        this.props.saveFile(
            file,
            fileContent,
            [
                () => getAllFilesByProjectId(this.props.projectId, getPaginationFromPage(this.props.files)),
                () => toggleModal()
            ]
        );
    }

    handleFileEdit = (fileId?: string) => {
        if (fileId) {
            this.props.getFileById(fileId);
            this.props.toggleProjectFileFormModal();
        }
    }

    handleConfirmationDialogToggle = (fileId?: string) => {
        this.setState((prevState) => ({
            ...prevState,
            isDeleteConfirmationDialogOpen: !prevState.isDeleteConfirmationDialogOpen,
            fileToDelete: fileId
        }));
    }

    handleDeleteConfirm = () => {
        const fileId = this.state.fileToDelete;

        if (fileId) {
            this.props.deleteFileById(
                fileId,
                [
                    () => getAllFilesByProjectId(this.props.projectId, getPaginationFromPage(this.props.files))
                ]
            );
            this.handleConfirmationDialogToggle();
        }
    }

    renderOperationsColumn = (rowObject: GenericFileType) => {
        return (
            <span className="d-flex justify-content-end mr-4">
                <FontAwesomeIcon
                    icon={faDownload}
                    className="mr-3"
                />
                <FontAwesomeIcon
                    icon={faEdit}
                    className="cursor-pointer mr-3"
                    onClick={e => {
                        this.handleFileEdit(rowObject.get(cGenericFile.id));
                        e.stopPropagation();
                    }}
                />
                <FontAwesomeIcon
                    icon={faTimes}
                    className="cursor-pointer mr-3"
                    onClick={e => {
                        this.handleConfirmationDialogToggle(rowObject.get(cGenericFile.id));
                        e.stopPropagation();
                    }}
                />
            </span>
        )
    }

    columnDefinitions: ColumnDefinition<GenericFileType>[] = [
        {
            attributeName: cGenericFile.name,
            renderHeader: () => "File name",
            renderCell: rowObject => rowObject.get(cGenericFile.name)
        },
        {
            attributeName: cGenericFile.size,
            renderHeader: () => "Size",
            renderCell: rowObject => getFileSizeWithUnit(rowObject.get(cGenericFile.size) || 0)
        },
        {
            attributeName: cGenericFile.createdOn,
            renderHeader: () => "Created on",
            renderCell: rowObject => moment(rowObject.get(cGenericFile.createdOn)).format(DATE_TIME_WITHOUT_TIMEZONE_FORMAT)
        },
        {
            attributeName: "actions",
            renderHeader: () =>  <span className="d-flex justify-content-end mr-5">Actions</span>,
            renderCell: this.renderOperationsColumn
        }
    ]

    render() {
        return (
            <div className="m-5">
                <Dropzone ref={this.dropzoneRef} noKeyboard onDrop={this.props.toggleProjectFileFormModal} noClick>
                    {
                        ({getRootProps, getInputProps, acceptedFiles}) => (
                            <div {...getRootProps({className: "dropzone"})}>
                                <input {...getInputProps()}/>
                                <Row>
                                    <Col>
                                        <TableView
                                            rows={this.props.files.get(cPage.content)}
                                            rowKeyAttribute={cGenericFile.id}
                                            columnDefinitions={this.columnDefinitions}
                                            onRowClick={rowObject =>
                                                this.props.downloadFileById(
                                                    rowObject.get(cGenericFile.id),
                                                    rowObject.get(cGenericFile.name))
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <PaginationControl page={this.props.files}
                                                           onPageChange={(page) => this.props.getAllFilesByProjectId(this.props.projectId, {page})}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-end flex-grow-1">
                                        <Button color="success" className="pr-5" onClick={this.handleOpenFileChooser}>
                                            <FontAwesomeIcon icon={faPlus} className="mr-5"/>
                                            Add
                                        </Button>
                                    </Col>
                                </Row>
                                {
                                    this.props.isProjectFileFormModalOpen
                                        ? <ProjectFileFormModal
                                            projectId={this.props.projectId}
                                            isOpen={this.props.isProjectFileFormModalOpen}
                                            file={this.props.file}
                                            fileContent={acceptedFiles.length > 0 ? acceptedFiles[0] : undefined}
                                            onToggle={this.props.toggleProjectFileFormModal}
                                            onFileSave={this.handleFileSave}
                                        />
                                        : null
                                }
                            </div>
                        )
                    }
                </Dropzone>
                {
                    this.state.isDeleteConfirmationDialogOpen
                        ? <ConfirmationDialog
                            header="File removal"
                            body="Delete selected file?"
                            isOpen={this.state.isDeleteConfirmationDialogOpen}
                            handleConfirm={this.handleDeleteConfirm}
                            handleCancel={this.handleConfirmationDialogToggle}
                        />
                        : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    file: state.fileApiReducer.get(cFileReducer.file),
    files: state.fileApiReducer.get(cFileReducer.files),
    isProjectFileFormModalOpen: state.modalContainerReducer.get(cModalReducer.isModalOpen)
});

const mapDispatchToProps = (dispatch: any) => ({
    toggleProjectFileFormModal: () => dispatch(toggleModal()),
    saveFile: (file: GenericFileType, fileContent?: File, successCallbacks?: SuccessEventCallback<string>[]) => dispatch(
        saveFile(file, fileContent, {onSuccess: successCallbacks})
    ),
    deleteFileById: (fileId: string, successCallbacks?: SuccessEventCallback<void>[]) => dispatch(
        deleteFileById(fileId, {onSuccess: successCallbacks})
    ),
    getAllFilesByProjectId: (projectId: string, page?: Pagination) => dispatch(getAllFilesByProjectId(projectId, page)),
    getFileById: (fileId: string) => dispatch(getFileById(fileId)),
    downloadFileById: (fileId: string, filename: string) => dispatch(downloadFileById(fileId, filename)),
    resetFileValue: () => dispatch(resetFileValue(cFileReducer.file))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)
(ProjectFilesTabContainer);