import React from "react";
import {cPage, PageType} from "../../models/Page";
import {cMetricQuery, metricQueryDefault, MetricQueryType} from "../../models/MetricQuery";
import {getPaginationFromPage, Pagination} from "../../models/Pagination";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import {cTask} from "../../models/Task";

interface VisualizationTableModalPropsInterface {
    isOpen: boolean;
    foundVisualizations: PageType<MetricQueryType>;

    onToggle: () => void;
    onSearchVisualizationsBySearchString: (searchString: string, page?: Pagination) => void;
    onResetFoundVisualizations: () => void;
    onAddVisualization: (visualization: MetricQueryType) => void;
}

interface VisualizationTableModalStateInterface {
    selectedVisualization?: MetricQueryType;
    isSearchDropdownOpen: boolean;
}

class VisualizationSearchModal extends React.PureComponent<VisualizationTableModalPropsInterface, VisualizationTableModalStateInterface> {

    state = {
        selectedVisualization: undefined,
        isSearchDropdownOpen: false
    }

    componentDidMount() {
        this.props.onResetFoundVisualizations();
    }

    handleSearchInputChange = (event: any) => {
        const textValue = event.target.value;
        const searchString = `name:${textValue},`;
        this.props.onSearchVisualizationsBySearchString(searchString, getPaginationFromPage(this.props.foundVisualizations));
    }

    handleDropdownToggle = () => {
        this.setState(prevState => ({
            ...prevState,
            isSearchDropdownOpen: !prevState.isSearchDropdownOpen
        }));
    }

    handleSave = () => {
        const visualization = this.state.selectedVisualization;
        if (visualization) this.props.onAddVisualization(visualization);
    }

    handleAddVisualization = (visualization: MetricQueryType) => {
        const visualizationId = visualization.get(cMetricQuery.id);
        if (visualizationId) {
            this.setState(prevState => ({
                ...prevState,
                selectedVisualization: visualization
            }));
        }
    }

    renderSearchResult = (): JSX.Element => {
        if (this.props.foundVisualizations.get(cPage.numberOfElements) > 0) {
            const searchResults = this.props.foundVisualizations.get(cPage.content)
                .map(e => (
                    <DropdownItem key={e.get(cMetricQuery.id)} onClick={() => this.handleAddVisualization(e)}>
                        <Row className="w-100">
                            {e.get(cMetricQuery.name)}
                        </Row>
                        <Row className="w-100">
                            <small className="text-secondary">{e.getIn([cMetricQuery.task, cTask.name])}</small>
                        </Row>
                    </DropdownItem>
                ));
            return (
                <>
                    {searchResults}
                </>
            )
        }

        return <DropdownItem>No results</DropdownItem>
    }

    render() {
        const selectedVisualization: MetricQueryType = this.state.selectedVisualization || metricQueryDefault();
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle}>
                <ModalHeader>Add new visualization</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Input type="text" placeholder="Search for visualizations" onClick={this.handleDropdownToggle} onChange={this.handleSearchInputChange} />
                            <Dropdown isOpen={this.state.isSearchDropdownOpen} toggle={this.handleDropdownToggle}>
                                <DropdownToggle tag="span" />
                                <DropdownMenu>
                                    {this.renderSearchResult()}
                                </DropdownMenu>
                            </Dropdown>
                        </FormGroup>
                    </Form>
                    {
                        this.state.selectedVisualization
                         ? <ListGroup>
                                <ListGroupItem>
                                    <Row className="w-100">
                                        {selectedVisualization.get(cMetricQuery.name)}
                                    </Row>
                                    <Row className="w-100">
                                        <small className="text-secondary">{selectedVisualization.getIn([cMetricQuery.task, cTask.name])}</small>
                                    </Row>
                                </ListGroupItem>
                           </ListGroup>
                         : null
                    }
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.onToggle}>Cancel</Button>
                    <Button color="primary" onClick={this.handleSave} disabled={!selectedVisualization.get(cMetricQuery.id)}>Add</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default VisualizationSearchModal;