import {MetricQueryInterface, MetricQueryType} from "../../models/MetricQuery";
import {CallbackInterface} from "../../models/Event";
import {Pagination} from "../../models/Pagination";
import {
    VISUALIZATION_DELETE_BY_ID,
    VISUALIZATION_GET_ALL_BY_PROJECT_ID_AND_SEARCH_STRING,
    VISUALIZATION_GET_ALL_BY_TASK_ID,
    VISUALIZATION_GET_BY_ID,
    VISUALIZATION_RESET_VALUE,
    VISUALIZATION_SAVE
} from "./constants";

export interface SaveVisualizationActionInterface {
    type: string;
    visualization: MetricQueryType;
    callbacks?: CallbackInterface<number>;
}

export interface DeleteVisualizationByIdActionInterface {
    type: string;
    id: number;
    callbacks?: CallbackInterface<void>;
}

export interface GetVisualizationByIdActionInterface {
    type: string;
    id: number;
    callbacks?: CallbackInterface<MetricQueryInterface>;
}

export interface GetAllVisualizationsByTaskIdActionInterface {
    type: string;
    taskId: string;
    page?: Pagination;
}

export interface GetAllVisualizationsByProjectIdAndSearchStringActionInterface {
    type: string;
    projectId: string;
    searchString: string;
    page?: Pagination;
}

export interface ResetVisualizationValueActionInterface {
    type: string;
    field: string;
}

export const saveVisualization = (visualization: MetricQueryType, callbacks?: CallbackInterface<number>): SaveVisualizationActionInterface => ({
    type: VISUALIZATION_SAVE,
    visualization,
    callbacks
});

export const deleteVisualizationById = (id: number, callbacks?: CallbackInterface<void>): DeleteVisualizationByIdActionInterface => ({
    type: VISUALIZATION_DELETE_BY_ID,
    id,
    callbacks
});

export const getVisualizationById = (id: number, callbacks?: CallbackInterface<MetricQueryInterface>): GetVisualizationByIdActionInterface => ({
    type: VISUALIZATION_GET_BY_ID,
    id,
    callbacks
});

export const getAllVisualizationsByTaskId = (taskId: string, page?: Pagination): GetAllVisualizationsByTaskIdActionInterface => ({
    type: VISUALIZATION_GET_ALL_BY_TASK_ID,
    taskId,
    page
});

export const getAllVisualizationsByProjectIdAndSearchString = (projectId: string, searchString: string, page?: Pagination): GetAllVisualizationsByProjectIdAndSearchStringActionInterface => ({
    type: VISUALIZATION_GET_ALL_BY_PROJECT_ID_AND_SEARCH_STRING,
    projectId,
    searchString,
    page
});

export const resetVisualizationValue = (field: string): ResetVisualizationValueActionInterface => ({
    type: VISUALIZATION_RESET_VALUE,
    field
});