import React from "react";
import Content from "../../components/Content";
import ContentCard from "../../components/ContentCard";
import {Button, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Formik} from "formik";
import {cProject, mapProjectFromJS, ProjectType, projectValidationSchema} from "../../models/Project";
import {getProjectById, resetProjectValue, saveProject} from "../../api/ProjectApi/actions";
import {goBack} from "connected-react-router";
import {cProjectReducer} from "../../api/ProjectApi/constants";
import NavigationBackwardLink from "../../components/NavigationBackwardLink";

interface ProjectFormContainerPropsInterface {
    project: ProjectType

    saveProject: (project: ProjectType) => void;
    getProjectById: (id: string) => void;
    resetProjectValue: () => void;
}

class ProjectFormContainer extends React.PureComponent<ProjectFormContainerPropsInterface & RouteComponentProps> {

    componentDidMount() {
        const projectId: string | null = new URLSearchParams(this.props.match.params).get("id");
        if (projectId) {
            this.props.getProjectById(projectId);
        } else {
            this.props.resetProjectValue();
        }
    }

    handleGoBack = () => {
        if (this.props.history.action === "PUSH") {
            this.props.history.goBack();
        } else {
            this.props.history.push("/project-list");
        }
    }

    render() {
        const editMode: boolean = !!this.props.project.get(cProject.id);

        return (
            <Content
                title={editMode ? "Edit project" : "Create project"}
                subtitle={<NavigationBackwardLink subtitle="Project details" onGoBack={this.handleGoBack}/>}
            >
                <ContentCard className="ml-5 mr-5">
                    <div className="m-5">
                        <Formik
                            initialValues={this.props.project.toJS()}
                            validationSchema={projectValidationSchema}
                            validateOnChange={false}
                            validateOnBlur={false}
                            enableReinitialize
                            onSubmit={((values, {setSubmitting}) => {
                                this.props.saveProject(mapProjectFromJS(values))
                            })}
                        >
                            {({values, touched, errors, handleChange, handleSubmit, handleBlur, isSubmitting}) => (
                                <React.Fragment>
                                    <Form onSubmit={handleSubmit}>
                                        <FormGroup>
                                            <Label for="nameField">Name</Label>
                                            <Input id="nameField" name="name" placeholder="Project name" value={values[cProject.name]} onChange={handleChange} onBlur={handleBlur} invalid={!!errors[cProject.name]} />
                                            <FormFeedback>{errors[cProject.name]}</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="descriptionField">Description</Label>
                                            <Input id="descriptionField" name="description" type="textarea" placeholder="Project description" value={values[cProject.description]} onChange={handleChange} onBlur={handleBlur} invalid={!!errors[cProject.description]} />
                                            <FormFeedback>{errors[cProject.description]}</FormFeedback>
                                        </FormGroup>
                                        <div className="d-flex justify-content-end">
                                            {/*<Link to="/project-list"><Button className="mr-2">Cancel</Button></Link>*/}
                                            <Button className="mr-2" onClick={this.handleGoBack}>Cancel</Button>
                                            <Button color="primary" type="submit" disabled={isSubmitting}>Save</Button>
                                        </div>
                                    </Form>
                                </React.Fragment>
                            )}
                        </Formik>
                    </div>
                </ContentCard>
            </Content>
        );
    }
}

const mapStateToProps = (state: any) => ({
    project: state.projectApiReducer.get(cProjectReducer.project)
});

const mapDispatchToProps = (dispatch: any) => ({
    saveProject: (project: ProjectType) => dispatch(
        saveProject(
            project,
            {
                onSuccess: [() => goBack()]
                // onSuccess: [() => push("/project-list")]
            })
    ),
    getProjectById: (id: string) => dispatch(getProjectById(id)),
    resetProjectValue: () => dispatch(resetProjectValue())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ProjectFormContainer));