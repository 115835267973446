import {Immutable} from "./Immutable";
import {fromJS} from "immutable";
import * as Yup from "yup";
import {ProjectTagType} from "./ProjectTag";
import {TaskType} from "./Task";

export enum cMetricQuery {
    id = "id",
    name = "name",
    description = "description",
    taskId = "taskId",
    tagId = "tagId",
    groupBy = "groupBy",
    bucketWidth = "bucketWidth",
    timeUnit = "timeUnit",
    timeFrom = "timeFrom",
    timeTo = "timeTo",
    histogramFrom = "histogramFrom",
    histogramTo = "histogramTo",
    histogramBucketCount = "histogramBucketCount",
    epochFrom = "epochFrom",
    epochTo = "epochTo",
    chartType = "chartType",
    tag = "tag",
    task = "task"
}

export enum PlotType {
    SCATTER = "scatter",
    BAR = "bar",
    HISTOGRAM = "histogram"
}

export enum ChartType {
    LINE = "LINE",
    BAR = "BAR",
    HISTOGRAM = "HISTOGRAM"
}

export enum MetricAggregationField {
    TIME = "TIME",
    EPOCH = "EPOCH",
    CONTEXT = "CONTEXT"
}

export enum TimeUnit {
    SECONDS = "s",
    MINUTES = "m",
    HOURS = "h"
}

export interface MetricQueryInterface {
    [cMetricQuery.id]?: number;
    [cMetricQuery.name]: string;
    [cMetricQuery.description]?: string;
    [cMetricQuery.taskId]: string;
    [cMetricQuery.tagId]: number;
    [cMetricQuery.groupBy]: MetricAggregationField;
    [cMetricQuery.bucketWidth]?: number;
    [cMetricQuery.timeUnit]?: TimeUnit;
    [cMetricQuery.timeFrom]?: string;
    [cMetricQuery.timeTo]?: string;
    [cMetricQuery.histogramFrom]?: number;
    [cMetricQuery.histogramTo]?: number;
    [cMetricQuery.histogramBucketCount]?: number;
    [cMetricQuery.epochFrom]?: number;
    [cMetricQuery.epochTo]?: number;
    [cMetricQuery.chartType]: ChartType;
    [cMetricQuery.tag]: ProjectTagType;
    [cMetricQuery.task]: TaskType;
}

export type MetricQueryType = Immutable<MetricQueryInterface>;

export const metricValidationSchema = Yup.object().shape({
    [cMetricQuery.taskId]: Yup.string().required(),
    [cMetricQuery.tagId]: Yup.number().required(),
    [cMetricQuery.groupBy]: Yup.string().required(),
    [cMetricQuery.bucketWidth]: Yup.number().optional().nullable().min(1),
    [cMetricQuery.epochFrom]: Yup.number().optional().nullable().min(0),
    [cMetricQuery.epochTo]: Yup.number().optional().nullable().min(Yup.ref(cMetricQuery.epochFrom)),
    [cMetricQuery.histogramFrom]: Yup.number().required().default(0),
    [cMetricQuery.histogramTo]: Yup.number().required().moreThan(Yup.ref(cMetricQuery.histogramFrom)),
    [cMetricQuery.histogramBucketCount]: Yup.number().required().min(1)
})

export const mapMetricQueryFromJS = (src: MetricQueryInterface): MetricQueryType => {
    return fromJS({
        [cMetricQuery.id]: src[cMetricQuery.id],
        [cMetricQuery.name]: src[cMetricQuery.name],
        [cMetricQuery.description]: src[cMetricQuery.description] || '',
        [cMetricQuery.taskId]: src[cMetricQuery.taskId],
        [cMetricQuery.tagId]: src[cMetricQuery.tagId],
        [cMetricQuery.groupBy]: src[cMetricQuery.groupBy],
        [cMetricQuery.bucketWidth]: src[cMetricQuery.bucketWidth],
        [cMetricQuery.timeUnit]: src[cMetricQuery.timeUnit],
        [cMetricQuery.timeFrom]: src[cMetricQuery.timeFrom],
        [cMetricQuery.timeTo]: src[cMetricQuery.timeTo],
        [cMetricQuery.histogramFrom]: src[cMetricQuery.histogramFrom],
        [cMetricQuery.histogramTo]: src[cMetricQuery.histogramTo],
        [cMetricQuery.histogramBucketCount]: src[cMetricQuery.histogramBucketCount],
        [cMetricQuery.epochFrom]: src[cMetricQuery.epochFrom],
        [cMetricQuery.epochTo]: src[cMetricQuery.epochTo],
        [cMetricQuery.chartType]: src[cMetricQuery.chartType],
        [cMetricQuery.tag]: src[cMetricQuery.tag],
        [cMetricQuery.task]: src[cMetricQuery.task]
    })
}

export const mapMetricQueryToJS = (src: MetricQueryType): MetricQueryInterface => ({
    [cMetricQuery.id]: src.get(cMetricQuery.id),
    [cMetricQuery.name]: src.get(cMetricQuery.name),
    [cMetricQuery.description]: src.get(cMetricQuery.description)?.length !== 0 ? src.get(cMetricQuery.description) : undefined,
    [cMetricQuery.taskId]: src.get(cMetricQuery.taskId),
    [cMetricQuery.tagId]: src.get(cMetricQuery.tagId),
    [cMetricQuery.groupBy]: src.get(cMetricQuery.groupBy),
    [cMetricQuery.bucketWidth]: src.get(cMetricQuery.bucketWidth),
    [cMetricQuery.timeUnit]: src.get(cMetricQuery.timeUnit),
    [cMetricQuery.timeFrom]: src.get(cMetricQuery.timeFrom),
    [cMetricQuery.timeTo]: src.get(cMetricQuery.timeTo),
    [cMetricQuery.histogramFrom]: src.get(cMetricQuery.histogramFrom),
    [cMetricQuery.histogramTo]: src.get(cMetricQuery.histogramTo),
    [cMetricQuery.histogramBucketCount]: src.get(cMetricQuery.histogramBucketCount),
    [cMetricQuery.epochFrom]: src.get(cMetricQuery.epochFrom),
    [cMetricQuery.epochTo]: src.get(cMetricQuery.epochTo),
    [cMetricQuery.chartType]: src.get(cMetricQuery.chartType),
    [cMetricQuery.tag]: src.get(cMetricQuery.tag),
    [cMetricQuery.task]: src.get(cMetricQuery.task)
});

export const metricQueryDefault = (): MetricQueryType => fromJS({
    [cMetricQuery.id]: null,
    [cMetricQuery.name]: "",
    [cMetricQuery.description]: undefined,
    [cMetricQuery.taskId]: undefined,
    [cMetricQuery.tagId]: undefined,
    [cMetricQuery.groupBy]: MetricAggregationField.TIME,
    [cMetricQuery.bucketWidth]: 1,
    [cMetricQuery.timeUnit]: TimeUnit.SECONDS,
    [cMetricQuery.timeFrom]: undefined,
    [cMetricQuery.timeTo]: undefined,
    [cMetricQuery.histogramFrom]: 0,
    [cMetricQuery.histogramTo]: 1,
    [cMetricQuery.histogramBucketCount]: 1,
    [cMetricQuery.epochFrom]: undefined,
    [cMetricQuery.epochTo]: undefined,
    [cMetricQuery.chartType]: ChartType.LINE,
    [cMetricQuery.tag]: undefined,
    [cMetricQuery.task]: undefined
});

export const getPlotTypeFromChartType = (type: ChartType): PlotType => {
    switch (type) {
        case ChartType.LINE: return PlotType.SCATTER;
        case ChartType.BAR: return PlotType.BAR;
        case ChartType.HISTOGRAM: return PlotType.HISTOGRAM;
    }
}