import {ALERT_ADD_FIRST, ALERT_REMOVE_LAST, cAlertReducer} from "./constants";
import {AlertType, mapAlertFromJS} from "../../models/Alert";
import {Immutable} from "../../models/Immutable";
import {List} from "immutable";

interface InitialStateInterface {
    [cAlertReducer.alerts]: List<AlertType>;
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
    [cAlertReducer.alerts]: List<AlertType>()
})

const reducer = (state: InitialStateType = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case ALERT_ADD_FIRST: {
            const alerts = state.get(cAlertReducer.alerts).insert(0, mapAlertFromJS(action.alert));
            return state.set(cAlertReducer.alerts, alerts);
        }
        case ALERT_REMOVE_LAST: {
            const alerts = state.get(cAlertReducer.alerts).pop();
            return state.set(cAlertReducer.alerts, alerts);
        }
        default: {
            return state;
        }
    }
}

export default reducer;