import {cDashboardItemReducer, DASHBOARD_ITEM_GET_ALL_BY_DASHBOARD_ID, DASHBOARD_ITEM_RESET_VALUE} from "./constants";
import {dashboardItemDefault, DashboardItemType} from "../../models/DashboardItem";
import {List} from "immutable";
import {Immutable} from "../../models/Immutable";
import {FULFILLED} from "../../global/constants";

interface InitialStateInterface {
    [cDashboardItemReducer.dashboardItem]: DashboardItemType;
    [cDashboardItemReducer.dashboardItems]: List<DashboardItemType>;
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
    [cDashboardItemReducer.dashboardItem]: dashboardItemDefault(),
    [cDashboardItemReducer.dashboardItems]: List<DashboardItemType>()
});

const reducer = (state: InitialStateType = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case `${DASHBOARD_ITEM_GET_ALL_BY_DASHBOARD_ID}${FULFILLED}`: {
            return state.set(cDashboardItemReducer.dashboardItems, action.payload);
        }
        case DASHBOARD_ITEM_RESET_VALUE: {
            return state.set(action.field, initialState.get(action.field));
        }
        default:
            return state;
    }
}

export default reducer;