import {DashboardItemType} from "../../models/DashboardItem";
import {CallbackInterface} from "../../models/Event";
import {
    DASHBOARD_ITEM_DELETE_BY_ID,
    DASHBOARD_ITEM_GET_ALL_BY_DASHBOARD_ID,
    DASHBOARD_ITEM_RESET_VALUE,
    DASHBOARD_ITEM_SAVE
} from "./constants";

export interface SaveDashboardItemActionInterface {
    type: string;
    dashboardItem: DashboardItemType;
    callbacks?: CallbackInterface<number>;
}

export interface DeleteDashboardItemByIdActionInterface {
    type: string;
    id: number;
    callbacks?: CallbackInterface<void>;
}

export interface GetAllDashboardItemsByDashboardIdActionInterface {
    type: string;
    dashboardId: number;
}

export interface ResetDashboardItemValueActionInterface {
    type: string;
    field: string;
}

export const saveDashboardItem = (dashboardItem: DashboardItemType, callbacks?: CallbackInterface<number>): SaveDashboardItemActionInterface => ({
    type: DASHBOARD_ITEM_SAVE,
    dashboardItem,
    callbacks
});

export const deleteDashboardItemById = (id: number, callbacks?: CallbackInterface<void>): DeleteDashboardItemByIdActionInterface => ({
    type: DASHBOARD_ITEM_DELETE_BY_ID,
    id,
    callbacks
});

export const getAllDashboardItemsByDashboardId = (dashboardId: number): GetAllDashboardItemsByDashboardIdActionInterface => ({
    type: DASHBOARD_ITEM_GET_ALL_BY_DASHBOARD_ID,
    dashboardId
});

export const resetDashboardItemValue = (field: string): ResetDashboardItemValueActionInterface => ({
    type: DASHBOARD_ITEM_RESET_VALUE,
    field
});