import {Immutable} from "./Immutable";
import {fromJS} from "immutable";

export enum cPlain2DPoint {
    x = "x",
    y = "y"
}

export interface Plain2DPointInterface {
    [cPlain2DPoint.x]: any;
    [cPlain2DPoint.y]: any;
}

export type Plain2DPointType = Immutable<Plain2DPointInterface>;

export const mapPlain2DPointFromJS = (src: Plain2DPointInterface): Plain2DPointType => {
    return fromJS({
        [cPlain2DPoint.x]: src[cPlain2DPoint.x],
        [cPlain2DPoint.y]: src[cPlain2DPoint.y]
    });
}

export const mapPlain2DPointToJS = (src: Plain2DPointType): Plain2DPointInterface => ({
    [cPlain2DPoint.x]: src.get(cPlain2DPoint.x),
    [cPlain2DPoint.y]: src.get(cPlain2DPoint.y)
});

export const plain2DPointDefault = (): Plain2DPointType => fromJS({
   [cPlain2DPoint.x]: 0,
   [cPlain2DPoint.y]: 0
});