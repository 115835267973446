import {cPage, PageType} from "./Page";

export interface Pagination {
    page?: number;
    size?: number;
}

export enum cPagination {
    page = "page",
    size = "size"
}

export const getPaginationFromPage = <T>(page: PageType<T>): Pagination => ({
    page: page.get(cPage.number),
    size: page.get(cPage.size)
})