import React from "react";
import {cPage, PageType} from "../../models/Page";
import {cTask, TaskType} from "../../models/Task";
import {connect} from "react-redux";
import {Pagination} from "../../models/Pagination";
import {getAllTasksByProjectId, updateTaskState} from "../../api/TaskApi/actions";
import {cTaskReducer} from "../../api/TaskApi/constants";
import {
    Button,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";
import TableView, {ColumnDefinition} from "../../components/TableView";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV, faExclamationCircle, faPlay, faPlus, faStop} from "@fortawesome/free-solid-svg-icons";
import {getTaskStateName, TaskState} from "../../models/TaskState";
import {DATE_TIME_WITHOUT_TIMEZONE_FORMAT} from "../../global/constants";
import moment from "moment";
import PaginationControl from "../../components/PaginationControl";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {CallbackInterface, SuccessEventCallback} from "../../models/Event";
import TaskCredentialsModal from "../../components/TaskCredentialsModal";
import {TaskTargetType} from "../../models/TaskTargetType";
import {SshCredentialsInterface} from "../../models/SshCredentials";
import TaskFileTableModal from "../../components/TaskFileTableModal";
import {cFileReducer} from "../../api/FileApi/constants";
import {cGenericFile, GenericFileType} from "../../models/GenericFile";
import {Set} from "immutable";
import {resetFileValue, searchProjectFilesByTaskIdAndSearchString} from "../../api/FileApi/actions";
import {
    cInputProcessingRequest,
    InputProcessingRequestType,
    mapInputProcessingRequestFromJS
} from "../../models/InputProcessingRequest";
import {saveInputProcessingRequest} from "../../api/InputProcessingRequestApi/actions";
import {cancelProcessingRequestResultDownload, downloadProcessingRequestResult} from "./actions";

interface ProjectTasksTabContainerPropsInterface {
    projectId: string;

    tasks: PageType<TaskType>;
    foundFiles: PageType<GenericFileType>;

    getAllTasksByProjectId: (projectId: string, page?: Pagination) => void;
    updateTaskState: (id: string, state: TaskState, credentials?: SshCredentialsInterface, callbacks?: CallbackInterface<TaskState>) => void;
    searchProjectFilesByTaskIdAndSearchString: (taskId: string, searchString: string, page: Pagination) => void;
    saveInputProcessingRequest: (request: InputProcessingRequestType, successEventCallbacks?: SuccessEventCallback<string>[]) => void;
    cancelProcessingRequestDownload: () => void;
    resetFileValue: () => void;
}

interface ProjectTasksTabContainerStateInterface {
    refreshIntervalId?: NodeJS.Timeout;
    isTaskCredentialsModalOpen: boolean;
    selectedTaskId?: string;
    selectedTaskTargetState?: TaskState;
    isFilePickerOpen: boolean;
}

class ProjectTasksTabContainer extends React.PureComponent<ProjectTasksTabContainerPropsInterface & RouteComponentProps, ProjectTasksTabContainerStateInterface> {

    state = {
        refreshIntervalId: undefined,
        isTaskCredentialsModalOpen: false,
        selectedTaskId: undefined,
        selectedTaskTargetState: undefined,
        isFilePickerOpen: false
    }

    componentDidMount() {
        this.props.getAllTasksByProjectId(this.props.projectId);
        const refreshIntervalId = setInterval(() => {
            this.props.getAllTasksByProjectId(this.props.projectId);
        }, 5000);
        this.setState((prevState) => ({
            ...prevState,
            refreshIntervalId
        }))
    }

    componentWillUnmount() {
        clearInterval(this.state.refreshIntervalId);
        this.props.cancelProcessingRequestDownload();
    }

    toggleFilePicker = () => {
        this.setState((prevState) => ({
            ...prevState,
            isFilePickerOpen: !prevState.isFilePickerOpen
        }));
    }

    handleSendInputProcessingRequest = (files: Set<GenericFileType>) => {
        files.forEach(e => console.log(e.get(cGenericFile.name)));
        if (!files.isEmpty()) {
            const file: GenericFileType = files.first();
            this.props.saveInputProcessingRequest(
                mapInputProcessingRequestFromJS({
                    [cInputProcessingRequest.taskId]: this.state.selectedTaskId || "",
                    [cInputProcessingRequest.fileId]: file.get(cGenericFile.id) || ""
                }),
                [
                    requestId => downloadProcessingRequestResult(requestId || "", this.props.projectId)
                ]
            );
        }
        this.toggleFilePicker();
    }

    handleTaskCredentialsModalToggle = () => {
        this.setState((prevState) => ({
            ...prevState,
            isTaskCredentialsModalOpen: !prevState.isTaskCredentialsModalOpen
        }));
    }

    handleUpdateTaskState = (credentials?: SshCredentialsInterface) => {
        const {selectedTaskId, selectedTaskTargetState} = this.state;
        if (selectedTaskId && selectedTaskTargetState) {
            this.props.updateTaskState(selectedTaskId!, selectedTaskTargetState!, credentials, {
                onSuccess: [() => getAllTasksByProjectId(this.props.projectId, {page: this.props.tasks.get(cPage.number)})]
            })
        }
        this.handleTaskCredentialsModalToggle();
    }

    renderActionsColumn = (rowObject: TaskType) => {
        let iconDefinition;
        let targetState: TaskState;
        let disabled = false;

        switch (rowObject.get(cTask.state)) {
            case TaskState.COMPLETED:
            case TaskState.STOPPED:
            case TaskState.FAILED:
            case TaskState.READY:
                iconDefinition = faPlay;
                targetState = TaskState.STARTING;
                break;
            case TaskState.STARTING:
                iconDefinition = faStop;
                disabled = true;
                break;
            case TaskState.STOPPING:
                iconDefinition = faPlay;
                disabled = true;
                break;
            default:
                iconDefinition = faStop;
                targetState = TaskState.STOPPING;
                break;
        }

        return (
            <span className="d-flex justify-content-end align-items-center mr-4">
                <FontAwesomeIcon
                    icon={iconDefinition}
                    className={`mr-3 ${!disabled ? "cursor-pointer" : "text-muted"}`}
                    onClick={(e) => {
                        if (disabled) return;

                        if (rowObject.get(cTask.targetType) === TaskTargetType.REMOTE || rowObject.get(cTask.targetType) === TaskTargetType.HPC) {
                            this.setState({
                                selectedTaskId: rowObject.get(cTask.id),
                                selectedTaskTargetState: targetState
                            }, () => this.handleTaskCredentialsModalToggle());
                        } else {
                            this.props.updateTaskState(rowObject.get(cTask.id) || "", targetState, undefined, {
                                onSuccess: [() => getAllTasksByProjectId(this.props.projectId, {page: this.props.tasks.get(cPage.number)})]
                            })
                        }
                        e.stopPropagation();
                    }}
                />
                <UncontrolledDropdown>
                    <DropdownToggle
                        tag="span"
                        data-toggle="dropdown"
                        onClick={e => e.stopPropagation()}
                    >
                        <FontAwesomeIcon
                            icon={faEllipsisV}
                            className="mr-2 cursor-pointer"
                        />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem
                            disabled={rowObject.get(cTask.state) !== TaskState.RUNNING}
                            onClick={e => {
                                this.setState({
                                    selectedTaskId: rowObject.get(cTask.id)
                                }, () => this.toggleFilePicker());
                                e.stopPropagation();
                            }}>
                            New request
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </span>
        )
    }

    renderStateColumn = (rowObject: TaskType) => {
        const state = rowObject.get(cTask.state);
        const errorLog = rowObject.get(cTask.errorLog);
        return (
            <>
                {
                    getTaskStateName(state || TaskState.READY)
                }
                <span id="error-sign">
                {
                    state === TaskState.FAILED
                        ?
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            className="ml-1"
                            color="red"
                        />
                        : null
                }
                {
                    errorLog
                        ? <UncontrolledTooltip target="error-sign" placement="right" autohide={false} onClick={e => e.stopPropagation()}>
                            {errorLog}
                          </UncontrolledTooltip>
                        : null
                }
                </span>
            </>
        )
    }

    columnDefinition: ColumnDefinition<TaskType>[] = [
        {
            attributeName: cTask.name,
            renderHeader: () => "Name",
            renderCell: rowObject => rowObject.get(cTask.name)
        },
        {
            attributeName: cTask.targetType,
            renderHeader: () => "Target",
            renderCell: rowObject => rowObject.get(cTask.targetType)
        },
        {
            attributeName: cTask.queueType,
            renderHeader: () => "Queue type",
            renderCell: rowObject => rowObject.get(cTask.queueType) || "-"
        },
        {
            attributeName: cTask.createdOn,
            renderHeader: () => "Created on",
            renderCell: rowObject => moment.utc(rowObject.get(cTask.createdOn)).local().format(DATE_TIME_WITHOUT_TIMEZONE_FORMAT)
        },
        {
            attributeName: cTask.state,
            renderHeader: () => "State",
            renderCell: this.renderStateColumn
        },
        {
            attributeName: "operations",
            renderHeader: () =>  <span className="d-flex justify-content-end mr-2">Operations</span>,
            renderCell: this.renderActionsColumn
        }
    ]

    render() {
        return (
            <div className="m-5">
                <Row>
                    <Col xs={12}>
                        <TableView
                            rows={this.props.tasks.get(cPage.content)}
                            rowKeyAttribute={cTask.id}
                            columnDefinitions={this.columnDefinition}
                            onRowClick={(rowObject: TaskType) => this.props.history.push(`/task/${rowObject.get(cTask.id)}`)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <PaginationControl
                            page={this.props.tasks}
                            onPageChange={(page) => this.props.getAllTasksByProjectId(this.props.projectId, {page})}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="d-flex justify-content-end flex-grow-1">
                        <Link to={`/task-form/project/${this.props.projectId}`}>
                            <Button color="success" className="pr-5">
                                <FontAwesomeIcon icon={faPlus} className="mr-5" />
                                Add
                            </Button>
                        </Link>
                    </Col>
                </Row>
                {
                    this.state.isTaskCredentialsModalOpen
                    ? <TaskCredentialsModal
                            isOpen={this.state.isTaskCredentialsModalOpen}
                            onToggle={this.handleTaskCredentialsModalToggle}
                            onConfirm={this.handleUpdateTaskState}
                        />
                    : null
                }
                {
                    this.state.isFilePickerOpen
                        ? <TaskFileTableModal
                            headerText="Select file to process"
                            confirmButtonText="OK"
                            isMultiselect={false}
                            isOpen={this.state.isFilePickerOpen}
                            foundFiles={this.props.foundFiles}
                            onToggle={this.toggleFilePicker}
                            onSearchProjectFilesBySearchString={
                                (searchString, page) =>
                                    this.props.searchProjectFilesByTaskIdAndSearchString(this.state.selectedTaskId || "", searchString, page)}
                            onSaveAllTaskFiles={this.handleSendInputProcessingRequest}
                            onResetFoundFiles={this.props.resetFileValue}
                        />
                        : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    tasks: state.taskApiReducer.get(cTaskReducer.tasks),
    foundFiles: state.fileApiReducer.get(cFileReducer.files)
});

const mapDispatchToProps = (dispatch: any) => ({
    getAllTasksByProjectId: (projectId: string, page?: Pagination) => dispatch(getAllTasksByProjectId(projectId, page)),
    updateTaskState: (id: string, state: TaskState, credentials?: SshCredentialsInterface, callbacks?: CallbackInterface<TaskState>) => dispatch(updateTaskState(id, state, credentials, callbacks)),
    searchProjectFilesByTaskIdAndSearchString: (taskId: string, searchString: string, page: Pagination) => dispatch(searchProjectFilesByTaskIdAndSearchString(taskId, searchString, page)),
    saveInputProcessingRequest: (request: InputProcessingRequestType, successEventCallbacks?: SuccessEventCallback<string>[]) => dispatch(saveInputProcessingRequest(request, {
        onSuccess: successEventCallbacks
    })),
    cancelProcessingRequestDownload: () => dispatch(cancelProcessingRequestResultDownload()),
    resetFileValue: () => dispatch(resetFileValue(cFileReducer.files))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ProjectTasksTabContainer));