import {cMetricQuery, MetricQueryInterface} from "./MetricQuery";

export interface TimeMetricQueryInterface {
    [cMetricQuery.id]?: number;
    [cMetricQuery.taskId]: string;
    [cMetricQuery.tagId]: number;
    [cMetricQuery.bucketWidth]?: string;
    [cMetricQuery.timeFrom]?: string;
    [cMetricQuery.timeTo]?: string;
}

export const mapTimeQueryFromMetricQuery = (src: MetricQueryInterface): TimeMetricQueryInterface => ({
    [cMetricQuery.id]: src[cMetricQuery.id],
    [cMetricQuery.taskId]: src[cMetricQuery.taskId],
    [cMetricQuery.tagId]: src[cMetricQuery.tagId],
    [cMetricQuery.bucketWidth]: (src[cMetricQuery.bucketWidth] || "1") + (src[cMetricQuery.timeUnit] || "s"),
    [cMetricQuery.timeFrom]: src[cMetricQuery.timeFrom],
    [cMetricQuery.timeTo]: src[cMetricQuery.timeTo]
});