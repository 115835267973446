import React from "react";
import {Button, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {cPage, PageType} from "../../models/Page";
import {cDashboard, dashboardDefault, DashboardType} from "../../models/Dashboard";
import {getPaginationFromPage, Pagination} from "../../models/Pagination";
import {cDashboardReducer} from "../../api/DashboardApi/constants";
import {deleteDashboardById, getAllDashboardsByProjectId, saveDashboard} from "../../api/DashboardApi/actions";
import TableView, {ColumnDefinition} from "../../components/TableView";
import PaginationControl from "../../components/PaginationControl";
import {DATE_TIME_WITHOUT_TIMEZONE_FORMAT} from "../../global/constants";
import moment from "moment";
import DashboardFormModal from "../../components/DashboardFormModal";
import {SuccessEventCallback} from "../../models/Event";
import {push} from "connected-react-router";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {RouteComponentProps, withRouter} from "react-router-dom";

interface ProjectDashboardsTabContainerPropsInterface {
    projectId: string;

    dashboards: PageType<DashboardType>;

    saveDashboard: (dashboard: DashboardType, successEventCallbacks?: SuccessEventCallback<number>[]) => void;
    deleteDashboardById: (id: number, successEventCallbacks?: SuccessEventCallback<void>[]) => void;
    getAllDashboardsByProjectId: (projectId: string, page?: Pagination) => void;
}

interface ProjectDashboardsTabContainerStateInterface {
    isDashboardFormModalOpen: boolean;
    isDeleteConfirmationDialogOpen: boolean;
    dashboard: DashboardType;
    dashboardToDelete?: number;
}

class ProjectDashboardsTabContainer extends React.PureComponent<ProjectDashboardsTabContainerPropsInterface & RouteComponentProps, ProjectDashboardsTabContainerStateInterface> {

    state = {
        isDashboardFormModalOpen: false,
        isDeleteConfirmationDialogOpen: false,
        dashboard: dashboardDefault(),
        dashboardToDelete: undefined
    }

    componentDidMount() {
        this.props.getAllDashboardsByProjectId(this.props.projectId);
    }

    handleSaveDashboard = (dashboard: DashboardType) => {
        const successCallbacks: SuccessEventCallback<number>[] = [];

        if (dashboard.get(cDashboard.id) === null) {
            successCallbacks.push(id => push(`/dashboard/${id}`));
        } else {
            successCallbacks.push(() => getAllDashboardsByProjectId(this.props.projectId, getPaginationFromPage(this.props.dashboards)));
            this.handleToggleDashboardFormModal();
        }

        this.props.saveDashboard(dashboard, successCallbacks);
    }

    handleEditDashboard = (dashboard: DashboardType) => {
        this.setState((prevState) => ({
            ...prevState,
            dashboard: dashboard,
            isDashboardFormModalOpen: !prevState.isDashboardFormModalOpen
        }));
    }

    handleDeleteConfirm = () => {
        if (!this.state.dashboardToDelete) return;

        this.props.deleteDashboardById(
            this.state.dashboardToDelete || -1,
            [
                () => getAllDashboardsByProjectId(this.props.projectId, getPaginationFromPage(this.props.dashboards))
            ]
        );
        this.handleConfirmationDialogToggle();
    }

    handleToggleDashboardFormModal = () => {
        this.setState((prevState) => ({
            ...prevState,
            isDashboardFormModalOpen: !prevState.isDashboardFormModalOpen,
            dashboard: dashboardDefault()
        }));
    }

    handleConfirmationDialogToggle = (id?: number) => {
        this.setState((prevState) => ({
            ...prevState,
            isDeleteConfirmationDialogOpen: !prevState.isDeleteConfirmationDialogOpen,
            dashboardToDelete: id
        }));
    }

    renderOperationsColumn = (rowObject: DashboardType) => {
        return (
            <span className="d-flex justify-content-end mr-4">
                <FontAwesomeIcon
                    icon={faEdit}
                    className="cursor-pointer mr-3"
                    onClick={e => {
                        this.handleEditDashboard(rowObject);
                        e.stopPropagation();
                    }}
                />
                <FontAwesomeIcon
                    icon={faTrash}
                    className="cursor-pointer mr-2"
                    onClick={e => {
                        this.handleConfirmationDialogToggle(rowObject.get(cDashboard.id));
                        e.stopPropagation();
                    }}
                />
            </span>
        )
    }

    columnDefinitions: ColumnDefinition<DashboardType>[] = [
        {
            attributeName: cDashboard.name,
            renderHeader: () => "Name",
            renderCell: rowObject => rowObject.get(cDashboard.name)
        },
        {
            attributeName: cDashboard.createdOn,
            renderHeader: () => "Created on",
            renderCell: rowObject => moment(rowObject.get(cDashboard.createdOn)).format(DATE_TIME_WITHOUT_TIMEZONE_FORMAT)
        },
        {
            attributeName: "actions",
            renderHeader: () => <span className="d-flex justify-content-end mr-4">Actions</span>,
            renderCell: this.renderOperationsColumn
        }
    ]

    render() {
        return (
            <div className="m-5">
                <Row>
                    <Col>
                        <TableView
                            rows={this.props.dashboards.get(cPage.content)}
                            rowKeyAttribute={cDashboard.id}
                            columnDefinitions={this.columnDefinitions}
                            onRowClick={rowObject => this.props.history.push(`/dashboard/${rowObject.get(cDashboard.id)}`)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PaginationControl
                            page={this.props.dashboards}
                            onPageChange={page => this.props.getAllDashboardsByProjectId(this.props.projectId, {page})}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end flex-grow-1">
                        <Button color="success" className="pr-5" onClick={this.handleToggleDashboardFormModal}>
                            <FontAwesomeIcon icon={faPlus} className="mr-5" />
                            Add
                        </Button>
                    </Col>
                </Row>
                {
                    this.state.isDashboardFormModalOpen
                        ? <DashboardFormModal
                            isOpen={this.state.isDashboardFormModalOpen}
                            onToggle={this.handleToggleDashboardFormModal}
                            onDashboardSave={this.handleSaveDashboard}
                            projectId={this.props.projectId}
                            dashboard={this.state.dashboard}
                        />
                        : null
                }
                {
                    this.state.isDeleteConfirmationDialogOpen
                        ? <ConfirmationDialog
                            header="Dashboard delete"
                            body="Delete selected dashboard?"
                            isOpen={this.state.isDeleteConfirmationDialogOpen}
                            handleConfirm={this.handleDeleteConfirm}
                            handleCancel={this.handleConfirmationDialogToggle}
                        /> : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    dashboards: state.dashboardApiReducer.get(cDashboardReducer.dashboards)
});

const mapDispatchToProps = (dispatch: any) => ({
    saveDashboard: (dashboard: DashboardType, successEventCallbacks?: SuccessEventCallback<number>[]) => dispatch(
        saveDashboard(dashboard, {onSuccess: successEventCallbacks})
    ),
    deleteDashboardById: (id: number, successEventCallbacks?: SuccessEventCallback<void>[]) => dispatch(
        deleteDashboardById(id, {onSuccess: successEventCallbacks})
    ),
    getAllDashboardsByProjectId: (projectId: string, page?: Pagination) => dispatch(getAllDashboardsByProjectId(projectId, page))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ProjectDashboardsTabContainer));