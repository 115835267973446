import {ProjectTagType} from "../../models/ProjectTag";
import {CallbackInterface} from "../../models/Event";
import {Pagination} from "../../models/Pagination";
import {
    PROJECT_TAG_DELETE_BY_ID,
    PROJECT_TAG_GET_ALL_BY_PROJECT_ID,
    PROJECT_TAG_GET_ALL_BY_TASK_ID,
    PROJECT_TAG_RESET,
    PROJECT_TAG_SAVE
} from "./constants";

export interface SaveProjectTagActionInterface {
    type: string;
    projectTag: ProjectTagType;
    callbacks?: CallbackInterface<number>;
}

export interface DeleteProjectTagByIdActionInterface {
    type: string;
    id: number;
    callbacks?: CallbackInterface<void>;
}

export interface GetProjectTagByIdActionInterface {
    type: string;
    id: number;
}

export interface GetAllProjectTagsByProjectIdActionInterface {
    type: string;
    projectId: string;
    page?: Pagination;
}

export interface GetAllProjectTagsByTaskIdActionInterface {
    type: string;
    taskId: string;
    page?: Pagination;
}

export interface ResetProjectTagActionInterface {
    type: string;
    field: string;
}

export const saveProjectTag = (projectTag: ProjectTagType, callbacks?: CallbackInterface<number>): SaveProjectTagActionInterface => ({
    type: PROJECT_TAG_SAVE,
    projectTag,
    callbacks
});

export const deleteProjectTagById = (id: number, callbacks?: CallbackInterface<void>): DeleteProjectTagByIdActionInterface => ({
    type: PROJECT_TAG_DELETE_BY_ID,
    id,
    callbacks
});

export const getAllProjectTagsByProjectId = (projectId: string, page?: Pagination): GetAllProjectTagsByProjectIdActionInterface => ({
    type: PROJECT_TAG_GET_ALL_BY_PROJECT_ID,
    projectId,
    page
});

export const getAllProjectTagsByTaskId = (taskId: string, page?: Pagination): GetAllProjectTagsByTaskIdActionInterface => ({
    type: PROJECT_TAG_GET_ALL_BY_TASK_ID,
    taskId,
    page
});

export const resetProjectTag = (field: string): ResetProjectTagActionInterface => ({
    type: PROJECT_TAG_RESET,
    field
});