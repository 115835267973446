import {Immutable} from "./Immutable";
import {fromJS} from "immutable";

export enum AlertMessageType {
    success = "success",
    error = "danger",
    warning = "warning",
    info = "info"
}

export enum cAlert {
    type = "type",
    message = "message"
}

export interface AlertInterface {
    [cAlert.type]: AlertMessageType;
    [cAlert.message]: string;
}

export type AlertType = Immutable<AlertInterface>;

export const mapAlertFromJS = (src: AlertInterface): AlertType => {
    return fromJS({
       [cAlert.type]: src[cAlert.type],
       [cAlert.message]: src[cAlert.message]
    });
}

export const alertDefault = (): AlertType => fromJS({
    [cAlert.type]: AlertMessageType.info,
    [cAlert.message]: ""
})