import {all, delay, put, takeEvery} from "redux-saga/effects";
import {ALERT_ADD_FIRST} from "./constants";
import {removeLastAlert} from "./actions";

function* scheduleRemoveAlert() {
    yield delay(5000);
    yield put(removeLastAlert());
}

export default function* saga() {
    yield all([
        yield takeEvery(ALERT_ADD_FIRST, scheduleRemoveAlert)
    ])
}