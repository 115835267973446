import {AUTH_FETCH_MYSELF, AUTH_LOGIN_BY_CODE, AUTH_LOGOUT, cAuthReducer as cReducer} from "./constants";
import {Immutable} from "../../models/Immutable";
import {FULFILLED, REJECTED} from "../../global/constants";
import {removeToken, setToken, TokenInterface} from "./utils";
import {userDefault, UserType} from "../../models/User";

interface InitialStateInterface {
    [cReducer.loading]: boolean;
    [cReducer.currentUser]: UserType;
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
    [cReducer.loading]: false,
    [cReducer.currentUser]: userDefault()
});

const reducer = (state: InitialStateType = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case AUTH_LOGIN_BY_CODE: {
            return state.set(cReducer.loading, true);
        }
        case `${AUTH_LOGIN_BY_CODE}${FULFILLED}`: {
            // console.log("Response from code", action.payload);

            const token = action.payload as TokenInterface;
            setToken(token);

            return state.set(cReducer.loading, false);
        }
        case `${AUTH_LOGIN_BY_CODE}${REJECTED}`: {
            removeToken();
            return state.set(cReducer.loading, false);
        }
        case `${AUTH_LOGOUT}${FULFILLED}`: {
            removeToken();
            return initialState;
        }
        case `${AUTH_FETCH_MYSELF}${FULFILLED}`: {
            return state.set(cReducer.currentUser, action.payload);
        }
        default: {
            return state;
        }
    }
}

export default reducer;