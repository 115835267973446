import React from "react";
import {Button, Col, Row} from "reactstrap";
import TableView, {ColumnDefinition} from "../../components/TableView";
import {connect} from "react-redux";
import {cPage, PageType} from "../../models/Page";
import {cParameter, parameterDefault, ParameterType} from "../../models/Parameter";
import {cParameterReducer} from "../../api/ParameterApi/constants";
import {deleteParameterById, getAllParametersByTaskId, saveParameter} from "../../api/ParameterApi/actions";
import {getPaginationFromPage, Pagination} from "../../models/Pagination";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import PaginationControl from "../../components/PaginationControl";
import {cTaskParametersTabReducer} from "./constants";
import {toggleTaskParameterModal} from "./actions";
import TaskParameterFormModal from "../../components/TaskParameterFormModal";
import {SuccessEventCallback} from "../../models/Event";
import ConfirmationDialog from "../../components/ConfirmationDialog";

interface TakParametersTabContainerPropsInterface {
    taskId: string;

    parameters: PageType<ParameterType>;
    isParameterModalOpen: boolean;

    deleteParameterById: (id: number, successCallbacks: SuccessEventCallback<number>[]) => void;
    getAllParametersByTaskId: (taskId: string, page?: Pagination) => void;
    toggleParameterModal: () => void;
    saveParameter: (parameter: ParameterType, successCallbacks: SuccessEventCallback<number>[]) => void;
}

interface TaskParametersTabContainerStateInterface {
    parameter: ParameterType;
    isDeleteConfirmationDialogOpen: boolean;
    parameterToDelete?: number;
}

class TaskParametersTabContainer extends React.PureComponent<TakParametersTabContainerPropsInterface, TaskParametersTabContainerStateInterface>{

    state = {
        parameter: parameterDefault(),
        isDeleteConfirmationDialogOpen: false,
        parameterToDelete: undefined
    }

    componentDidMount() {
        this.props.getAllParametersByTaskId(this.props.taskId);
    }

    handleConfirmationDialogToggle = (id?: number) => {
        this.setState((prevState) => ({
            ...prevState,
            isDeleteConfirmationDialogOpen: !prevState.isDeleteConfirmationDialogOpen,
            parameterToDelete: id
        }))
    }

    handleParameterSave = (parameter: ParameterType) => {
        this.props.saveParameter(
            parameter,
            [
                () => toggleTaskParameterModal(),
                () => getAllParametersByTaskId(this.props.taskId, getPaginationFromPage(this.props.parameters))
            ]
        );
        this.setState((prevState) => ({
            ...prevState,
            parameter: parameterDefault()
        }));
    }

    handleDeleteConfirm = () => {
        if (!this.state.parameterToDelete) return;

        this.props.deleteParameterById(
            this.state.parameterToDelete || -1,
            [
                () => getAllParametersByTaskId(this.props.taskId, getPaginationFromPage(this.props.parameters))
            ]
        );
        this.handleConfirmationDialogToggle();
    }

    handleEditParameter = (parameter: ParameterType) => {
        this.setState((prevState) => ({
            ...prevState,
            parameter: parameter
        }));
        this.props.toggleParameterModal();
    }

    renderActionsColumn = (rowObject: ParameterType) => {
        return (
            <span className="d-flex justify-content-end mr-4">
                <FontAwesomeIcon
                    icon={faEdit}
                    className="mr-3 cursor-pointer"
                    onClick={() => this.handleEditParameter(rowObject)}
                />
                <FontAwesomeIcon
                    icon={faTrash}
                    className="mr-3 cursor-pointer"
                    onClick={() => this.handleConfirmationDialogToggle(rowObject.get(cParameter.id))}
                />
            </span>
        )
    }

    columnDefinition: ColumnDefinition<ParameterType>[] = [
        {
            attributeName: cParameter.name,
            renderHeader: () => "Name",
            renderCell: rowObject => rowObject.get(cParameter.name)
        },
        {
            attributeName: cParameter.value,
            renderHeader: () => "Value",
            renderCell: rowObject => rowObject.get(cParameter.value)
        },
        {
            attributeName: "operations",
            renderHeader: () => <span className="d-flex justify-content-end mr-4">Operations</span>,
            renderCell: this.renderActionsColumn
        }
    ]

    render() {
        return (
            <div className="m-5">
                <Row>
                    <Col xs={12}>
                        <TableView
                            rows={this.props.parameters.get(cPage.content)}
                            rowKeyAttribute={cParameter.id}
                            columnDefinitions={this.columnDefinition}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <PaginationControl page={this.props.parameters} onPageChange={(page: number) => this.props.getAllParametersByTaskId(this.props.taskId, {page: page})} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className="d-flex justify-content-end flex-grow-1">
                        <Button onClick={this.props.toggleParameterModal} color="success" className="pr-5">
                            <FontAwesomeIcon icon={faPlus} className="mr-5" />
                            Add
                        </Button>
                    </Col>
                </Row>
                {
                    this.props.isParameterModalOpen
                        ? <TaskParameterFormModal
                            taskId={this.props.taskId}
                            isOpen={this.props.isParameterModalOpen}
                            parameter={this.state.parameter}
                            onToggle={this.props.toggleParameterModal}
                            onSaveParameter={this.handleParameterSave}
                        />
                        : null
                }
                {
                    this.state.isDeleteConfirmationDialogOpen
                        ? <ConfirmationDialog
                            header="Parameter removal"
                            body="Remove selected parameter from task?"
                            isOpen={this.state.isDeleteConfirmationDialogOpen}
                            handleConfirm={this.handleDeleteConfirm}
                            handleCancel={this.handleConfirmationDialogToggle}
                        />
                        : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    parameters: state.parameterApiReducer.get(cParameterReducer.parameters),
    isParameterModalOpen: state.taskParametersTabContainerReducer.get(cTaskParametersTabReducer.isParameterModalOpen),
});

const mapDispatchToProps = (dispatch: any) => ({
    deleteParameterById: (id: number, successCallbacks: SuccessEventCallback<number>[]) => dispatch(
        deleteParameterById(id, {onSuccess: successCallbacks})
    ),
    getAllParametersByTaskId: (taskId: string, page?: Pagination) => dispatch(getAllParametersByTaskId(taskId, page)),
    toggleParameterModal: () => dispatch(toggleTaskParameterModal()),
    saveParameter: (parameter: ParameterType, successCallbacks: SuccessEventCallback<number>[]) => dispatch(
        saveParameter(parameter, {onSuccess: successCallbacks})
    )
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaskParametersTabContainer);