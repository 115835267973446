export enum cMetricReducer {
    plain2DMetrics = "plain2DMetrics",
    metricHistogram = "metricHistogram"
}

export const METRIC_GET_ALL_METRICS_GROUPED_BY_TIME = "Metric/GET_ALL_METRICS_GROUPED_BY_TIME";
export const METRIC_GET_ALL_METRICS_GROUPED_BY_EPOCH = "Metric/GET_ALL_METRICS_GROUPED_BY_EPOCH";
export const METRIC_GET_ALL_METRICS_GROUPED_BY_CONTEXT = "Metric/GET_ALL_METRICS_GROUPED_BY_CONTEXT";
export const METRIC_GET_METRIC_HISTOGRAM_BY_QUERY = "Metric/GET_METRIC_HISTOGRAM_BY_QUERY";
export const METRIC_RESET_VALUE = "Metric/RESET_VALUE";
export const METRIC_REMOVE_VALUE = "Metric/REMOVE_VALUE";