import React from "react";
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {cMetricQuery, MetricQueryInterface} from "../../models/MetricQuery";

interface VisualizationSaveModalPropsInterface {
    isOpen: boolean;
    isSubmitting: boolean;
    visualization: MetricQueryInterface;

    onToggle: () => void;
    onValueChange: (event: {target: {name: string; value?: any}}) => void;
    onSaveVisualization: (event: React.FormEvent<HTMLFormElement>) => void;
}

class VisualizationSaveModal extends React.PureComponent<VisualizationSaveModalPropsInterface> {

    state = {
        isNameValid: true
    }

    saveVisualization = (event: any) => {
        const isNameValid = (this.props.visualization[cMetricQuery.name] !== undefined && this.props.visualization[cMetricQuery.name].length > 0);
        this.setState({
            isNameValid: isNameValid
        });
        if (isNameValid) {
            this.props.onSaveVisualization(event);
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle}>
                <ModalHeader>Save visualization</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="nameField">Name</Label>
                        <Input
                            id="nameField"
                            name="name"
                            placeholder="Visualization name"
                            value={this.props.visualization[cMetricQuery.name]}
                            onChange={this.props.onValueChange}
                            invalid={!this.state.isNameValid}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="descriptionField">Description</Label>
                        <Input
                            id="descriptionField"
                            name="description"
                            type="textarea"
                            value={this.props.visualization[cMetricQuery.description]}
                            onChange={this.props.onValueChange}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.onToggle}>Cancel</Button>
                    <Button color="primary" onClick={this.saveVisualization} disabled={this.props.isSubmitting}>Save</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default VisualizationSaveModal;