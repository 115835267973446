import {NavItem, NavLink} from "reactstrap";
import React from "react";

interface TabLinkPropsInterface {
    id: number;
    title: string;
    activeTab: number;
    setActiveTab: (id: number) => void;
}

const TabLink = (props: TabLinkPropsInterface) => {
    const {id, title, activeTab, setActiveTab} = props;
    return <NavItem>
        <NavLink className={`cursor-pointer ${activeTab === id ? "active" : ""}`} onClick={() => setActiveTab(id)}>
            {title}
        </NavLink>
    </NavItem>;
}

export default TabLink;