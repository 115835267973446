import React from "react";
import {cPage, PageType} from "../../models/Page";
import {cUser, UserType} from "../../models/User";
import {getPaginationFromPage, Pagination} from "../../models/Pagination";
import {connect} from "react-redux";
import {cUserReducer} from "../../api/UserApi/constants";
import {deleteUserById, getAllUsers, updateUserRoleById} from "../../api/UserApi/actions";
import {Button, Col, CustomInput, Row} from "reactstrap";
import TableView, {ColumnDefinition} from "../../components/TableView";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import PaginationControl from "../../components/PaginationControl";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {SuccessEventCallback} from "../../models/Event";
import {Role} from "../../models/Role";
import {capitalize} from "../../global/utils";

interface UsersTabContainerPropsInterface {
    users: PageType<UserType>;

    updateUserRoleById: (id: string, role: Role, successEventCallbacks?: SuccessEventCallback<void>[]) => void;
    deleteUserById: (id: string, successEventCallbacks?: SuccessEventCallback<void>[]) => void;
    getAllUsers: (page?: Pagination) => void;
}

interface UsersTabContainerStateInterface {
    isDeleteConfirmationDialogOpen: boolean;
    userToDelete?: string;
}

class UsersTabContainer extends React.PureComponent<UsersTabContainerPropsInterface & RouteComponentProps, UsersTabContainerStateInterface> {

    state = {
        isDeleteConfirmationDialogOpen: false,
        userToDelete: undefined
    }

    componentDidMount() {
        this.props.getAllUsers();
    }

    handleEditUser = (id: string) => {
        this.props.history.push(`/user-form/${id}`);
    }

    handleDeleteConfirm = () => {
        if (!this.state.userToDelete) return;

        this.props.deleteUserById(
            this.state.userToDelete || "",
            [
                () => getAllUsers(getPaginationFromPage(this.props.users))
            ]
        );
        this.handleConfirmationDialogToggle();
    }

    handleConfirmationDialogToggle = (id?: string) => {
        this.setState((prevState) => ({
            ...prevState,
            isDeleteConfirmationDialogOpen: !prevState.isDeleteConfirmationDialogOpen,
            userToDelete: id
        }));
    }

    renderRoleColumn = (rowObject: UserType) => {
        return (
            <CustomInput
                type="select"
                id="roleField"
                value={rowObject.get(cUser.role)}
                onChange={e => this.props.updateUserRoleById(
                    rowObject.get(cUser.id) || "",
                    e.target.value === Role.ADMIN ? Role.ADMIN : Role.USER,
                    [() => getAllUsers(getPaginationFromPage(this.props.users))]
                )}
            >
                {Object.keys(Role).map(e => <option key={e} value={e}>{capitalize(e.toLowerCase())}</option>)}
            </CustomInput>
        )
    }

    renderOperationsColumn = (rowObject: UserType) => {
        return (
            <span className="d-flex justify-content-end mr-4">
                <FontAwesomeIcon
                    icon={faEdit}
                    className="cursor-pointer mr-3"
                    onClick={() => this.handleEditUser(rowObject.get(cUser.id) || "")}
                />
                <FontAwesomeIcon
                    icon={faTrash}
                    className="cursor-pointer mr-2"
                    onClick={() => this.handleConfirmationDialogToggle(rowObject.get(cUser.id))}
                />
            </span>
        )
    }

    columnDefinition: ColumnDefinition<UserType>[] = [
        {
            attributeName: cUser.firstName,
            renderHeader: () => "First name",
            renderCell: rowObject => rowObject.get(cUser.firstName)
        },
        {
            attributeName: cUser.lastName,
            renderHeader: () => "Last name",
            renderCell: rowObject => rowObject.get(cUser.lastName)
        },
        {
            attributeName: cUser.login,
            renderHeader: () => "Login",
            renderCell: rowObject => rowObject.get(cUser.login)
        },
        {
            attributeName: cUser.email,
            renderHeader: () => "Email",
            renderCell: rowObject => rowObject.get(cUser.email)
        },
        {
            attributeName: cUser.role,
            renderHeader: () => "Role",
            renderCell: this.renderRoleColumn
        },
        {
            attributeName: "actions",
            renderHeader: () => <span className="d-flex justify-content-end mr-4">Actions</span>,
            renderCell: this.renderOperationsColumn
        }
    ]

    render() {
        return (
            <div className="m-5">
                <Row>
                    <Col>
                        <TableView
                            rows={this.props.users.get(cPage.content)}
                            rowKeyAttribute={cUser.id}
                            columnDefinitions={this.columnDefinition}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PaginationControl
                            page={this.props.users}
                            onPageChange={page => this.props.getAllUsers({page})}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end flex-grow-1">
                        <Link to={"/user-form"}>
                            <Button color="success" className="pr-5">
                                <FontAwesomeIcon icon={faPlus} className="mr-5"/>
                                Add
                            </Button>
                        </Link>
                    </Col>
                </Row>
                {
                    this.state.isDeleteConfirmationDialogOpen
                        ? <ConfirmationDialog
                            header="User delete"
                            body="Delete selected user?"
                            isOpen={this.state.isDeleteConfirmationDialogOpen}
                            handleConfirm={this.handleDeleteConfirm}
                            handleCancel={this.handleConfirmationDialogToggle}
                        />
                        : null
                }
            </div>
        );
    }

}

const mapStateToProps = (state: any) => ({
    users: state.userApiReducer.get(cUserReducer.users)
});

const mapDispatchToProps = (dispatch: any) => ({
    updateUserRoleById: (id: string, role: Role, successEventCallbacks?: SuccessEventCallback<void>[]) => dispatch(
        updateUserRoleById(id, role, {onSuccess: successEventCallbacks})
    ),
    deleteUserById: (id: string, successEventCallbacks?: SuccessEventCallback<void>[]) => dispatch(
        deleteUserById(id, {onSuccess: successEventCallbacks})
    ),
    getAllUsers: (page?: Pagination) => dispatch(getAllUsers(page))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UsersTabContainer));