import {all, debounce, put} from "redux-saga/effects";
import {PROJECT_STUDENTS_TAB_SEARCH_USER} from "./constants";
import {SearchUserBySearchStringActionInterface} from "./actions";
import {getAllUsersBySearchString} from "../../api/UserApi/actions";

function* searchUserBySearchString(action: SearchUserBySearchStringActionInterface) {
    yield put(getAllUsersBySearchString(action.searchString, {page: 0, size: 10}));
}

export default function* saga() {
    yield all([
        yield debounce(1000, PROJECT_STUDENTS_TAB_SEARCH_USER, searchUserBySearchString)
    ])
}