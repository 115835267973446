import React from "react";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import {cPage, PageType} from "../../models/Page";
import {cUser, UserType} from "../../models/User";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Map, Set} from "immutable";
import {cProjectAssignment, mapProjectAssignmentFromJS, ProjectAssignmentType} from "../../models/ProjectAssignment";

interface ProjectAssignmentModalContainerProps {
    projectId: string;
    className?: string;

    isOpen: boolean;
    foundUsers: PageType<UserType>;

    onToggle: () => void;
    onSearchUsersBySearchString: (searchString: string) => void;
    onSaveAllProjectAssignments: (projectAssignments: Set<ProjectAssignmentType>) => void;
}

class ProjectAssignmentContainerModal extends React.PureComponent<ProjectAssignmentModalContainerProps> {

    state = {
        isSearchDropdownOpen: false,
        addedUsers: Map<string, UserType>()
    }

    handleDropdownToggle = () => {
        this.setState((prevState: any) => ({
            ...prevState,
            isSearchDropdownOpen: !prevState.isSearchDropdownOpen
        }))
    }

    handleSave = () => {
        const projectAssignments: Set<ProjectAssignmentType> = this.state.addedUsers.toSet()
            .map(e => mapProjectAssignmentFromJS({
                [cProjectAssignment.userId]: e.get(cUser.id) || "",
                [cProjectAssignment.projectId]: this.props.projectId
            }));

        this.props.onSaveAllProjectAssignments(projectAssignments);
    }

    handleAddUser = (user: UserType) => {
        const newAddedUsersIds = this.state.addedUsers
            .set(user.get(cUser.id) || "", user);

        this.setState({
            ...this.state,
            addedUsers: newAddedUsersIds
        });
    }

    handleRemoveUser = (userId: string) => {
        const newAddedUsersIds = this.state.addedUsers
            .remove(userId);

        this.setState({
            ...this.state,
            addedUsers: newAddedUsersIds
        });
    }

    handleSearchInputChange = (event: any) => {
        const textValue = event.target.value;
        const searchString = `firstName:${textValue},lastName:${textValue},login:${textValue},`;
        this.props.onSearchUsersBySearchString(searchString);
    }

    renderSearchResult = (): JSX.Element => {
        if (this.props.foundUsers.get(cPage.numberOfElements) > 0) {
            const searchResults = this.props.foundUsers.get(cPage.content)
                .map(e => (
                        <DropdownItem key={e.get(cUser.id)} onClick={() => this.handleAddUser(e)}>
                            {e.get(cUser.firstName) + " " + e.get(cUser.lastName)}
                        </DropdownItem>
                    )
                );
            return (
                <>
                    {searchResults}
                </>
            )
        }
        return <DropdownItem>No result</DropdownItem>
    }

    renderAddedStudents = () => {
        return this.state.addedUsers.map((user, id) => (
            <ListGroupItem key={id}>
                <div className="d-flex w-100 justify-content-between">
                    <span>{user.get(cUser.firstName) + " " + user.get(cUser.lastName)}</span>
                    <FontAwesomeIcon icon={faTimes} className="cursor-pointer" onClick={() => this.handleRemoveUser(id)} />
                </div>
            </ListGroupItem>
        )).valueSeq();
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle} className={this.props.className}>
                <ModalHeader>Add students</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Input type="text" placeholder="Search for users..." onClick={this.handleDropdownToggle} onChange={this.handleSearchInputChange} />
                            <Dropdown isOpen={this.state.isSearchDropdownOpen} toggle={this.handleDropdownToggle}>
                                <DropdownToggle tag="span" />
                                <DropdownMenu>
                                    {this.renderSearchResult()}
                                </DropdownMenu>
                            </Dropdown>
                        </FormGroup>
                    </Form>
                    <ListGroup className="overflow-auto" style={{maxHeight: "15rem"}}>
                        {this.renderAddedStudents()}
                    </ListGroup>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.onToggle}>Cancel</Button>
                    <Button color="primary" onClick={this.handleSave}>Save</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ProjectAssignmentContainerModal;