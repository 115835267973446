import React from "react";
import {Card} from "reactstrap";

interface ContentCardPropsInterface {
    className?: string;
    style?: any;
}

class ContentCard extends React.PureComponent<ContentCardPropsInterface> {

    render() {
        return (
            <Card className={`shadow-sm bg-white rounded border-0 content-card ${this.props.className}`} style={this.props.style}>
                {this.props.children}
            </Card>
        );
    }
}

export default ContentCard;