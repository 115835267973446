import {
    cMetricReducer,
    METRIC_GET_ALL_METRICS_GROUPED_BY_CONTEXT,
    METRIC_GET_ALL_METRICS_GROUPED_BY_EPOCH,
    METRIC_GET_ALL_METRICS_GROUPED_BY_TIME,
    METRIC_GET_METRIC_HISTOGRAM_BY_QUERY,
    METRIC_REMOVE_VALUE,
    METRIC_RESET_VALUE
} from "./constants";
import {Plain2DPointType} from "../../models/Plain2DPoint";
import {List, Map} from "immutable";
import {Immutable} from "../../models/Immutable";
import {FULFILLED} from "../../global/constants";

interface InitialStateInterface {
    [cMetricReducer.plain2DMetrics]: Map<number, List<Plain2DPointType>>;
    [cMetricReducer.metricHistogram]: Map<number, number[]>;
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
    [cMetricReducer.plain2DMetrics]: Map<number, List<Plain2DPointType>>(),
    [cMetricReducer.metricHistogram]: Map<number, number[]>(),
});

const reducer = (state: InitialStateType = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case `${METRIC_GET_ALL_METRICS_GROUPED_BY_TIME}${FULFILLED}`: {
            const visualizationId = action.payload.visualizationId || 0;
            return state.setIn([cMetricReducer.plain2DMetrics, visualizationId], action.payload.result);
        }
        case `${METRIC_GET_ALL_METRICS_GROUPED_BY_CONTEXT}${FULFILLED}`: {
            const visualizationId = action.payload.visualizationId || 0;
            return state.setIn([cMetricReducer.plain2DMetrics, visualizationId], action.payload.result);
        }
        case `${METRIC_GET_ALL_METRICS_GROUPED_BY_EPOCH}${FULFILLED}`: {
            const visualizationId = action.payload.visualizationId || 0;
            return state.setIn([cMetricReducer.plain2DMetrics, visualizationId], action.payload.result);
        }
        case `${METRIC_GET_METRIC_HISTOGRAM_BY_QUERY}${FULFILLED}`: {
            const visualizationId = action.payload.visualizationId || 0;
            return state.setIn([cMetricReducer.metricHistogram, visualizationId], action.payload.result);
        }
        case METRIC_RESET_VALUE: {
            return state.set(action.field, initialState.get(action.field));
        }
        case METRIC_REMOVE_VALUE: {
            return state.removeIn(action.path);
        }
        default:
            return state;
    }
}

export default reducer;