import {cTaskParametersTabReducer as cReducer, TASK_PARAMETERS_TAB_TOGGLE_PARAMETER_MODAL} from "./constants";
import {Immutable} from "../../models/Immutable";

interface InitialStateInterface {
    [cReducer.isParameterModalOpen]: boolean;
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
    [cReducer.isParameterModalOpen]: false
});

const reducer = (state: InitialStateType = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case TASK_PARAMETERS_TAB_TOGGLE_PARAMETER_MODAL: {
            return state.set(cReducer.isParameterModalOpen, !state.get(cReducer.isParameterModalOpen));
        }
        default:
            return state;
    }
}

export default reducer;