import {
    cProjectReducer as cReducer,
    PROJECT_GET_ALL,
    PROJECT_GET_ALL_OWN_ASSIGNED,
    PROJECT_GET_BY_ID,
    PROJECT_RESET_VALUE
} from "./constants";
import {projectDefault, ProjectType} from "../../models/Project";
import {pageDefault, PageType} from "../../models/Page";
import {Immutable} from "../../models/Immutable";
import {FULFILLED} from "../../global/constants";

interface InitialStateInterface {
    [cReducer.project]: ProjectType;
    [cReducer.projects]: PageType<ProjectType>;
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
    [cReducer.project]: projectDefault(),
    [cReducer.projects]: pageDefault<ProjectType>()
})

const reducer = (state: InitialStateType = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case `${PROJECT_GET_BY_ID}${FULFILLED}`: {
            return state.set(cReducer.project, action.payload);
        }
        case `${PROJECT_GET_ALL}${FULFILLED}`: {
            return state.set(cReducer.projects, action.payload);
        }
        case `${PROJECT_GET_ALL_OWN_ASSIGNED}${FULFILLED}`: {
            return state.set(cReducer.projects, action.payload);
        }
        case PROJECT_RESET_VALUE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export default reducer;