import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";

interface NavigationBackwardLinkProps {
    subtitle: string;
    onGoBack: () => void;
}

class NavigationBackwardLink extends React.PureComponent<NavigationBackwardLinkProps> {

    render() {
        return (
            <span>
                <span className="cursor-pointer" onClick={this.props.onGoBack}>
                    <FontAwesomeIcon icon={faAngleLeft} className="mr-2" />
                    Go back
                </span>
                <span className="ml-2 mr-2">
                    |
                </span>
                {this.props.subtitle}
            </span>
        );
    }
}

export default NavigationBackwardLink;