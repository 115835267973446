import {put} from "redux-saga/effects";

export enum cEvent {
    onSuccess = "onSuccess",
    onError = "onError",
}

export type SuccessEventCallback<T> = (result?: T) => {type: string};
export type ErrorEventCallback = (error: any) => {type: string};

export interface CallbackInterface<T> {
    [cEvent.onSuccess]?: SuccessEventCallback<T>[];
    [cEvent.onError]?: ErrorEventCallback[];
}

export const invokeSuccessCallbacks = <T>(callbacks?: CallbackInterface<T>, result?: T) =>
    callbacks?.onSuccess?.map(callback => put(callback(result))) || [];

export const invokeErrorCallbacks = <T>(callbacks?: CallbackInterface<T>, result?: T) =>
    callbacks?.onError?.map(callback => put(callback(result))) || [];

export const Callback = {
    invokeSuccessCallbacks,
    invokeErrorCallbacks
}