import React from "react";
import {Button, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {cPage, PageType} from "../../models/Page";
import {cMetricQuery, MetricQueryType} from "../../models/MetricQuery";
import {cVisualizationReducer} from "../../api/VisualizationApi/constants";
import {deleteVisualizationById, getAllVisualizationsByTaskId} from "../../api/VisualizationApi/actions";
import {getPaginationFromPage, Pagination} from "../../models/Pagination";
import {connect} from "react-redux";
import TableView, {ColumnDefinition} from "../../components/TableView";
import PaginationControl from "../../components/PaginationControl";
import {cProjectTag} from "../../models/ProjectTag";
import {capitalize} from "../../global/utils";
import {SuccessEventCallback} from "../../models/Event";
import ConfirmationDialog from "../../components/ConfirmationDialog";

interface TaskMetricsTabContainerPropsInterface {
    taskId: string;

    visualizations: PageType<MetricQueryType>;

    deleteVisualizationById: (id: number, successCallbacks?: SuccessEventCallback<void>[]) => void;
    getAllVisualizationsByTaskId: (taskId: string, page?: Pagination) => void;
}

interface TaskMetricsTabContainerStateInterface {
    isDeleteConfirmationDialogOpen: boolean;
    visualizationToDelete?: number;
}

class TaskMetricsTabContainer extends React.PureComponent<TaskMetricsTabContainerPropsInterface & RouteComponentProps, TaskMetricsTabContainerStateInterface> {

    state = {
        isDeleteConfirmationDialogOpen: false,
        visualizationToDelete: undefined
    }

    componentDidMount() {
        this.props.getAllVisualizationsByTaskId(this.props.taskId);
    }

    handleDeleteConfirm = () => {
        if (!this.state.visualizationToDelete) return;

        this.props.deleteVisualizationById(
            this.state.visualizationToDelete || -1,
            [
                () => getAllVisualizationsByTaskId(this.props.taskId, getPaginationFromPage(this.props.visualizations))
            ]
        );
        this.handleDeleteConfirmationDialogToggle();
    }

    handleDeleteConfirmationDialogToggle = (id?: number) => {
        this.setState((prevState) => ({
            ...prevState,
            isDeleteConfirmationDialogOpen: !prevState.isDeleteConfirmationDialogOpen,
            visualizationToDelete: id
        }));
    }

    renderOptionsColumn = (rowObject: MetricQueryType) => {
        return (
            <span className="d-flex justify-content-end mr-4">
                <FontAwesomeIcon
                    icon={faEdit}
                    className="mr-3 cursor-pointer"
                    onClick={() => this.props.history.push(`/metrics-plot/${rowObject.get(cMetricQuery.id)}`)}
                />
                <FontAwesomeIcon
                    icon={faTimes}
                    className="mr-2 cursor-pointer"
                    onClick={e => {
                        this.handleDeleteConfirmationDialogToggle(rowObject.get(cMetricQuery.id));
                        e.stopPropagation();
                    }}
                />
            </span>
        )
    }

    columnDefinitions: ColumnDefinition<MetricQueryType>[] = [
        {
            attributeName: cMetricQuery.name,
            renderHeader: () => "Name",
            renderCell: rowObject => rowObject.get(cMetricQuery.name)
        },
        {
            attributeName: cMetricQuery.tag,
            renderHeader: () => "Metric",
            renderCell: rowObject => rowObject.getIn([cMetricQuery.tag, cProjectTag.name])
        },
        {
            attributeName: cMetricQuery.chartType,
            renderHeader: () => "Type",
            renderCell: rowObject => capitalize(rowObject.get(cMetricQuery.chartType).toLowerCase())
        },
        {
            attributeName: "actions",
            renderHeader: () => <span className="d-flex justify-content-end mr-4">Actions</span>,
            renderCell: this.renderOptionsColumn
        }
    ]

    render() {
        return (
            <div className="m-5">
                <Row>
                    <TableView
                        rows={this.props.visualizations.get(cPage.content)}
                        rowKeyAttribute={cMetricQuery.id}
                        columnDefinitions={this.columnDefinitions}
                        onRowClick={(rowObject: MetricQueryType) => this.props.history.push(`/metrics-plot/${rowObject.get(cMetricQuery.id)}`)}
                    />
                </Row>
                <Row>
                    <Col>
                        <PaginationControl
                            page={this.props.visualizations}
                            onPageChange={(page) => this.props.getAllVisualizationsByTaskId(this.props.taskId, {page: page})}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end flex-grow-1">
                        <Link to={`/metrics-plot/task/${this.props.taskId}`}>
                            <Button color="success" className="pr-5">
                                <FontAwesomeIcon icon={faPlus} className="mr-5"/>
                                Add
                            </Button>
                        </Link>
                    </Col>
                </Row>
                {
                    this.state.isDeleteConfirmationDialogOpen
                    ? <ConfirmationDialog
                            header="Visualization delete"
                            body="Delete selected visualization?"
                            isOpen={this.state.isDeleteConfirmationDialogOpen}
                            handleConfirm={this.handleDeleteConfirm}
                            handleCancel={this.handleDeleteConfirmationDialogToggle}
                        />
                    : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    visualizations: state.visualizationApiReducer.get(cVisualizationReducer.visualizations)
});

const mapDispatchToProps = (dispatch: any) => ({
    deleteVisualizationById: (id: number, successCallbacks?: SuccessEventCallback<void>[]) => dispatch(deleteVisualizationById(
        id,
        {onSuccess: successCallbacks}
        )),
    getAllVisualizationsByTaskId: (taskId: string, page?: Pagination) => dispatch(getAllVisualizationsByTaskId(taskId, page))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TaskMetricsTabContainer));