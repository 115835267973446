import {FULFILLED, REJECTED} from "./constants";

export const plain = (type: string, payload: any = {}) => ({
    type: type,
    payload
});

export const fulfilled = (type: string, payload: any = {}) => ({
   type: type + FULFILLED,
   payload
});

export const rejected = (type: string, payload: any = {}) => ({
    type: type + REJECTED,
    payload
})

export const Actions = {
    plain, fulfilled, rejected
}