import {all, call, put, takeLatest} from "redux-saga/effects";
import {
    PROJECT_DELETE_BY_ID,
    PROJECT_GET_ALL,
    PROJECT_GET_ALL_OWN_ASSIGNED,
    PROJECT_GET_BY_ID,
    PROJECT_SAVE
} from "./constants";
import {
    DeleteProjectByIdActionInterface,
    GetAllOwnAssignedProjectsActionInterface,
    GetAllProjectsActionInterface,
    GetProjectByIdActionInterface,
    SaveProjectActionInterface
} from "./actions";
import {Network} from "../../internal/network";
import Links from "../../global/links";
import {mapPageFromJS, PageInterface} from "../../models/Page";
import {cProject, mapProjectFromJS, mapProjectToJS, ProjectInterface} from "../../models/Project";
import {Actions} from "../../global/actions";
import {Alert} from "../../containers/AlertContainer/actions";
import {Callback} from "../../models/Event";
import {getExceptionMessage} from "../../models/ExceptionCode";

function* save(action: SaveProjectActionInterface) {
    const callbacks = action.callbacks;
    try {
        let projectId: string = action.project.get(cProject.id);
        const payload = {body: JSON.stringify(mapProjectToJS(action.project))};
        if (projectId) {
            yield call(Network.put(Links.project(), payload));
        } else {
            projectId = yield call(Network.post(Links.project(), payload));
        }
        yield put(Actions.fulfilled(action.type, projectId));
        yield put(Alert.success("Project has been successfully saved."));
        yield all(Callback.invokeSuccessCallbacks(callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(callbacks));
    }
}

function* deleteById(action: DeleteProjectByIdActionInterface) {
    try {
        yield call(Network.del(Links.projectById(action.id)));
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("Project has been successfully deleted."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* getById(action: GetProjectByIdActionInterface) {
    try {
        let project: ProjectInterface = yield call(Network.get(Links.projectById(action.id)));
        yield put(Actions.fulfilled(action.type, mapProjectFromJS(project)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

function* getAll(action: GetAllProjectsActionInterface) {
    try {
        let projects: PageInterface<ProjectInterface> = yield call(Network.get(Links.allProjects(action.page?.page, action.page?.size)));
        yield put(Actions.fulfilled(action.type, mapPageFromJS(projects, mapProjectFromJS)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

function* getAllOwnAssigned(action: GetAllOwnAssignedProjectsActionInterface) {
    try {
        let projects: PageInterface<ProjectInterface> = yield call(Network.get(Links.allOwnAssignedProjects(action.page?.page, action.page?.size)));
        yield put(Actions.fulfilled(action.type, mapPageFromJS(projects, mapProjectFromJS)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

export default function* saga() {
    yield all([
        yield takeLatest(PROJECT_SAVE, save),
        yield takeLatest(PROJECT_DELETE_BY_ID, deleteById),
        yield takeLatest(PROJECT_GET_BY_ID, getById),
        yield takeLatest(PROJECT_GET_ALL, getAll),
        yield takeLatest(PROJECT_GET_ALL_OWN_ASSIGNED, getAllOwnAssigned)
    ])
}