import {getUrl, Module} from "../internal/endpoints";

const Links = {
    // Project
    project: () => getUrl("/projects", Module.managementService),
    projectById: (id: string) => getUrl(`/projects/${id}`, Module.managementService),
    allProjects: (page: number = 0, size: number = 20) => getUrl(`/projects?page=${page}&size=${size}`, Module.managementService),
    allOwnAssignedProjects: (page: number = 0, size: number = 20) => getUrl(`/projects?own-assigned&page=${page}&size=${size}`, Module.managementService),

    // Project assignment
    projectAssignmentById: (id: string) => getUrl(`/project-assignments/${id}`, Module.managementService),
    saveAllProjectAssignments: () => getUrl("/project-assignments?bulk", Module.managementService),
    projectAssignmentByProjectId: (projectId: string, page: number = 0, size: number = 20) => getUrl(`/project-assignments/project/${projectId}?page=${page}&size=${size}`, Module.managementService),

    // Task
    task: () => getUrl("/tasks", Module.managementService),
    taskState: (id: string) => getUrl(`/tasks/${id}/task-state`, Module.managementService),
    taskById: (id: string) => getUrl(`/tasks/${id}`, Module.managementService),
    tasksByProjectId: (projectId: string, page: number = 0, size: number = 20) => getUrl(`/tasks/project/${projectId}?page=${page}&size=${size}`, Module.managementService),
    tasksAvailableRunners: () => getUrl("/task-runners?available", Module.managementService),

    // User
    user: () => getUrl("/users", Module.managementService),
    userById: (id: string) => getUrl(`/users/${id}`, Module.managementService),
    userRoleById: (id: string) => getUrl(`/users/${id}/role`, Module.managementService),
    allUsers: (page: number = 0, size: number = 20) => getUrl(`/users?page=${page}&size=${size}`, Module.managementService),
    allUsersBySearchString: (searchString: string, page: number = 0, size: number = 20) => getUrl(`/users?search=${searchString}&page=${page}&size=${size}`, Module.managementService),

    // Parameter
    parameter: () => getUrl("/parameters", Module.managementService),
    parameterById: (id: number) => getUrl(`/parameters/${id}`, Module.managementService),
    parametersByTaskId: (taskId: string, page: number = 0, size: number = 20) => getUrl(`/parameters/task/${taskId}?page=${page}&size=${size}`, Module.managementService),

    // Task file
    taskFilesBulk: () => getUrl("/task-files?bulk", Module.managementService),
    taskFileById: (id: number) => getUrl(`/task-files/${id}`, Module.managementService),
    taskFilesByTaskId: (taskId: string, page: number = 0, size: number = 20) => getUrl(`/task-files/task/${taskId}?page=${page}&size=${size}`, Module.managementService),

    // File
    file: () => getUrl("/files", Module.managementService),
    fileById: (id: string) => getUrl(`/files/${id}`, Module.managementService),
    fileStreamById: (id: string) => getUrl(`/files/${id}?file-stream`, Module.managementService),
    filesByProjectId: (projectId: string, page: number = 0, size: number = 20) => getUrl(`/files/project/${projectId}?page=${page}&size=${size}`, Module.managementService),
    filesByProjectIdAndSearchString: (projectId: string, searchString: string, page: number = 0, size: number = 20) => getUrl(`/files/project/${projectId}?search=${searchString}&page=${page}&size=${size}`, Module.managementService),
    projectFilesByTaskIdAndSearchString: (taskId: string, searchString: string, page: number = 0, size: number = 20) => getUrl(`/files/project/tasks/${taskId}?search=${searchString}&page=${page}&size=${size}`, Module.managementService),

    // Project tag
    projectTag: () => getUrl("/project-tags", Module.managementService),
    projectTagById: (id: number) => getUrl(`/project-tags/${id}`, Module.managementService),
    projectTagsByProjectId: (projectId: string, page: number = 0, size: number = 20) => getUrl(`/project-tags/project/${projectId}?page=${page}&size=${size}`, Module.managementService),
    projectTagsByTaskId: (taskId: string, page: number = 0, size: number = 20) => getUrl(`/project-tags/project/tasks/${taskId}?page=${page}&size=${size}`, Module.managementService),

    // Metric
    metricsGroupedByTime: (criteria: string, bucketWidth: string = "1s") => getUrl(`/metrics?${criteria}&groupBy=time&bucketWidth=${bucketWidth}`, Module.managementService),
    metricsGroupedByEpoch: (criteria: string) => getUrl(`/metrics?${criteria}&groupBy=epoch`, Module.managementService),
    metricsGroupedByContext: (criteria: string) => getUrl(`/metrics?${criteria}&groupBy=context`, Module.managementService),
    metricHistogram: (criteria: string, from: number, to: number, bucketCount: number) => getUrl(`/metrics?${criteria}&transformInto=histogram&from=${from}&to=${to}&bucketCount=${bucketCount}`, Module.managementService),

    // Visualization
    visualization: () => getUrl("/metrics-visualizations", Module.managementService),
    visualizationById: (id: number) => getUrl(`/metrics-visualizations/${id}`, Module.managementService),
    visualizationsByTaskId: (taskId: string, page: number = 0, size: number = 20) => getUrl(`/metrics-visualizations/task/${taskId}?page=${page}&size=${size}`, Module.managementService),
    visualizationsByProjectIdAndSearchString: (projectId: string, searchString: string, page: number = 0, size: number = 20) => getUrl(`/metrics-visualizations/task/project/${projectId}?search=${searchString}&page=${page}&size=${size}`, Module.managementService),

    // Dashboard
    dashboard: () => getUrl("/dashboards", Module.managementService),
    dashboardById: (id: number) => getUrl(`/dashboards/${id}`, Module.managementService),
    dashboardByProjectId: (projectId: string, page: number = 0, size: number = 20) => getUrl(`/dashboards/project/${projectId}?page=${page}&size=${size}`, Module.managementService),
    dashboardMetricVisualization: (metricVisualizationId: number, dashboardId: number) => getUrl(`/dashboards/${dashboardId}/metric-visualizations/${metricVisualizationId}`, Module.managementService),

    // Dashboard items
    dashboardItem: () => getUrl("/dashboard-items", Module.managementService),
    dashboardItemById: (id: number) => getUrl(`/dashboard-items/${id}`, Module.managementService),
    dashboardItemsByDashboardId: (dashboardId: number) => getUrl(`/dashboard-items/dashboard/${dashboardId}`, Module.managementService),

    // Auth
    authorization: (redirectTo: string, clientId: string) => getUrl(`/oauth/authorize?response_type=code&scope=openid&redirect_uri=${redirectTo}&client_id=${clientId}`, Module.idp),
    logout: () => getUrl("/users/logout", Module.idp),
    tokenByCode: (code: string, redirectTo: string, clientId: string) => getUrl(`/oauth/token?grant_type=authorization_code&code=${code}&redirect_uri=${redirectTo}&client_id=${clientId}&client_secret=`, Module.idp),
    ownProfile: () => getUrl("/users?self", Module.managementService),

    // InputProcessingRequest
    inputProcessingRequest: () => getUrl("/input-processing-requests", Module.managementService)
}

export default Links;