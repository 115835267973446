import React from "react";

interface AttributeDetailBlockPropsInterface {
    title: string;
    value: any;
}

const AttributeDetailBlock = (props: AttributeDetailBlockPropsInterface) => {
    const {title, value} = props;
    return (
        <React.Fragment>
            <b>{title}:</b>
            <br/>
            {value}
        </React.Fragment>
    );
}

export default AttributeDetailBlock;