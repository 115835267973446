export enum cUserReducer {
    user = "user",
    users = "users"
}

export const USER_SAVE = "User/SAVE";
export const USER_UPDATE_ROLE_BY_ID = "User/UPDATE_ROLE";
export const USER_DELETE_BY_ID = "User/DELETE_BY_ID";
export const USER_GET_BY_ID = "User/GET_BY_ID";
export const USER_GET_ALL = "User/GET_ALL";
export const USER_GET_ALL_BY_SEARCH_STRING = "User/GET_ALL_BY_SEARCH_STRING";
export const USER_RESET_VALUE = "User/RESET_VALUE";