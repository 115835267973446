import {fromJS} from "immutable";

export interface Immutable<T> {
    get<K extends keyof T>(name: K): T[K];
    getIn<R>(name: any[]): R;
    set<K>(name: K, value: any): Immutable<T & K>;
    setIn<K extends keyof any[]>(name: K[], value: any): any;
    remove<K extends keyof T>(name: K): T[K];
    removeIn<R>(name: any[]): R;
    toJS(): T;
    withMutations<R>(s: R): Immutable<T & R>;
}

export function Immutable<T>(object: T) {
    return fromJS(object) as Immutable<T>;
}