import React from "react";
import {Table} from "reactstrap";
import {Immutable} from "../../models/Immutable";

export interface ColumnDefinition<T> {
    attributeName: string;
    renderHeader?: (attributeName: string) => any;
    renderCell?: (rowObject: T) => any;
}

interface TableViewProps {
    rows: Immutable<any>[];
    rowKeyAttribute: any;
    onRowClick?: (rowObject: any) => void;
    columnDefinitions: ColumnDefinition<Immutable<any>>[];
    className?: string;
    style?: object;
}

class TableView extends React.PureComponent<TableViewProps> {

    renderHeader = (): JSX.Element => {
        return (
            <>
                {this.props.columnDefinitions.map(cd =>
                    <th key={cd.attributeName} style={{borderTop: "none"}}>
                        {
                            cd.renderHeader
                                ? cd.renderHeader(cd.attributeName)
                                : cd.attributeName
                        }
                    </th>
                )}
            </>
        )
    }

    renderRows = (): JSX.Element => {
        if (this.props.rows) {
            const tableRows = this.props.rows.map((e: any) =>
                <tr key={e.get(this.props.rowKeyAttribute)}
                    className={this.props.onRowClick ? "cursor-pointer" : ""}
                    onClick={() => this.props.onRowClick ? this.props.onRowClick(e) : null}
                >
                    {this.props.columnDefinitions.map(cd =>
                        <td key={cd.attributeName}>
                            {
                                cd.renderCell
                                    ? cd.renderCell(e)
                                    : e.get(cd.attributeName)
                            }
                        </td>
                    )}
                </tr>
            )
            return (
                <>
                    {tableRows}
                </>
            )
        }
        return <></>
    }

    render() {
        return (
            <Table hover className={this.props.className} style={this.props.style}>
                <thead>
                    <tr>
                        {this.renderHeader()}
                    </tr>
                </thead>
                <tbody>
                    {this.renderRows()}
                </tbody>
            </Table>
        );
    }
}

export default TableView;