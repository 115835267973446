import {all, call, put, takeLatest} from "redux-saga/effects";
import {AUTH_FETCH_MYSELF, AUTH_LOGIN_BY_CODE, AUTH_LOGOUT} from "./constants";
import {Network} from "../../internal/network";
import Links from "../../global/links";
import {
    fetchMyself as fetchMyselfAction,
    FetchMyselfActionInterface,
    LoginByCodeActionInterface,
    LogoutActionInterface
} from "./actions";
import {getAuth} from "../../internal/endpoints";
import {Actions} from "../../global/actions";
import {mapUserFromJS, UserInterface} from "../../models/User";
import {Alert} from "../../containers/AlertContainer/actions";
import {push} from "connected-react-router";

function* loginByCode(action: LoginByCodeActionInterface) {
    try {
        const response = yield call(Network.postRaw(Links.tokenByCode(action.code, getAuth().redirectUri, getAuth().clientId)));
        yield put(Actions.fulfilled(action.type, response));
        yield put(fetchMyselfAction());
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
    }
}

function* logout(action: LogoutActionInterface) {
    try {
        yield call(Network.get(Links.logout()));
        yield put(Actions.fulfilled(action.type));
        yield put(push("/"));
    } catch (e) {
        yield put(Actions.rejected(action.type));
    }
}

function* fetchMyself(action: FetchMyselfActionInterface) {
    try {
        const user: UserInterface = yield call(Network.get(Links.ownProfile()));
        yield put(Actions.fulfilled(action.type, mapUserFromJS(user)));
        yield put(push("/"))
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

export default function* saga() {
    yield all([
        yield takeLatest(AUTH_LOGIN_BY_CODE, loginByCode),
        yield takeLatest(AUTH_LOGOUT, logout),
        yield takeLatest(AUTH_FETCH_MYSELF, fetchMyself)
    ])
}