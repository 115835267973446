import {
    cUserReducer as cReducer,
    USER_GET_ALL,
    USER_GET_ALL_BY_SEARCH_STRING,
    USER_GET_BY_ID,
    USER_RESET_VALUE
} from "./constants";
import {userDefault, UserType} from "../../models/User";
import {pageDefault, PageType} from "../../models/Page";
import {Immutable} from "../../models/Immutable";
import {FULFILLED} from "../../global/constants";

interface InitialStateInterface {
    [cReducer.user]: UserType;
    [cReducer.users]: PageType<UserType>;
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
   [cReducer.user]: userDefault(),
   [cReducer.users]: pageDefault<UserType>()
});

const reducer = (state: InitialStateType = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case `${USER_GET_BY_ID}${FULFILLED}`: {
            return state.set(cReducer.user, action.payload);
        }
        case `${USER_GET_ALL}${FULFILLED}`: {
            return state.set(cReducer.users, action.payload);
        }
        case `${USER_GET_ALL_BY_SEARCH_STRING}${FULFILLED}`: {
            return state.set(cReducer.users, action.payload);
        }
        case USER_RESET_VALUE: {
            return state.set(action.field, initialState.get(action.field));
        }
        default:
            return state;
    }
}

export default reducer;