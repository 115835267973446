import {all, debounce, put} from "redux-saga/effects";
import {TASK_FILES_TAB_SEARCH_PROJECT_FILES} from "./constants";
import {getAllProjectFilesByTaskIdAndSearchString} from "../../api/FileApi/actions";
import {SearchProjectFilesByTaskIdAndSearchStringActionInterface} from "./actions";

function* searchProjectFilesByTaskIdAndSearchString(action: SearchProjectFilesByTaskIdAndSearchStringActionInterface) {
    yield put(getAllProjectFilesByTaskIdAndSearchString(action.taskId, action.searchString, action.page))
}

export default function* saga() {
    yield all([
        yield debounce(1000, TASK_FILES_TAB_SEARCH_PROJECT_FILES, searchProjectFilesByTaskIdAndSearchString)
    ])
}