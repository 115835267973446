import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import {History} from "history";
import AuthApiReducer from "./api/AuthApi/reducer";
import DashboardApiReducer from "./api/DashboardApi/reducer";
import DashboardItemApiReducer from "./api/DashboardItemApi/reducer";
import FileApiReducer from "./api/FileApi/reducer";
import InputProcessingRequestApiReducer from "./api/InputProcessingRequestApi/reducer";
import MetricApiReducer from "./api/MetricApi/reducer";
import ParameterApiReducer from "./api/ParameterApi/reducer";
import ProjectApiReducer from "./api/ProjectApi/reducer";
import ProjectAssignmentApiReducer from "./api/ProjectAssignmentApi/reducer";
import ProjectTagApiReducer from "./api/ProjectTagApi/reducer";
import TaskApiReducer from "./api/TaskApi/reducer";
import TaskFileApiReducer from "./api/TaskFileApi/reducer";
import UserApiReducer from "./api/UserApi/reducer";
import VisualizationApiReducer from "./api/VisualizationApi/reducer";
import ProjectStudentsTabContainerReducer from "./containers/ProjectStudentsTabContainer/reducer";
import AlertContainerReducer from "./containers/AlertContainer/reducer";
import TaskParametersTabContainerReducer from "./containers/TaskParametersTabContainer/reducer";
import ModalContainerReducer from "./containers/ModalContainer/reducer";

const createRootReducer = (history: History<any>) => combineReducers({
    router: connectRouter(history),
    authApiReducer: AuthApiReducer,
    dashboardApiReducer: DashboardApiReducer,
    dashboardItemApiReducer: DashboardItemApiReducer,
    fileApiReducer: FileApiReducer,
    inputProcessingRequestApiReducer: InputProcessingRequestApiReducer,
    metricApiReducer: MetricApiReducer,
    parameterApiReducer: ParameterApiReducer,
    projectApiReducer: ProjectApiReducer,
    projectAssignmentApiReducer: ProjectAssignmentApiReducer,
    projectTagApiReducer: ProjectTagApiReducer,
    taskApiReducer: TaskApiReducer,
    taskFileApiReducer: TaskFileApiReducer,
    userApiReducer: UserApiReducer,
    visualizationApiReducer: VisualizationApiReducer,
    projectStudentsTabContainerReducer: ProjectStudentsTabContainerReducer,
    alertContainerReducer: AlertContainerReducer,
    taskParametersTabContainerReducer: TaskParametersTabContainerReducer,
    modalContainerReducer: ModalContainerReducer
});

export default createRootReducer;