import React from "react";
import {Button, Form, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Dropzone, {DropzoneRef} from "react-dropzone";
import {cSshCredentials, SshCredentialsInterface} from "../../models/SshCredentials";

interface TaskCredentialsModalPropsInterface {
    isOpen: boolean;

    onToggle: () => void;
    onConfirm: (credentials: SshCredentialsInterface) => void;
}

interface TaskCredentialsModalStateInterface {
    fileName?: string;
    key?: string;
    username?: string;
    passphrase?: string;
}

class TaskCredentialsModal extends React.PureComponent<TaskCredentialsModalPropsInterface, TaskCredentialsModalStateInterface> {

    dropzoneRef = React.createRef<DropzoneRef>();

    state = {
        fileName: undefined,
        key: undefined,
        username: undefined,
        passphrase: undefined
    }

    handleOpenFileChooser = () => {
        if (this.dropzoneRef) {
            this.dropzoneRef.current?.open();
        }
    }

    handleFileDrop = (acceptedFiles: File[]) => {
        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.onload = async () => {
                const fileResult = reader.result as string;
                this.setState({
                    key: btoa(fileResult),
                    fileName: file.name
                });
            }
            reader.readAsBinaryString(file);
        })
    }

    handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    handleConfirm = () => {
        this.props.onConfirm({
            [cSshCredentials.key]: this.state.key,
            [cSshCredentials.username]: this.state.username,
            [cSshCredentials.passphrase]: this.state.passphrase
        });
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle}>
                <ModalHeader>Authentication required</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label className="w-100">SSH identity file</Label>
                            <Button className="w-100" onClick={this.handleOpenFileChooser}>Select a key file</Button>
                            <Dropzone ref={this.dropzoneRef} noKeyboard onDrop={this.handleFileDrop} accept=".ppk">
                                {
                                    ({getRootProps, getInputProps, acceptedFiles}) => (
                                        <div {...getRootProps()}>
                                            <input id="keyUploadField" {...getInputProps()}/>
                                            <FormText>{this.state.fileName ? `Selected file: ${this.state.fileName}` : null}</FormText>
                                        </div>
                                    )
                                }
                            </Dropzone>
                        </FormGroup>
                        <FormGroup>
                            <Label for="usernameField">Username</Label>
                            <Input id="usernameField" name="username" type="text" onChange={this.handleFieldChange} />
                        </FormGroup>
                        <FormGroup>
                            <FormText>
                                Username which will be used for login.
                            </FormText>
                        </FormGroup>
                        <FormGroup>
                            <Label for="passphraseField">Passphrase</Label>
                            <Input id="passphraseField" name="passphrase" type="password" onChange={this.handleFieldChange} />
                        </FormGroup>
                        <FormGroup>
                            <FormText>
                                Passphrase which will be used for SSH identity file decryption.
                            </FormText>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button className="mr-2" onClick={this.props.onToggle}>Cancel</Button>
                    <Button color="primary" onClick={this.handleConfirm} disabled={!(this.state.key && this.state.passphrase)}>OK</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default TaskCredentialsModal;