export enum cProjectReducer {
    project = "project",
    projects = "projects",
}

export const PROJECT_SAVE = "Project/SAVE";
export const PROJECT_DELETE_BY_ID = "Project/DELETE_BY_ID";
export const PROJECT_GET_BY_ID = "Project/GET_BY_ID";
export const PROJECT_GET_ALL = "Project/GET_ALL";
export const PROJECT_GET_ALL_OWN_ASSIGNED = "Project/GET_ALL_OWN_ASSIGNED";
export const PROJECT_RESET_VALUE = "Project/RESET_VALUE";
