import React from "react";
import {connect} from "react-redux";
import {Button, Col, Row} from "reactstrap";
import TableView, {ColumnDefinition} from "../../components/TableView";
import {cPage, PageType} from "../../models/Page";
import {cProjectTag, projectTagDefault, ProjectTagType} from "../../models/ProjectTag";
import {getPaginationFromPage, Pagination} from "../../models/Pagination";
import {cProjectTagReducer} from "../../api/ProjectTagApi/constants";
import {deleteProjectTagById, getAllProjectTagsByProjectId, saveProjectTag} from "../../api/ProjectTagApi/actions";
import PaginationControl from "../../components/PaginationControl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {SuccessEventCallback} from "../../models/Event";
import {cModalReducer} from "../ModalContainer/constants";
import {toggleModal} from "../ModalContainer/actions";
import ProjectTagFormModal from "../../components/ProjectTagFormModal";
import ConfirmationDialog from "../../components/ConfirmationDialog";

interface ProjectTagsTabContainerPropsInterface {
    projectId: string;

    projectTag: ProjectTagType;
    projectTags: PageType<ProjectTagType>;
    isProjectTagFormModalOpen: boolean;

    saveProjectTag: (projectTag: ProjectTagType, successCallbacks?: SuccessEventCallback<number>[]) => void;
    deleteProjectTagById: (id: number, successCallbacks?: SuccessEventCallback<void>[]) => void;
    getAllProjectTagsByProjectId: (projectId: string, page?: Pagination) => void;
    toggleProjectTagFormModal: () => void;
}

interface ProjectTagsTabContainerStateInterface {
    isDeleteConfirmationDialogOpen: boolean;
    projectTagToDelete?: number;
    projectTag: ProjectTagType;
}

class ProjectTagsTabContainer extends React.PureComponent<ProjectTagsTabContainerPropsInterface, ProjectTagsTabContainerStateInterface> {

    state = {
        isDeleteConfirmationDialogOpen: false,
        projectTagToDelete: undefined,
        projectTag: projectTagDefault()
    }

    componentDidMount() {
        this.props.getAllProjectTagsByProjectId(this.props.projectId);
    }

    handleSaveProjectTag = (projectTag: ProjectTagType) => {
        this.props.saveProjectTag(
            projectTag,
            [
                () => toggleModal(),
                () => getAllProjectTagsByProjectId(this.props.projectId, getPaginationFromPage(this.props.projectTags))
            ]
        );
        this.setState((prevState) => ({
            ...prevState,
            projectTag: projectTagDefault()
        }));
    }

    handleEditProjectTag = (projectTag: ProjectTagType) => {
        this.setState((prevState) => ({
            ...prevState,
            projectTag: projectTag
        }));
        this.props.toggleProjectTagFormModal();
    }

    handleDeleteConfirm = () => {
        if (!this.state.projectTagToDelete) return;

        this.props.deleteProjectTagById(
            this.state.projectTagToDelete || -1,
            [
                () => getAllProjectTagsByProjectId(this.props.projectId, getPaginationFromPage(this.props.projectTags))
            ]
        );
        this.handleConfirmationDialogToggle();
    }

    handleConfirmationDialogToggle = (id?: number) => {
        this.setState((prevState) => ({
            ...prevState,
            isDeleteConfirmationDialogOpen: !prevState.isDeleteConfirmationDialogOpen,
            projectTagToDelete: id
        }));
    }

    renderOperationsColumn = (rowObject: ProjectTagType) => {
        return (
            <span className="d-flex justify-content-end mr-4">
                <FontAwesomeIcon
                    icon={faEdit}
                    className="cursor-pointer mr-3"
                    onClick={() => this.handleEditProjectTag(rowObject)}
                />
                <FontAwesomeIcon
                    icon={faTrash}
                    className="cursor-pointer mr-2"
                    onClick={() => this.handleConfirmationDialogToggle(rowObject.get(cProjectTag.id))}
                />
            </span>
        )
    }

    columnDefinition: ColumnDefinition<ProjectTagType>[] = [
        {
            attributeName: cProjectTag.name,
            renderHeader: () => "Name",
            renderCell: rowObject => rowObject.get(cProjectTag.name)
        },
        {
            attributeName: cProjectTag.value,
            renderHeader: () => "Value",
            renderCell: rowObject => rowObject.get(cProjectTag.value)
        },
        {
            attributeName: cProjectTag.description,
            renderHeader: () => "Description",
            renderCell: rowObject => rowObject.get(cProjectTag.description)
        },
        {
            attributeName: "actions",
            renderHeader: () => <span className="d-flex justify-content-end mr-4">Actions</span>,
            renderCell: this.renderOperationsColumn
        }
    ]

    render() {
        return (
            <div className="m-5">
                <Row>
                    <Col>
                        <TableView
                            rows={this.props.projectTags.get(cPage.content)}
                            rowKeyAttribute={cProjectTag.id}
                            columnDefinitions={this.columnDefinition}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PaginationControl
                            page={this.props.projectTags}
                            onPageChange={(page) => this.props.getAllProjectTagsByProjectId(this.props.projectId, {page})}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end flex-grow-1">
                        <Button color="success" className="pr-5" onClick={this.props.toggleProjectTagFormModal}>
                            <FontAwesomeIcon icon={faPlus} className="mr-5" />
                            Add
                        </Button>
                    </Col>
                </Row>
                {
                    this.props.isProjectTagFormModalOpen
                        ? <ProjectTagFormModal
                            projectId={this.props.projectId}
                            isOpen={this.props.isProjectTagFormModalOpen}
                            projectTag={this.state.projectTag}
                            onToggle={this.props.toggleProjectTagFormModal}
                            onProjectTagSave={this.handleSaveProjectTag}
                        />
                        : null
                }
                {
                    this.state.isDeleteConfirmationDialogOpen
                        ? <ConfirmationDialog
                            header="Project tag delete"
                            body="Delete selected project tag?"
                            isOpen={this.state.isDeleteConfirmationDialogOpen}
                            handleConfirm={this.handleDeleteConfirm}
                            handleCancel={this.handleConfirmationDialogToggle}
                        />
                        : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    projectTag: state.projectTagApiReducer.get(cProjectTagReducer.projectTag),
    projectTags: state.projectTagApiReducer.get(cProjectTagReducer.projectTags),
    isProjectTagFormModalOpen: state.modalContainerReducer.get(cModalReducer.isModalOpen)
});

const mapDispatchToProps = (dispatch: any) => ({
    saveProjectTag: (projectTag: ProjectTagType, successEventCallbacks?: SuccessEventCallback<number>[]) => dispatch(
        saveProjectTag(projectTag, {onSuccess: successEventCallbacks})
    ),
    deleteProjectTagById: (id: number, successEventCallbacks?: SuccessEventCallback<void>[]) => dispatch(
        deleteProjectTagById(id, {onSuccess: successEventCallbacks})
    ),
    getAllProjectTagsByProjectId: (projectId: string, page?: Pagination) => dispatch(getAllProjectTagsByProjectId(projectId, page)),
    toggleProjectTagFormModal: () => dispatch(toggleModal())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectTagsTabContainer);