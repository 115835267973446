import {taskDefault, TaskType} from "./Task";
import {Immutable} from "./Immutable";
import * as Yup from "yup";
import {genericFileDefault, GenericFileType} from "./GenericFile";
import {FileType} from "./FileType";
import {fromJS} from "immutable";

export enum cTaskFile {
    id = "id",
    taskId = "taskId",
    fileId = "fileId",
    task = "task",
    file = "file",
    fileType = "fileType"
}

export interface TaskFileInterface {
    [cTaskFile.id]?: number,
    [cTaskFile.taskId]: string,
    [cTaskFile.fileId]: string,
    [cTaskFile.task]?: TaskType,
    [cTaskFile.file]?: GenericFileType,
    [cTaskFile.fileType]: FileType
}

export type TaskFileType = Immutable<TaskFileInterface>;

export const taskFileValidationSchema = Yup.object().shape({
    [cTaskFile.fileType]: Yup.object().required()
});

export const mapTaskFileFromJS = (src: TaskFileInterface): TaskFileType => {
    return fromJS({
       [cTaskFile.id]: src[cTaskFile.id],
       [cTaskFile.taskId]: src[cTaskFile.taskId],
       [cTaskFile.fileId]: src[cTaskFile.fileId],
       [cTaskFile.task]: src[cTaskFile.task],
       [cTaskFile.file]: src[cTaskFile.file],
       [cTaskFile.fileType]: src[cTaskFile.fileType]
    });
};

export const mapTaskFileToJS = (src: TaskFileType): TaskFileInterface => ({
    [cTaskFile.id]: src.get(cTaskFile.id),
    [cTaskFile.taskId]: src.get(cTaskFile.taskId),
    [cTaskFile.fileId]: src.get(cTaskFile.fileId),
    [cTaskFile.task]: src.get(cTaskFile.task),
    [cTaskFile.file]: src.get(cTaskFile.file),
    [cTaskFile.fileType]: src.get(cTaskFile.fileType)
});

export const taskFileDefault = (): TaskFileType => fromJS({
    [cTaskFile.id]: null,
    [cTaskFile.taskId]: null,
    [cTaskFile.fileId]: null,
    [cTaskFile.task]: taskDefault(),
    [cTaskFile.file]: genericFileDefault(),
    [cTaskFile.fileType]: FileType.ATTACHMENT
});