import {Immutable} from "../../models/Immutable";
import {parameterDefault, ParameterType} from "../../models/Parameter";
import {pageDefault, PageType} from "../../models/Page";
import {cParameterReducer as cReducer, PARAMETER_GET_ALL_BY_TASK_ID} from "./constants";
import {FULFILLED} from "../../global/constants";

interface InitialStateInterface {
    [cReducer.parameter]: ParameterType;
    [cReducer.parameters]: PageType<ParameterType>;
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
    [cReducer.parameter]: parameterDefault(),
    [cReducer.parameters]: pageDefault<ParameterType>()
})

const reducer = (state: InitialStateType = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case `${PARAMETER_GET_ALL_BY_TASK_ID}${FULFILLED}`: {
            return state.set(cReducer.parameters, action.payload)
        }
        default:
            return state;
    }
}

export default reducer;