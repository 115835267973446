import React from "react";
import Content from "../Content";
import {Col, Row} from "reactstrap";
import ContentCard from "../ContentCard";

function About() {
    return (
        <Content title="About" subtitle="">
            <ContentCard className="ml-5 mr-5 mb-5" style={{minHeight: "calc(100vh - 26vh)"}}>
                <div className="m-5">
                    <Row>
                        <Col>
                            <p className="text-left">
                                ML Project is application intended for neural network task management and training
                                monitoring.
                                This application allows you to create and organize your neural network training in
                                tasks
                                which can be therefore executed on remote cluster or supercomputer.
                            </p>
                            <h5>Main features</h5>
                            <ul>
                                <li>Projects</li>
                                <ul>
                                    <li>Project management</li>
                                    <li>File management</li>
                                    <li>Student assignment</li>
                                    <li>Tag creation and modification</li>
                                    <li>Content sharing with members</li>
                                </ul>
                                <li>Tasks</li>
                                <ul>
                                    <li>Task management</li>
                                    <li>Parameter management</li>
                                    <li>Task file management</li>
                                </ul>
                                <li>Visualizations</li>
                                <ul>
                                    <li>Plot builder tool</li>
                                    <li>Available several types of charts</li>
                                    <li>Dashboards</li>
                                </ul>
                                <li>Client library</li>
                                <ul>
                                    <li>Custom metric logging</li>
                                    <li>Performance metric logging</li>
                                    <li>File download/upload during training</li>
                                    <li>Support for parameter change event handling</li>
                                    <li>Support for processing on-the-fly input data</li>
                                </ul>
                                <li>And more...</li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </ContentCard>
        </Content>
    )
}

export default About;