import {projectDefault, ProjectType} from "./Project";
import {Immutable} from "./Immutable";
import * as Yup from "yup";
import {fromJS} from "immutable";
import {round} from "../global/utils";

export enum cGenericFile {
    id = "id",
    name = "name",
    description = "description",
    size = "size",
    createdOn = "createdOn",
    projectId = "projectId",
    project = "project"
}

export interface GenericFileInterface {
    [cGenericFile.id]?: string;
    [cGenericFile.name]: string;
    [cGenericFile.description]?: string;
    [cGenericFile.size]?: number;
    [cGenericFile.createdOn]?: string;
    [cGenericFile.projectId]: string;
    [cGenericFile.project]?: ProjectType;
}

export type GenericFileType = Immutable<GenericFileInterface>;

export const genericFileValidationSchema = Yup.object().shape({
   [cGenericFile.name]: Yup.string().required().matches(/^\.*[a-zA-Z0-9][.a-zA-Z0-9_-]*$/),
   [cGenericFile.description]: Yup.string().optional()
});

export const getFileSizeWithUnit = (size: number): string => {
    if (size < 1024) return size + " B";
    else if (size < Math.pow(1024, 2)) return round(size / 1024, 2) + " KB";
    else if (size < Math.pow(1024, 3)) return round(size / Math.pow(1024, 2), 2) + " MB";
    else if (size < Math.pow(1024, 4)) return round(size / Math.pow(1024, 3), 2) + " GB";
    else if (size < Math.pow(1024, 5)) return round(size / Math.pow(1024, 4), 2) + " TB";

    return size + " B";
}

export const mapGenericFileFromJS = (src: GenericFileInterface): GenericFileType => {
    return fromJS({
        [cGenericFile.id]: src[cGenericFile.id],
        [cGenericFile.name]: src[cGenericFile.name],
        [cGenericFile.description]: src[cGenericFile.description] || '',
        [cGenericFile.size]: src[cGenericFile.size],
        [cGenericFile.createdOn]: src[cGenericFile.createdOn],
        [cGenericFile.projectId]: src[cGenericFile.projectId],
        [cGenericFile.project]: src[cGenericFile.project],
    });
}

export const mapGenericFileToJS = (src: GenericFileType): GenericFileInterface => ({
    [cGenericFile.id]: src.get(cGenericFile.id),
    [cGenericFile.name]: src.get(cGenericFile.name),
    [cGenericFile.description]: src.get(cGenericFile.description)?.length !== 0 ? src.get(cGenericFile.description) : undefined,
    [cGenericFile.size]: src.get(cGenericFile.size),
    [cGenericFile.createdOn]: src.get(cGenericFile.createdOn),
    [cGenericFile.projectId]: src.get(cGenericFile.projectId),
    [cGenericFile.project]: src.get(cGenericFile.project)
});

export const genericFileDefault = (): GenericFileType => fromJS({
    [cGenericFile.id]: null,
    [cGenericFile.name]: "",
    [cGenericFile.description]: "",
    [cGenericFile.size]: 0,
    [cGenericFile.createdOn]: null,
    [cGenericFile.projectId]: null,
    [cGenericFile.project]: projectDefault()
});