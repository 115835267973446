import {
    cProjectAssignmentReducer as cReducer,
    PROJECT_ASSIGNMENT_GET_BY_PROJECT_ID,
    PROJECT_ASSIGNMENT_RESET_VALUE
} from "./constatns";
import {projectAssignmentDefault, ProjectAssignmentType} from "../../models/ProjectAssignment";
import {pageDefault, PageType} from "../../models/Page";
import {Immutable} from "../../models/Immutable";
import {FULFILLED} from "../../global/constants";

interface InitialStateInterface {
    [cReducer.projectAssignment]: ProjectAssignmentType;
    [cReducer.projectAssignments]: PageType<ProjectAssignmentType>;
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
    [cReducer.projectAssignment]: projectAssignmentDefault(),
    [cReducer.projectAssignments]: pageDefault<ProjectAssignmentType>()
})

const reducer = (state: InitialStateType = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case `${PROJECT_ASSIGNMENT_GET_BY_PROJECT_ID}${FULFILLED}`: {
            return state.set(cReducer.projectAssignments, action.payload);
        }
        case PROJECT_ASSIGNMENT_RESET_VALUE: {
            return state.set(action.field, initialState.get(action.field));
        }
        default: {
            return state;
        }
    }
}

export default reducer;