import {Pagination} from "../../models/Pagination";
import {
    FILE_DELETE_BY_ID,
    FILE_DOWNLOAD_BY_ID,
    FILE_GET_ALL_BY_PROJECT_ID,
    FILE_GET_ALL_BY_PROJECT_ID_AND_SEARCH_STRING,
    FILE_GET_ALL_PROJECT_FILES_BY_TASK_ID_AND_SEARCH_STRING,
    FILE_GET_BY_ID,
    FILE_RESET_VALUE,
    FILE_SAVE,
    FILE_SEARCH_PROJECT_FILES
} from "./constants";
import {CallbackInterface} from "../../models/Event";
import {GenericFileType} from "../../models/GenericFile";

export interface SaveFileActionInterface {
    type: string;
    file: GenericFileType;
    fileContent?: File;
    callbacks?: CallbackInterface<string>;
}

export interface GetFileByIdActionInterface {
    type: string;
    fileId: string;
}

export interface DeleteFileByIdActionInterface {
    type: string;
    fileId: string;
    callbacks?: CallbackInterface<void>;
}

export interface GetAllFilesByProjectIdActionInterface {
    type: string;
    projectId: string;
    page?: Pagination
}

export interface GetAllFilesByProjectIdAndSearchStringActionInterface {
    type: string;
    projectId: string;
    searchString: string;
    page?: Pagination;
}

export interface GetAllProjectFilesByTaskIdAndSearchStringActionInterface {
    type: string;
    taskId: string;
    searchString: string;
    page?: Pagination
}

export interface SearchProjectFilesByTaskIdAndSearchStringActionInterface {
    type: string;
    taskId: string;
    searchString: string;
    page?: Pagination
}

export interface DownloadFileByIdActionInterface {
    type: string;
    id: string;
    filename: string;
}

export interface ResetFileValueActionInterface {
    type: string;
    field: string;
}

export const saveFile = (file: GenericFileType, fileContent?: File, callbacks?: CallbackInterface<string>): SaveFileActionInterface => ({
    type: FILE_SAVE,
    file,
    fileContent,
    callbacks
});

export const getFileById = (fileId: string): GetFileByIdActionInterface => ({
   type: FILE_GET_BY_ID,
   fileId
});

export const deleteFileById = (fileId: string, callbacks?: CallbackInterface<void>): DeleteFileByIdActionInterface => ({
    type: FILE_DELETE_BY_ID,
    fileId,
    callbacks
});

export const getAllFilesByProjectId = (projectId: string, page?: Pagination): GetAllFilesByProjectIdActionInterface => ({
    type: FILE_GET_ALL_BY_PROJECT_ID,
    projectId,
    page
});

export const getAllFilesByProjectIdAndSearchString = (projectId: string, searchString: string, page?: Pagination): GetAllFilesByProjectIdAndSearchStringActionInterface => ({
    type: FILE_GET_ALL_BY_PROJECT_ID_AND_SEARCH_STRING,
    projectId,
    searchString,
    page
});

export const getAllProjectFilesByTaskIdAndSearchString = (taskId: string, searchString: string, page?: Pagination): GetAllProjectFilesByTaskIdAndSearchStringActionInterface => ({
    type: FILE_GET_ALL_PROJECT_FILES_BY_TASK_ID_AND_SEARCH_STRING,
    taskId,
    searchString,
    page
});

export const searchProjectFilesByTaskIdAndSearchString = (taskId: string, searchString: string, page?: Pagination): SearchProjectFilesByTaskIdAndSearchStringActionInterface => ({
    type: FILE_SEARCH_PROJECT_FILES,
    taskId,
    searchString,
    page
});

export const downloadFileById = (id: string, filename: string): DownloadFileByIdActionInterface => ({
   type: FILE_DOWNLOAD_BY_ID,
   id,
   filename
});

export const resetFileValue = (field: string): ResetFileValueActionInterface => ({
    type: FILE_RESET_VALUE,
    field
});