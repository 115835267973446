import {all, call, put, takeLatest} from "redux-saga/effects";
import {SaveInputProcessingRequestActionInterface} from "./actions";
import {INPUT_PROCESSING_REQUEST_SAVE} from "./constants";
import {Network} from "../../internal/network";
import {mapInputProcessingRequestToJS} from "../../models/InputProcessingRequest";
import Links from "../../global/links";
import {Actions} from "../../global/actions";
import {Alert} from "../../containers/AlertContainer/actions";
import {Callback} from "../../models/Event";
import {getExceptionMessage} from "../../models/ExceptionCode";

function* saveInputProcessingRequest(action: SaveInputProcessingRequestActionInterface) {
    try {
        const payload = {body: JSON.stringify(mapInputProcessingRequestToJS(action.request))};
        const requestId = yield call(Network.post(Links.inputProcessingRequest(), payload));
        yield put(Actions.fulfilled(action.type, requestId));
        yield put(Alert.success("Processing in progress. Download will start automatically, please wait..."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks, requestId));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

export default function* saga() {
    yield all([
        yield takeLatest(INPUT_PROCESSING_REQUEST_SAVE, saveInputProcessingRequest)
    ])
}