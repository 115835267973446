import {cMetricQuery, MetricQueryInterface} from "./MetricQuery";

export interface EpochMetricQueryInterface {
    [cMetricQuery.id]?: number;
    [cMetricQuery.taskId]: string;
    [cMetricQuery.tagId]: number;
    [cMetricQuery.epochFrom]?: number;
    [cMetricQuery.epochTo]?: number;
}

export const mapEpochQueryFromMetricQuery = (src: MetricQueryInterface): EpochMetricQueryInterface => ({
    [cMetricQuery.id]: src[cMetricQuery.id],
    [cMetricQuery.taskId]: src[cMetricQuery.taskId],
    [cMetricQuery.tagId]: src[cMetricQuery.tagId],
    [cMetricQuery.epochFrom]: src[cMetricQuery.epochFrom],
    [cMetricQuery.epochTo]: src[cMetricQuery.epochTo]
});