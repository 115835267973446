import React from "react";
import {connect} from "react-redux";
import {cUserReducer} from "../../api/UserApi/constants";
import {getUserById, resetUserValue, saveUser} from "../../api/UserApi/actions";
import {RouteComponentProps} from "react-router-dom";
import Content from "../../components/Content";
import NavigationBackwardLink from "../../components/NavigationBackwardLink";
import ContentCard from "../../components/ContentCard";
import {Formik, FormikProps} from "formik";
import {cUser, mapUserFromJS, mapUserToJS, UserInterface, UserType, userValidationSchema} from "../../models/User";
import {Button, Col, Form, FormFeedback, FormGroup, FormText, Input, Label, Row} from "reactstrap";
import {SuccessEventCallback} from "../../models/Event";
import {goBack, push} from "connected-react-router";

interface UserFormContainerPropsInterface {
    userId: string;
    user: UserType;

    saveUser: (user: UserType, successCallbacks?: SuccessEventCallback<string>[]) => void;
    getUserById: (id: string) => void;
    resetUserValue: () => void;
}

class UserFormContainer extends React.PureComponent<UserFormContainerPropsInterface & RouteComponentProps> {

    formikRef: React.RefObject<FormikProps<UserInterface>>;

    constructor(props: any) {
        super(props);

        this.formikRef = React.createRef();
    }

    componentDidMount() {
        const userId: string | null = new URLSearchParams(this.props.match.params).get("id");
        if (userId) {
            this.props.getUserById(userId);
        } else {
            this.props.resetUserValue();
        }
    }

    handleGoBack = () => {
        if (this.props.history.action === "PUSH") {
            this.props.history.goBack();
        } else {
            this.props.history.push("/configuration")
        }
    }

    handleSave = (values: UserInterface) => {
        let user = mapUserFromJS(values)
            .set(cUser.role, null);
        if (values[cUser.id]) {
            user = user.set(cUser.login, null);
        }

        let goBackCallback: SuccessEventCallback<string>;
        if (this.props.history.action === "PUSH") {
            goBackCallback = () => goBack();
        } else {
            goBackCallback = () => push("/configuration");
        }
        this.props.saveUser(user, [goBackCallback]);
    }

    componentDidUpdate(prevProps: Readonly<UserFormContainerPropsInterface & RouteComponentProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (this.formikRef.current) {
            this.formikRef.current.setValues(mapUserToJS(this.props.user));
        }
    }

    render() {
        const editMode = this.props.user.get(cUser.id);
        return (
            <Content title={editMode ? "User profile" : "Create user"} subtitle={<NavigationBackwardLink subtitle={!editMode ? "User list" : "Page"} onGoBack={this.handleGoBack} />}>
                <ContentCard className="ml-5 mr-5">
                    <div className="m-5">
                        <Formik
                            initialValues={mapUserToJS(this.props.user)}
                            validationSchema={userValidationSchema}
                            validateOnChange={false}
                            validateOnBlur={false}
                            enableReinitialize={false}
                            innerRef={this.formikRef}
                            onSubmit={(values, {setSubmitting}) => {
                                setSubmitting(true);
                                this.handleSave(values);
                            }}
                        >
                            {
                                ({values, errors, handleChange, handleSubmit, handleBlur, isSubmitting, setFieldValue}) => (
                                    <React.Fragment>
                                        <Form onSubmit={handleSubmit}>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for="firstNameField">First name</Label>
                                                        <Input
                                                            id="firstNameField"
                                                            name={cUser.firstName}
                                                            placeholder="John"
                                                            value={values[cUser.firstName]}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            invalid={!!errors[cUser.firstName]}
                                                        />
                                                        <FormFeedback>{errors[cUser.firstName]}</FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for="lastNameField">Last name</Label>
                                                        <Input
                                                            id="lastNameField"
                                                            name={cUser.lastName}
                                                            placeholder="Doe"
                                                            value={values[cUser.lastName]}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            invalid={!!errors[cUser.lastName]}
                                                        />
                                                        <FormFeedback>{errors[cUser.lastName]}</FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for="emailField">Email</Label>
                                                        <Input
                                                            id="emailField"
                                                            name={cUser.email}
                                                            type="email"
                                                            placeholder="john.doe@email.com"
                                                            value={values[cUser.email]}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            invalid={!!errors[cUser.email]}
                                                        />
                                                        <FormFeedback>{errors[cUser.email]}</FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for="loginField">Login</Label>
                                                        <Input
                                                            id="loginField"
                                                            name={cUser.login}
                                                            placeholder="doe1234"
                                                            value={values[cUser.login]}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            invalid={!!errors[cUser.login]}
                                                            disabled={!!values[cUser.id]}
                                                        />
                                                        <FormFeedback>{errors[cUser.login]}</FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for="passwordField">Password</Label>
                                                        <Input
                                                            id="passwordField"
                                                            name={cUser.password}
                                                            type="password"
                                                            value={values[cUser.password]}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            invalid={!!errors[cUser.password]}
                                                        />
                                                        <FormFeedback>{errors[cUser.password]}</FormFeedback>
                                                    </FormGroup>
                                                    {
                                                        editMode
                                                        ? <FormGroup>
                                                                <FormText>
                                                                    If you don`t want to change password, leave empty.
                                                                </FormText>
                                                            </FormGroup>
                                                        : null
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex justify-content-end">
                                                    <Button className="mr-2" onClick={this.handleGoBack}>Cancel</Button>
                                                    <Button color="primary" type="submit" disabled={isSubmitting}>Save</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </React.Fragment>
                                )
                            }
                        </Formik>
                    </div>
                </ContentCard>
            </Content>
        );
    }
}

const mapStateToProps = (state: any) => ({
    user: state.userApiReducer.get(cUserReducer.user)
});

const mapDispatchToProps = (dispatch: any) => ({
    saveUser: (user: UserType, successCallbacks?: SuccessEventCallback<string>[]) => dispatch(saveUser(
        user,
        {onSuccess: successCallbacks}
    )),
    getUserById: (id: string) => dispatch(getUserById(id)),
    resetUserValue: () => dispatch(resetUserValue(cUserReducer.user))
});

export default connect
(
   mapStateToProps,
   mapDispatchToProps
)(UserFormContainer);