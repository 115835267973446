import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

interface ConfirmationDialogPropsInterface {
    className?: string;
    header: string;
    body: any;

    isOpen: boolean;
    handleConfirm: () => void;
    handleCancel: () => void;
}

class ConfirmationDialog extends React.PureComponent<ConfirmationDialogPropsInterface>{

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.handleCancel}
                className={this.props.className}
            >
                <ModalHeader>{this.props.header}</ModalHeader>
                <ModalBody>{this.props.body}</ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.handleCancel}>Cancel</Button>
                    <Button color="primary" onClick={this.props.handleConfirm}>OK</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ConfirmationDialog;