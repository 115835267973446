export enum cFileReducer {
    file = "file",
    files = "files"
}

export const FILE_SAVE = "File/SAVE";
export const FILE_GET_BY_ID = "File/GET_BY_ID";
export const FILE_DELETE_BY_ID = "File/DELETE_BY_ID";
export const FILE_GET_ALL_BY_PROJECT_ID = "File/GET_ALL_BY_PROJECT_ID";
export const FILE_GET_ALL_BY_PROJECT_ID_AND_SEARCH_STRING = "File/GET_ALL_BY_PROJECT_ID_AND_SEARCH_STRING";
export const FILE_GET_ALL_PROJECT_FILES_BY_TASK_ID_AND_SEARCH_STRING = "File/GET_ALL_PROJECT_FILES_BY_TASK_ID_AND_SEARCH_STRING";
export const FILE_SEARCH_PROJECT_FILES = "File/SEARCH_PROJECT_FILES";
export const FILE_DOWNLOAD_BY_ID = "File/DOWNLOAD_BY_ID";
export const FILE_RESET_VALUE = "File/RESET_VALUE";