import {all, call, put, takeLatest} from "redux-saga/effects";
import {TASK_FILE_DELETE_BY_ID, TASK_FILE_GET_ALL_BY_TASK_ID, TASK_FILE_SAVE_ALL} from "./constants";
import {mapTaskFileFromJS, mapTaskFileToJS, TaskFileInterface} from "../../models/TaskFile";
import {
    DeleteTaskFileByIdActionInterface,
    GetAllTaskFilesByTaskIdActionInterface,
    SaveAllTaskFilesActionInterface
} from "./actions";
import {Network} from "../../internal/network";
import Links from "../../global/links";
import {Actions} from "../../global/actions";
import {Alert} from "../../containers/AlertContainer/actions";
import {Callback} from "../../models/Event";
import {mapPageFromJS, PageInterface} from "../../models/Page";
import {getExceptionMessage} from "../../models/ExceptionCode";

function* saveAll(action: SaveAllTaskFilesActionInterface) {
    try {
        const payload = {body: JSON.stringify(action.taskFiles.map(mapTaskFileToJS))}
        yield call(Network.post(Links.taskFilesBulk(), payload));
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("All changes have been saved."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* deleteById(action: DeleteTaskFileByIdActionInterface) {
    try {
        yield call(Network.del(Links.taskFileById(action.id)));
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("Task file has been successfully deleted."))
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* getAllByTaskId(action: GetAllTaskFilesByTaskIdActionInterface) {
    try {
        const taskFiles: PageInterface<TaskFileInterface> = yield call(Network.get(Links.taskFilesByTaskId(action.taskId, action.page?.page, action.page?.size)));
        yield put(Actions.fulfilled(action.type, mapPageFromJS(taskFiles, mapTaskFileFromJS)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

export default function* saga() {
    yield all([
        yield takeLatest(TASK_FILE_SAVE_ALL, saveAll),
        yield takeLatest(TASK_FILE_DELETE_BY_ID, deleteById),
        yield takeLatest(TASK_FILE_GET_ALL_BY_TASK_ID, getAllByTaskId)
    ])
}