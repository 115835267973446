export enum cTaskReducer {
    task = "task",
    tasks = "tasks",
    taskRunners = "taskRunners"
}

export const TASK_SAVE = "Task/SAVE";
export const TASK_UPDATE_STATE = "Task/UPDATE_STATE";
export const TASK_DELETE_BY_ID = "Task/DELETE_BY_ID";
export const TASK_GET_BY_ID = "Task/GET_BY_ID";
export const TASK_GET_ALL_BY_PROJECT_ID = "Task/GET_ALL_BY_PROJECT_ID";
export const TASK_GET_ALL_AVAILABLE_TASK_RUNNERS = "Task/GET_ALL_AVAILABLE_TASK_RUNNERS";
export const TASK_RESET_VALUE = "Task/RESET_VALUE";
