
export enum Module {
    managementService = "managementService",
    idp =  "idp"
}

export interface AuthConfig {
    clientId: string;
    redirectUri: string;
}

export const hosts = {
    development: {
        [Module.managementService]: "http://127.0.0.1:6080",
        [Module.idp]: "http://127.0.0.1:9999"
    },
    production: {
        [Module.managementService]: "https://vsrvfeia0h-50.vsb.cz/management-service/api/v1",
        [Module.idp]: "https://vsrvfeia0h-50.vsb.cz/auth-service/api/v1"
    }
}

export const auth = {
    development: {
        clientId: "management-service-web",
        redirectUri: "http://localhost:3000/login/idp"
    },
    production: {
        clientId: "management-service-web",
        redirectUri: "https://vsrvfeia0h-50.vsb.cz/login/idp"
    }
}

export const getHost = (module: Module): string => {
    const env = process.env.REACT_APP_ENV;

    if (!env) {
        throw Error("Environment variable is undefined!");
    } else {
        // @ts-ignore
        return hosts[env][module];
    }
}

export const getUrl = (path: string, module: Module) => {
    const hostUrl = getHost(module);

    if (!hostUrl) {
        throw Error(`Host URL for module ${module} not found!`);
    }

    return `${hostUrl}${path}`;
}

export const getAuth = (): AuthConfig => {
    const env = process.env.REACT_APP_ENV;

    if (!env) {
        throw Error("Environment variable is undefined!");
    } else {
        // @ts-ignore
        return auth[env]
    }
}