import React from "react";
import {cDashboard, DashboardType, dashboardValidationSchema, mapDashboardFromJS} from "../../models/Dashboard";
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {Formik} from "formik";

interface DashboardFormModalPropsInterface {
    projectId: string;
    className?: string;

    isOpen: boolean;
    dashboard: DashboardType;

    onToggle: () => void;
    onDashboardSave: (dashboard: DashboardType) => void;
}

class DashboardFormModal extends React.PureComponent<DashboardFormModalPropsInterface> {

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle} className={this.props.className}>
                <Formik
                    initialValues={this.props.dashboard.toJS()}
                    validationSchema={dashboardValidationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize={false}
                    onSubmit={(values, {setSubmitting}) => {
                        let dashboard = mapDashboardFromJS(values);
                        if (this.props.dashboard?.get(cDashboard.id)) {
                            dashboard = dashboard.set(cDashboard.projectId, null)
                                .set(cDashboard.createdOn, null);
                        } else {
                            dashboard = dashboard.set(cDashboard.projectId, this.props.projectId);
                        }
                        setSubmitting(true);
                        this.props.onDashboardSave(dashboard);
                    }}
                >
                    {
                        ({values, errors, handleChange, handleSubmit, handleBlur, isSubmitting, setFieldValue}) => (
                            <React.Fragment>
                                <Form onSubmit={handleSubmit}>
                                    <ModalHeader>{this.props.dashboard.get(cDashboard.id) ? "Edit dashboard" : "Create dashboard"}</ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="nameField">Name</Label>
                                                    <Input
                                                        id="nameField"
                                                        name="name"
                                                        placeholder="Dashboard name"
                                                        value={values[cDashboard.name]}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={!!errors[cDashboard.name]}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.props.onToggle}>Cancel</Button>
                                        <Button color="primary" type="submit" disabled={isSubmitting}>Save</Button>
                                    </ModalFooter>
                                </Form>
                            </React.Fragment>
                        )
                    }
                </Formik>
            </Modal>
        );
    }
}

export default DashboardFormModal;