import React from "react";
import "./style.css";
import {Col, Row} from "reactstrap";

interface ContentPropsInterface {
    title: string;
    subtitle: string | JSX.Element;
    controlPanel?: () => JSX.Element;
}

class Content extends React.PureComponent<ContentPropsInterface> {

    render() {
        let controlPanelComponent;

        if (this.props.controlPanel) {
            controlPanelComponent = (
                <Col xs={4} className="text-right p-0">
                    {this.props.controlPanel()}
                </Col>
            )
        }

        return (
            <React.Fragment>
                <Row className="ml-5 mr-5 mb-4 mt-4">
                    <Col xs={8} className="p-0">
                        <h1>{this.props.title}</h1>
                        <h6>{this.props.subtitle}</h6>
                    </Col>
                    {controlPanelComponent}
                </Row>
                {this.props.children}
            </React.Fragment>
        );
    }
}

export default Content;