import {SearchProjectFilesByTaskIdAndSearchStringActionInterface} from "../TaskFilesTabContainer/actions";
import {all, debounce, put} from "redux-saga/effects";
import {TASK_FORM_CONTAINER_SEARCH_PROJECT_FILES} from "./constants";
import {getAllFilesByProjectIdAndSearchString} from "../../api/FileApi/actions";

function* searchProjectFilesByTaskIdAndSearchString(action: SearchProjectFilesByTaskIdAndSearchStringActionInterface) {
    yield put(getAllFilesByProjectIdAndSearchString(action.taskId, action.searchString, action.page));
}

export default function* saga() {
    yield all([
        yield debounce(1000, TASK_FORM_CONTAINER_SEARCH_PROJECT_FILES, searchProjectFilesByTaskIdAndSearchString)
    ]);
}