import React from "react";
import {Navbar} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlask, faSignOutAlt, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {logout} from "../../api/AuthApi/actions";
import {connect} from "react-redux";
import {cAuthReducer} from "../../api/AuthApi/constants";
import {cUser, UserType} from "../../models/User";
import {Link} from "react-router-dom";

interface HeaderNavigationContainerPropsInterface {
    authUser: UserType;

    logout: () => void;
}

class HeaderNavigationContainer extends React.PureComponent<HeaderNavigationContainerPropsInterface> {

    render() {
        return (
            <React.Fragment>
                <Navbar color="white" light expand="md" sticky="top" className="header-bar list-unstyled w-100 p-0">
                    <div className="header-logo col-1">
                        <FontAwesomeIcon className="sidebar-logo" icon={faFlask} />
                        ML project
                    </div>
                    <div className="container-fluid">
                        <div className="mr-3 col-12 text-right d-flex flex-row justify-content-end align-content-center">
                            <FontAwesomeIcon icon={faUserCircle} color="gray" className="cursor-pointer mr-1" style={{fontSize: "1.5em"}} />
                            <span className="mr-3">
                                <Link to={`/user-form/${this.props.authUser.get(cUser.id)}`} className="plain-link text-decoration-none">
                                {this.props.authUser.get(cUser.firstName) + " " + this.props.authUser.get(cUser.lastName)}
                                </Link>
                            </span>
                            |
                            <FontAwesomeIcon icon={faSignOutAlt} color="gray" className="cursor-pointer ml-3 mt-1" onClick={this.props.logout} />
                        </div>
                    </div>
                </Navbar>
                {this.props.children}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => ({
    authUser: state.authApiReducer.get(cAuthReducer.currentUser)
});

const mapDispatchToProps = (dispatch: any) => ({
   logout: () => dispatch(logout())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderNavigationContainer);