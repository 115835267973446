import {fromJS, Set} from "immutable";
import {projectDefault, ProjectInterface} from "./Project";
import {mapUserFromJS, userDefault, UserInterface} from "./User";
import {Immutable} from "./Immutable";
import * as Yup from "yup";
import {TaskTargetType} from "./TaskTargetType";
import {QueueType} from "./QueueType";
import {TaskState} from "./TaskState";
import {
    genericFileDefault,
    GenericFileInterface,
    GenericFileType,
    mapGenericFileFromJS,
    mapGenericFileToJS
} from "./GenericFile";

export enum cTask {
    id = "id",
    name = "name",
    targetType = "targetType",
    jobId = "jobId",
    externalProjectId = "externalProjectId",
    queueType = "queueType",
    numberOfNodes = "numberOfNodes",
    numberOfCoresPerNode = "numberOfCoresPerNode",
    maximumAllocatedTime = "maximumAllocatedTime",
    createdOn = "createdOn",
    updatedOn = "updatedOn",
    state = "state",
    errorLog = "errorLog",
    executableFileId = "executableFileId",
    projectId = "projectId",
    creatorId = "creatorId",
    taskFiles = "taskFiles",
    executableFile = "executableFile",
    project = "project",
    creator = "creator"
}

export interface TaskInterface {
    [cTask.id]?: string;
    [cTask.name]: string;
    [cTask.targetType]: TaskTargetType;
    [cTask.jobId]?: string;
    [cTask.externalProjectId]?: string;
    [cTask.queueType]?: QueueType;
    [cTask.numberOfNodes]?: number;
    [cTask.numberOfCoresPerNode]?: number;
    [cTask.maximumAllocatedTime]?: number; // RENAME to maximumAllocatedTime!
    [cTask.createdOn]?: string;
    [cTask.updatedOn]?: string;
    [cTask.state]?: TaskState;
    [cTask.errorLog]?: string;
    [cTask.executableFileId]?: string;
    [cTask.projectId]: string;
    [cTask.creatorId]?: string;
    [cTask.taskFiles]?: Set<any>;
    [cTask.executableFile]?: GenericFileInterface;
    [cTask.project]?: ProjectInterface;
    [cTask.creator]?: UserInterface;
}

export type TaskType = Immutable<TaskInterface>;

export const taskValidationSchema = Yup.object().shape({
    [cTask.name]: Yup.string().required(),
    [cTask.targetType]: Yup.string().required(),
    [cTask.queueType]: Yup.string().when([cTask.targetType], {
        is: (value) => value === TaskTargetType.HPC,
        then: Yup.string().required()
    }),
    [cTask.numberOfNodes]: Yup.number().when([cTask.targetType], {
        is: (value) => value === TaskTargetType.HPC,
        then: Yup.number().required().min(1)
    }),
    [cTask.numberOfCoresPerNode]: Yup.number().when([cTask.targetType], {
        is: (value) => value === TaskTargetType.HPC,
        then: Yup.number().required().min(1)
    }),
    [cTask.maximumAllocatedTime]: Yup.number().when([cTask.targetType], {
        is: (value) => value === TaskTargetType.HPC,
        then: Yup.number().required().min(1)
    })
});

export const mapTaskFromJS = (src: TaskInterface): TaskType => {
    let executableFile;
    let creator;
    if (src[cTask.executableFile]) executableFile = src[cTask.executableFile];
    if (src[cTask.creator]) creator = src[cTask.creator];

    return fromJS({
        [cTask.id]: src[cTask.id],
        [cTask.name]: src[cTask.name],
        [cTask.targetType]: src[cTask.targetType],
        [cTask.jobId]: src[cTask.jobId],
        [cTask.externalProjectId]: src[cTask.externalProjectId] || '',
        [cTask.queueType]: src[cTask.queueType],
        [cTask.numberOfNodes]: src[cTask.numberOfNodes],
        [cTask.numberOfCoresPerNode]: src[cTask.numberOfCoresPerNode],
        [cTask.maximumAllocatedTime]: src[cTask.maximumAllocatedTime],
        [cTask.createdOn]: src[cTask.createdOn],
        [cTask.updatedOn]: src[cTask.updatedOn],
        [cTask.state]: src[cTask.state],
        [cTask.errorLog]: src[cTask.errorLog],
        [cTask.executableFileId]: src[cTask.executableFileId],
        [cTask.projectId]: src[cTask.projectId],
        [cTask.creatorId]: src[cTask.creatorId],
        [cTask.taskFiles]: src[cTask.taskFiles],
        [cTask.executableFile]: executableFile ? mapGenericFileFromJS(executableFile) : undefined,
        [cTask.project]: src[cTask.project],
        [cTask.creator]: creator ? mapUserFromJS(creator) : undefined
    })
};

export const mapTaskToJS = (src: TaskType): TaskInterface => {
    let executableFile;
    if (src.get(cTask.executableFile)) executableFile = (src.get(cTask.executableFile) as unknown) as GenericFileType;

    let hpcTaskValues;
    if (src.get(cTask.targetType) === TaskTargetType.HPC) {
        hpcTaskValues = {
            [cTask.externalProjectId]: src.get(cTask.externalProjectId)?.length !== 0 ? src.get(cTask.externalProjectId) : undefined,
            [cTask.queueType]: src.get(cTask.queueType),
            [cTask.numberOfNodes]: src.get(cTask.numberOfNodes),
            [cTask.numberOfCoresPerNode]: src.get(cTask.numberOfCoresPerNode),
            [cTask.maximumAllocatedTime]: src.get(cTask.maximumAllocatedTime)
        }
    }

    return {
        [cTask.id]: src.get(cTask.id),
        [cTask.name]: src.get(cTask.name),
        [cTask.targetType]: src.get(cTask.targetType),
        [cTask.executableFileId]: src.get(cTask.executableFileId),
        [cTask.projectId]: src.get(cTask.projectId),
        [cTask.taskFiles]: src.get(cTask.taskFiles),
        [cTask.executableFile]: executableFile ? mapGenericFileToJS(executableFile) : undefined,
        ...hpcTaskValues
    };
}

export const convertAllocationTimeToMinutes = (src: TaskType): TaskType => {
    const maximumAllocationTime = src.get(cTask.maximumAllocatedTime);
    return src.set(cTask.maximumAllocatedTime, maximumAllocationTime && maximumAllocationTime / 60);
}

export const convertAllocationTimeFromMinutes = (src: TaskType): TaskType => {
    const maximumAllocationTime = src.get(cTask.maximumAllocatedTime);
    return src.set(cTask.maximumAllocatedTime, maximumAllocationTime && maximumAllocationTime * 60);
}

export const taskDefault = (): TaskType => fromJS({
    [cTask.id]: null,
    [cTask.name]: "",
    [cTask.targetType]: undefined,
    [cTask.jobId]: null,
    [cTask.externalProjectId]: undefined,
    [cTask.queueType]: QueueType.QEXP,
    [cTask.numberOfNodes]: 1,
    [cTask.numberOfCoresPerNode]: 1,
    [cTask.maximumAllocatedTime]: 1,
    [cTask.createdOn]: null,
    [cTask.updatedOn]: null,
    [cTask.state]: TaskState.READY,
    [cTask.errorLog]: null,
    [cTask.executableFileId]: null,
    [cTask.projectId]: null,
    [cTask.creatorId]: null,
    [cTask.taskFiles]: Set(),
    [cTask.executableFile]: genericFileDefault(),
    [cTask.project]: projectDefault(),
    [cTask.creator]: userDefault()
});