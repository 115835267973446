export const ExceptionCode = {
    MANSERV: "Something went wrong!",
    MANSERV_DB_1: "Duplicate or invalid entity",

    MANSERV_PARAM_1: "Parameter not found.",

    MANSERV_USER_1: "User not found.",

    MANSERV_FILE_1: "File not found.",

    MANSERV_PROJ_1: "Project not found.",
    MANSERV_PROJ_2: "Unable to delete project because some of its task is in use.",

    MANSERV_PRTAG_1: "Project tag not found.",

    MANSERV_TASKF_1: "Task file not found.",

    MANSERV_TASK_1: "Task not found.",
    MANSERV_TASK_2: "Illegal task state update.",
    MANSERV_TASK_3: "Unable to delete task which is in use.",
    MANSERV_TASK_4: "Missing executable file for task run.",

    MANSERV_TASKRS_1: "Requested task runner is not available.",

    MANSERV_STORE_1: "There was an error during file/directory save.",
    MANSERV_STORE_2: "No free space.",
    MANSERV_STORE_3: "There was an error during file delete.",
    MANSERV_STORE_4: "There was an error during directory delete.",
    MANSERV_STORE_5: "File not found.",

    MANSERV_MVIS_1: "Metric visualization not found.",

    MANSERV_DASH_1: "Dashboard not found.",
    MANSERV_DASIT_1: "Dashboard item not found.",

    MANSERV_SECCON_1: "Unable to get current user.",

    MANSERV_SSHKEY_1: "Unsupported key type.",
    MANSERV_SSHKEY_2: "Unable to load provided key pair."
}

export const getExceptionMessage = (code: string) => {
    // @ts-ignore
    return  ExceptionCode[code];
}