import {all, call, put, takeLatest} from "redux-saga/effects";
import {
    DASHBOARD_ADD_METRIC_VISUALIZATION,
    DASHBOARD_DELETE_BY_ID,
    DASHBOARD_GET_ALL_BY_PROJECT_ID,
    DASHBOARD_GET_BY_ID,
    DASHBOARD_REMOVE_METRIC_VISUALIZATION,
    DASHBOARD_SAVE
} from "./constants";
import {
    AddMetricVisualizationToDashboardActionInterface,
    DeleteDashboardByIdActionInterface,
    GetAllDashboardsByProjectIdActionInterface,
    GetDashboardByIdActionInterface,
    RemoveMetricVisualizationFromDashboardActionInterface,
    SaveDashboardActionInterface
} from "./actions";
import {Network} from "../../internal/network";
import {cDashboard, mapDashboardFromJS} from "../../models/Dashboard";
import Links from "../../global/links";
import {Actions} from "../../global/actions";
import {Alert} from "../../containers/AlertContainer/actions";
import {Callback} from "../../models/Event";
import {mapPageFromJS} from "../../models/Page";
import {getExceptionMessage} from "../../models/ExceptionCode";

function* save(action: SaveDashboardActionInterface) {
    try {
        let id: number | undefined = action.dashboard.get(cDashboard.id);
        const payload = {body: JSON.stringify(action.dashboard)};
        if (id) {
            yield call(Network.put(Links.dashboard(), payload));
        } else  {
            const response = yield call(Network.post(Links.dashboard(), payload));
            id = mapDashboardFromJS(response).get(cDashboard.id);
        }
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("Dashboard has been successfully saved."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks, id));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* deleteById(action: DeleteDashboardByIdActionInterface) {
    try {
        yield call(Network.del(Links.dashboardById(action.id)));
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("Dashboard has been successfully deleted."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* addMetricVisualizationToDashboard(action: AddMetricVisualizationToDashboardActionInterface) {
    try {
        yield call(Network.put(Links.dashboardMetricVisualization(action.metricVisualizationId, action.dashboardId)));
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("Visualization has been successfully added."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* removeMetricVisualizationToDashboard(action: RemoveMetricVisualizationFromDashboardActionInterface) {
    try {
        yield call(Network.del(Links.dashboardMetricVisualization(action.metricVisualizationId, action.dashboardId)));
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("Visualization has been successfully removed."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* getById(action: GetDashboardByIdActionInterface) {
    try {
        const dashboard = yield call(Network.get(Links.dashboardById(action.id)));
        yield put(Actions.fulfilled(action.type, mapDashboardFromJS(dashboard)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

function* getAllByProjectId(action: GetAllDashboardsByProjectIdActionInterface) {
    try {
        const dashboards = yield call(Network.get(Links.dashboardByProjectId(action.projectId, action.page?.page, action.page?.size)));
        yield put(Actions.fulfilled(action.type, mapPageFromJS(dashboards, mapDashboardFromJS)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

export default function* saga() {
    yield all([
        yield takeLatest(DASHBOARD_SAVE, save),
        yield takeLatest(DASHBOARD_DELETE_BY_ID, deleteById),
        yield takeLatest(DASHBOARD_ADD_METRIC_VISUALIZATION, addMetricVisualizationToDashboard),
        yield takeLatest(DASHBOARD_REMOVE_METRIC_VISUALIZATION, removeMetricVisualizationToDashboard),
        yield takeLatest(DASHBOARD_GET_BY_ID, getById),
        yield takeLatest(DASHBOARD_GET_ALL_BY_PROJECT_ID, getAllByProjectId)
    ])
}