import {
    PROJECT_ASSIGNMENT_DELETE_BY_ID,
    PROJECT_ASSIGNMENT_GET_BY_PROJECT_ID,
    PROJECT_ASSIGNMENT_RESET_VALUE,
    PROJECT_ASSIGNMENT_SAVE_ALL
} from "./constatns";
import {Pagination} from "../../models/Pagination";
import {ProjectAssignmentType} from "../../models/ProjectAssignment";
import {Set} from "immutable";
import {CallbackInterface} from "../../models/Event";

export interface SaveAllProjectAssignmentsActionInterface {
    type: string;
    projectAssignments: Set<ProjectAssignmentType>;
    callbacks?: CallbackInterface<void>;
}

export interface DeleteProjectAssignmentByIdActionInterface {
    type: string;
    id: string;
    callbacks?: CallbackInterface<void>;
}

export interface GetAllProjectAssignmentsByProjectIdActionInterface {
    type: string;
    projectId: string;
    page?: Pagination
}

export interface ResetProjectAssignmentValueActionInterface {
    type: string;
    field: string;
}

export const saveAllProjectAssignments = (projectAssignments: Set<ProjectAssignmentType>, callbacks?: CallbackInterface<void>): SaveAllProjectAssignmentsActionInterface => ({
    type: PROJECT_ASSIGNMENT_SAVE_ALL,
    projectAssignments,
    callbacks
});

export const deleteProjectAssignmentById = (id: string, callbacks?: CallbackInterface<void>): DeleteProjectAssignmentByIdActionInterface => ({
    type: PROJECT_ASSIGNMENT_DELETE_BY_ID,
    id,
    callbacks
})

export const getAllProjectAssignmentsByProjectId = (projectId: string, page?: Pagination): GetAllProjectAssignmentsByProjectIdActionInterface => ({
    type: PROJECT_ASSIGNMENT_GET_BY_PROJECT_ID,
    projectId,
    page
});

export const resetProjectAssignmentValue = (field: string): ResetProjectAssignmentValueActionInterface => ({
    type: PROJECT_ASSIGNMENT_RESET_VALUE,
    field
});
