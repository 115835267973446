import {TASK_FILES_TAB_SEARCH_PROJECT_FILES} from "./constants";
import {Pagination} from "../../models/Pagination";

export interface SearchProjectFilesByTaskIdAndSearchStringActionInterface {
    type: string;
    taskId: string;
    searchString: string;
    page?: Pagination
}

export const searchProjectFilesByTaskIdAndSearchString = (taskId: string, searchString: string, page?: Pagination): SearchProjectFilesByTaskIdAndSearchStringActionInterface => ({
    type: TASK_FILES_TAB_SEARCH_PROJECT_FILES,
    taskId,
    searchString,
    page
});