export enum cProjectTagReducer {
    projectTag = "projectTag",
    projectTags = "projectTags"
}

export const PROJECT_TAG_SAVE = "ProjectTag/SAVE";
export const PROJECT_TAG_DELETE_BY_ID = "ProjectTag/DELETE";
export const PROJECT_TAG_GET_BY_ID = "ProjectTag/GET_BY_ID";
export const PROJECT_TAG_GET_ALL_BY_PROJECT_ID = "ProjectTag/GET_ALL_BY_PROJECT_ID";
export const PROJECT_TAG_GET_ALL_BY_TASK_ID = "ProjectTag/GET_ALL_BY_TASK_ID";
export const PROJECT_TAG_RESET = "ProjectTag/RESET";