import React from "react";
import {connect} from "react-redux";
import Content from "../../components/Content";
import ContentCard from "../../components/ContentCard";
import {Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown} from "reactstrap";
import {cDashboard, DashboardType} from "../../models/Dashboard";
import {cDashboardReducer} from "../../api/DashboardApi/constants";
import {getDashboardById} from "../../api/DashboardApi/actions";
import {Link, RouteComponentProps} from "react-router-dom";
import {cMetricQuery, mapMetricQueryToJS, metricQueryDefault, MetricQueryType} from "../../models/MetricQuery";
import MetricPlotContainer from "../MetricPlotContainer";
import {cProjectTag} from "../../models/ProjectTag";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faPlus} from "@fortawesome/free-solid-svg-icons";
import {List} from "immutable";
import {
    cDashboardItem,
    dashboardItemDefault,
    DashboardItemType,
    mapDashboardItemFromJS
} from "../../models/DashboardItem";
import {cDashboardItemReducer} from "../../api/DashboardItemApi/constants";
import {
    deleteDashboardItemById,
    getAllDashboardItemsByDashboardId,
    saveDashboardItem
} from "../../api/DashboardItemApi/actions";
import VisualizationSearchModal from "../../components/VisualizationSearchModal";
import {PageType} from "../../models/Page";
import {cVisualizationReducer} from "../../api/VisualizationApi/constants";
import {resetVisualizationValue} from "../../api/VisualizationApi/actions";
import {Pagination} from "../../models/Pagination";
import {SuccessEventCallback} from "../../models/Event";
import {searchVisualizationsByProjectIAndSearchString} from "./actions";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import DashboardItemFormModal from "../../components/DashboardItemFormModal";

interface DashboardContainerPropsInterface {
    dashboard: DashboardType;
    dashboardItems: List<DashboardItemType>;
    foundVisualizations: PageType<MetricQueryType>;

    saveDashboardItem: (dashboardItem: DashboardItemType, successEventCallbacks?: SuccessEventCallback<number>[]) => void;
    deleteDashboardItem: (id: number, successEventCallbacks?: SuccessEventCallback<void>[]) => void,
    getDashboardById: (id: number) => void;
    getAllDashboardItemsByDashboardId: (dashboardId: number) => void;
    searchVisualizationsByProjectIAndSearchString: (projectId: string, searchString: string, page?: Pagination) => void;
    resetFoundVisualizations: () => void;
}

interface DashboardContainerStateInterface {
    selectedDashboardItem?: DashboardItemType;
    isVisualizationAddModalOpen: boolean;
    isDeleteConfirmationDialogOpen: boolean;
    isEditDialogOpen: boolean;
}

class DashboardContainer extends React.PureComponent<DashboardContainerPropsInterface & RouteComponentProps, DashboardContainerStateInterface> {

    state = {
        selectedDashboardItem: undefined,
        isVisualizationAddModalOpen: false,
        isDeleteConfirmationDialogOpen: false,
        isEditDialogOpen: false
    }

    componentDidMount() {
        const dashboardId: number = parseInt(new URLSearchParams(this.props.match.params).get("id") || "-1");
        this.props.getDashboardById(dashboardId);
        this.props.getAllDashboardItemsByDashboardId(dashboardId);
    }

    handleConfirmationDialogToggle = (dashboardItem?: DashboardItemType) => {
        this.setState(prevState => ({
            ...prevState,
            isDeleteConfirmationDialogOpen: !prevState.isDeleteConfirmationDialogOpen,
            selectedDashboardItem: dashboardItem
        }));
    }

    handleToggleVisualizationAddModal = () => {
        this.setState(prevState => ({
            ...prevState,
            isVisualizationAddModalOpen: !prevState.isVisualizationAddModalOpen
        }));
    }

    handleToggleDashboardItemEditModal = (dashboardItem?: DashboardItemType) => {
        this.setState(prevState => ({
            ...prevState,
            isEditDialogOpen: !prevState.isEditDialogOpen,
            selectedDashboardItem: dashboardItem
        }));
    }

    handleSaveDashboardItem = (dashboardItem: DashboardItemType) => {
        const dashboardId = this.props.dashboard.get(cDashboard.id);
        if (dashboardId) {
            this.props.saveDashboardItem(
                dashboardItem,
                [
                    () => getAllDashboardItemsByDashboardId(dashboardId)
                ]
            );
        }
        this.handleToggleDashboardItemEditModal();
    }

    handleDeleteConfirm = () => {
        const visualization = this.state.selectedDashboardItem || dashboardItemDefault();
        const visualizationId = visualization.get(cDashboardItem.id);
        const dashboardId = this.props.dashboard.get(cDashboard.id);
        if (visualizationId && dashboardId) {
            this.props.deleteDashboardItem(
                visualizationId,
                [
                    () => getAllDashboardItemsByDashboardId(dashboardId)
                ]
            )
        }
        this.handleConfirmationDialogToggle();
    }

    handleAddVisualization = (visualization: MetricQueryType) => {
        const dashboardId = this.props.dashboard.get(cDashboard.id);
        const visualizationId = visualization.get(cMetricQuery.id);

        if (dashboardId && visualizationId) {
            const dashboardItem: DashboardItemType = mapDashboardItemFromJS({
                [cDashboardItem.dashboardId]: dashboardId,
                [cDashboardItem.visualizationId]: visualizationId
            })
            this.props.saveDashboardItem(
                dashboardItem,
                [
                    () => getAllDashboardItemsByDashboardId(dashboardId)
                ]
            );
        }
        this.handleToggleVisualizationAddModal();
    }

    handleSearchVisualizationBySearchString = (searchString: string, page?: Pagination) => {
        const projectId = this.props.dashboard.get(cDashboard.projectId);
        this.props.searchVisualizationsByProjectIAndSearchString(projectId, searchString, page);
    }

    renderBreadcrumb = () => {
        return (
            <span>
                <Link to="/" className="plain-link">Home</Link>
                {" / "}
                <Link to="/project-list" className="plain-link">Projects</Link>
                {" / "}
                <Link to={`/project/${this.props.dashboard.getIn([cDashboard.project, cDashboard.id])}`} className="plain-link">
                    {this.props.dashboard.getIn([cDashboard.project, cDashboard.name])}
                </Link>
                {" / "}
                {this.props.dashboard.get(cDashboard.name)}
            </span>
        )
    }

    renderControlPanel = () => {
        return (
            <Button color="success" className="pr-5 mt-4" onClick={this.handleToggleVisualizationAddModal}>
                <FontAwesomeIcon icon={faPlus} className="mr-5"/>
                Add
            </Button>
        )
    }

    renderCards = () => {
        const chartCards: JSX.Element[] = [];
        this.props.dashboardItems
            .forEach(dashboardItem => chartCards.push(
                <Col xs={6} className="mb-4" key={dashboardItem.get(cDashboardItem.id)}>
                    <ContentCard className="m-1">
                        <span className="d-flex justify-content-end">
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    tag="span"
                                    data-toggle="dropdown"
                                >
                                    <FontAwesomeIcon
                                        icon={faCog}
                                        color="gray"
                                        className="mr-2 mt-2 cursor-pointer"
                                    />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => this.handleToggleDashboardItemEditModal(dashboardItem)}>Edit</DropdownItem>
                                    <DropdownItem onClick={() => this.handleConfirmationDialogToggle(dashboardItem)}>Remove</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </span>
                        <div style={{height: "500px"}}>
                            <MetricPlotContainer
                                metricQuery={mapMetricQueryToJS(dashboardItem.get(cDashboardItem.visualization) || metricQueryDefault())}
                                dataSeriesLabel={dashboardItem.getIn([cDashboardItem.visualization, cMetricQuery.tag, cProjectTag.name])}
                            />
                        </div>
                    </ContentCard>
                </Col>
            ));

        return chartCards;
    }

    render() {
        return (
            <Content title="Dashboard" subtitle={this.renderBreadcrumb()} controlPanel={this.renderControlPanel}>
                <Row className="ml-5 mr-5 justify-content-start no-gutters">
                    {this.renderCards()}
                </Row>
                {
                    this.state.isVisualizationAddModalOpen
                    ? <VisualizationSearchModal
                            isOpen={this.state.isVisualizationAddModalOpen}
                            foundVisualizations={this.props.foundVisualizations}
                            onToggle={this.handleToggleVisualizationAddModal}
                            onSearchVisualizationsBySearchString={this.handleSearchVisualizationBySearchString}
                            onResetFoundVisualizations={this.props.resetFoundVisualizations}
                            onAddVisualization={this.handleAddVisualization}
                        />
                    : null
                }
                {
                    this.state.isDeleteConfirmationDialogOpen
                    ? <ConfirmationDialog
                            header="Visualization remove"
                            body="Remove selected visualization from dashboard?"
                            isOpen={this.state.isDeleteConfirmationDialogOpen}
                            handleConfirm={this.handleDeleteConfirm}
                            handleCancel={this.handleConfirmationDialogToggle}
                        />
                    : null
                }
                {
                    this.state.isEditDialogOpen
                    ? <DashboardItemFormModal
                            isOpen={this.state.isEditDialogOpen}
                            dashboardItem={this.state.selectedDashboardItem || dashboardItemDefault()}
                            totalItems={this.props.dashboardItems.size}
                            onToggle={this.handleToggleDashboardItemEditModal}
                            onSaveDashboardItem={this.handleSaveDashboardItem}
                        />
                    : null
                }
            </Content>
        );
    }
}

const mapStateToProps = (state: any) => ({
    dashboard: state.dashboardApiReducer.get(cDashboardReducer.dashboard),
    dashboardItems: state.dashboardItemApiReducer.get(cDashboardItemReducer.dashboardItems),
    foundVisualizations: state.visualizationApiReducer.get(cVisualizationReducer.visualizations)
});

const mapDispatchToProps = (dispatch: any) => ({
    saveDashboardItem: (dashboardItem: DashboardItemType, successEventCallbacks?: SuccessEventCallback<number>[]) => dispatch(
        saveDashboardItem(dashboardItem, {onSuccess: successEventCallbacks})
    ),
    deleteDashboardItem: (id: number, successEventCallbacks?: SuccessEventCallback<void>[]) => dispatch(
        deleteDashboardItemById(id, {onSuccess: successEventCallbacks})
    ),
    getDashboardById: (id: number) => dispatch(getDashboardById(id)),
    getAllDashboardItemsByDashboardId: (dashboardId: number) => dispatch(getAllDashboardItemsByDashboardId(dashboardId)),
    searchVisualizationsByProjectIAndSearchString: (projectId: string, searchString: string, page?: Pagination) => dispatch(searchVisualizationsByProjectIAndSearchString(projectId, searchString, page)),
    resetFoundVisualizations: () => dispatch(resetVisualizationValue(cVisualizationReducer.visualizations))
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(DashboardContainer);