import {
    cVisualizationReducer,
    VISUALIZATION_GET_ALL_BY_PROJECT_ID_AND_SEARCH_STRING,
    VISUALIZATION_GET_ALL_BY_TASK_ID,
    VISUALIZATION_GET_BY_ID,
    VISUALIZATION_RESET_VALUE
} from "./constants";
import {metricQueryDefault, MetricQueryType} from "../../models/MetricQuery";
import {pageDefault, PageType} from "../../models/Page";
import {Immutable} from "../../models/Immutable";
import {FULFILLED} from "../../global/constants";

interface InitialStateInterface {
    [cVisualizationReducer.visualization]: MetricQueryType;
    [cVisualizationReducer.visualizations]: PageType<MetricQueryType>;
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
    [cVisualizationReducer.visualization]: metricQueryDefault(),
    [cVisualizationReducer.visualizations]: pageDefault<MetricQueryType>(),
})

const reducer = (state: InitialStateType = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case `${VISUALIZATION_GET_BY_ID}${FULFILLED}`: {
            return state.set(cVisualizationReducer.visualization, action.payload);
        }
        case `${VISUALIZATION_GET_ALL_BY_TASK_ID}${FULFILLED}`: {
            return state.set(cVisualizationReducer.visualizations, action.payload);
        }
        case `${VISUALIZATION_GET_ALL_BY_PROJECT_ID_AND_SEARCH_STRING}${FULFILLED}`: {
            return state.set(cVisualizationReducer.visualizations, action.payload);
        }
        case VISUALIZATION_RESET_VALUE: {
            return state.set(action.field, initialState.get(action.field));
        }
        default:
            return state;
    }
}

export default reducer;