import {Set} from "immutable";
import {TaskFileType} from "../../models/TaskFile";
import {CallbackInterface} from "../../models/Event";
import {TASK_FILE_DELETE_BY_ID, TASK_FILE_GET_ALL_BY_TASK_ID, TASK_FILE_SAVE_ALL} from "./constants";
import {Pagination} from "../../models/Pagination";

export interface SaveAllTaskFilesActionInterface {
    type: string;
    taskFiles: Set<TaskFileType>;
    callbacks?: CallbackInterface<void>;
}

export interface DeleteTaskFileByIdActionInterface {
    type: string;
    id: number;
    callbacks?: CallbackInterface<void>;
}

export interface GetAllTaskFilesByTaskIdActionInterface {
    type: string;
    taskId: string;
    page?: Pagination;
}

export const saveAllTaskFiles = (taskFiles: Set<TaskFileType>, callbacks?: CallbackInterface<void>): SaveAllTaskFilesActionInterface => ({
   type: TASK_FILE_SAVE_ALL,
   taskFiles,
   callbacks
});

export const deleteTaskFileById = (id: number, callbacks?: CallbackInterface<void>): DeleteTaskFileByIdActionInterface => ({
    type: TASK_FILE_DELETE_BY_ID,
    id,
    callbacks
});

export const getAllTaskFilesByTaskId = (taskId: string, page?: Pagination): GetAllTaskFilesByTaskIdActionInterface => ({
    type: TASK_FILE_GET_ALL_BY_TASK_ID,
    taskId,
    page
});