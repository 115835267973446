import {cTaskFileReducer, TASK_FILE_GET_ALL_BY_TASK_ID} from "./constants";
import {taskFileDefault, TaskFileType} from "../../models/TaskFile";
import {pageDefault, PageType} from "../../models/Page";
import {Immutable} from "../../models/Immutable";
import {FULFILLED} from "../../global/constants";

interface InitialStateInterface {
    [cTaskFileReducer.taskFile]: TaskFileType;
    [cTaskFileReducer.taskFiles]: PageType<TaskFileType>;
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
    [cTaskFileReducer.taskFile]: taskFileDefault(),
    [cTaskFileReducer.taskFiles]: pageDefault<TaskFileType>()
});

const reducer = (state: InitialStateType = initialState, action: any) => {
    switch (action.type) {
        case `${TASK_FILE_GET_ALL_BY_TASK_ID}${FULFILLED}`: {
            return state.set(cTaskFileReducer.taskFiles, action.payload);
        }
        default:
            return state;
    }
}

export default reducer;