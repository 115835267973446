import {connect} from "react-redux";
import {Redirect} from "react-router";
import * as React from "react";
import {hasToken} from "../../api/AuthApi/utils";
import {UserType} from "../../models/User";
import {cAuthReducer} from "../../api/AuthApi/constants";

interface AuthCheckerInterface {
    authUser: UserType;
    authUserLoading: boolean;
}

export default (WrappedComponent: any) => {
    class AuthChecker extends React.PureComponent<AuthCheckerInterface> {
        render() {
            const {authUser, authUserLoading, ...otherProps} = this.props;

            if (!hasToken()) {
                console.log("AuthChecker: no token!")
                return <Redirect to="/login"/>;
            }

            if (!authUser) {
                return <div>Logging in...</div>;
                // return <Loader loading={true} />;
            }

            return (
                <React.Fragment>
                    <WrappedComponent {...otherProps} />
                </React.Fragment>
            )
        }
    }

    const mapStateToProps = (state: any) => ({
        authUser: state.authApiReducer.get(cAuthReducer.currentUser),
        authUserLoading: state.authApiReducer.get(cAuthReducer.loading)
    });

    const mapDispatchToProps = (dispatch: any) => ({

    });

    return  connect(
        mapStateToProps,
        mapDispatchToProps
    )(AuthChecker);
}