import React from "react";
import {Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {cProjectTag, mapProjectTagFromJS, ProjectTagType, projectTagValidationSchema} from "../../models/ProjectTag";
import {Formik} from "formik";

interface ProjectTagFormModalPropsInterface {
    projectId: string;
    className?: string;

    isOpen: boolean;
    projectTag: ProjectTagType;

    onToggle: () => void;
    onProjectTagSave: (projectTag: ProjectTagType) => void;
}

class ProjectTagFormModal extends React.PureComponent<ProjectTagFormModalPropsInterface> {

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle} className={this.props.className}>
                <Formik
                    initialValues={this.props.projectTag.toJS()}
                    validationSchema={projectTagValidationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize
                    onSubmit={(values, {setSubmitting}) => {
                        let projectTag = mapProjectTagFromJS(values);
                        if (projectTag.get(cProjectTag.id)) {
                            projectTag = projectTag.set(cProjectTag.projectId, null);
                        } else {
                            projectTag = projectTag.set(cProjectTag.projectId, this.props.projectId);
                        }
                        this.props.onProjectTagSave(projectTag);
                    }}
                >
                    {
                        ({values, errors, handleChange, handleSubmit, handleBlur, isSubmitting, setFieldValue}) => (
                            <React.Fragment>
                                <Form onSubmit={handleSubmit}>
                                    <ModalHeader>{this.props.projectTag.get(cProjectTag.id) ? "Edit project tag" : "Add project tag"}</ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="nameField">Name</Label>
                                                    <Input
                                                        id="nameField"
                                                        name="name"
                                                        placeholder="Tag name"
                                                        value={values[cProjectTag.name]}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={!!errors[cProjectTag.name]}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="valueField">Value</Label>
                                                    <Input
                                                        id="valueField"
                                                        name="value"
                                                        placeholder="Tag value"
                                                        value={values[cProjectTag.value]}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={!!errors[cProjectTag.value]}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="descriptionField">Description</Label>
                                                    <Input
                                                        id="descriptionField"
                                                        name="description"
                                                        placeholder="Short description"
                                                        value={values[cProjectTag.description]}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={!!errors[cProjectTag.description]}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.props.onToggle}>Cancel</Button>
                                        <Button color="primary" type="submit" disabled={isSubmitting}>Save</Button>
                                    </ModalFooter>
                                </Form>
                            </React.Fragment>
                        )
                    }
                </Formik>
            </Modal>
        );
    }
}

export default ProjectTagFormModal;