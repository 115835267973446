import {Pagination} from "../../models/Pagination";
import {DASHBOARD_CONTAINER_SEARCH_VISUALIZATIONS} from "./constants";

export interface SearchVisualizationsByProjectIdAndSearchStringActionInterface {
    type: string;
    projectId: string;
    searchString: string;
    page?: Pagination;
}

export const searchVisualizationsByProjectIAndSearchString = (projectId: string, searchString: string, page?: Pagination): SearchVisualizationsByProjectIdAndSearchStringActionInterface => ({
    type: DASHBOARD_CONTAINER_SEARCH_VISUALIZATIONS,
    projectId,
    searchString,
    page
});