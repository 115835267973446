import {Store} from "redux";
import {LOCATION_CHANGE, RouterState} from "connected-react-router";
import {getAuth} from "../internal/endpoints";
import {fetchMyself, loginByCode} from "../api/AuthApi/actions";
import {hasToken} from "../api/AuthApi/utils";
import {cAuthReducer} from "../api/AuthApi/constants";
import {cUser} from "../models/User";

export const AuthLoginMiddleware = (store: Store) => (next: Function) => (action: {type: string; payload: any}) => {
    if (action.type.includes(LOCATION_CHANGE)) {
        const currentPathname: string = action.payload.location.pathname;
        const authLoginPathname: string = getAuth().redirectUri;

        // console.log("Current pathname: " + currentPathname);
        // console.log("Current auth login pathname: " + authLoginPathname);
        if (authLoginPathname.endsWith(currentPathname)) {
            const state = store.getState();
            const router = state.router as RouterState;
            const params = new URLSearchParams(router.location.search);
            const code = params.get("code");
            // console.log(code);
            store.dispatch(loginByCode(code || ""));
        } else if (hasToken() && store.getState().authApiReducer.getIn([cAuthReducer.currentUser, cUser.id]) === null) {
            store.dispatch(fetchMyself());
        }
    }
    return next(action);
}