import {Pagination} from "../../models/Pagination";
import {
    USER_DELETE_BY_ID,
    USER_GET_ALL,
    USER_GET_ALL_BY_SEARCH_STRING,
    USER_GET_BY_ID,
    USER_RESET_VALUE,
    USER_SAVE,
    USER_UPDATE_ROLE_BY_ID
} from "./constants";
import {UserType} from "../../models/User";
import {CallbackInterface} from "../../models/Event";
import {Role} from "../../models/Role";

export interface SaveUserActionInterface {
    type: string;
    user: UserType;
    callbacks?: CallbackInterface<string>;
}

export interface UpdateUserRoleByIdActionInterface {
    type: string;
    id: string;
    role: Role;
    callbacks?: CallbackInterface<void>;
}

export interface DeleteUserByIdActionInterface {
    type: string;
    id: string;
    callbacks?: CallbackInterface<void>;
}

export interface GetUserByIdActionInterface {
    type: string;
    id: string;
}

export interface GetAllUsersActionInterface {
    type: string;
    page?: Pagination;
}

export interface GetAllUsersBySearchStringActionInterface {
    type: string;
    searchString: string;
    page?: Pagination;
}

export interface ResetUserValueActionInterface {
    type: string;
    field: string;
}

export const saveUser = (user: UserType, callbacks?: CallbackInterface<string>): SaveUserActionInterface => ({
   type: USER_SAVE,
   user,
   callbacks
});

export const updateUserRoleById = (id: string, role: Role, callbacks?: CallbackInterface<void>): UpdateUserRoleByIdActionInterface => ({
    type: USER_UPDATE_ROLE_BY_ID,
    id,
    role,
    callbacks
});

export const deleteUserById = (id: string, callbacks?: CallbackInterface<void>): DeleteUserByIdActionInterface => ({
    type: USER_DELETE_BY_ID,
    id,
    callbacks
});

export const getUserById = (id: string): GetUserByIdActionInterface => ({
    type: USER_GET_BY_ID,
    id
});

export const getAllUsers = (page?: Pagination): GetAllUsersActionInterface => ({
    type: USER_GET_ALL,
    page
});

export const getAllUsersBySearchString = (searchString: string, page?: Pagination): GetAllUsersBySearchStringActionInterface => ({
    type: USER_GET_ALL_BY_SEARCH_STRING,
    searchString,
    page
});

export const resetUserValue = (field: string): ResetUserValueActionInterface => ({
    type: USER_RESET_VALUE,
    field: field
});