import React from "react";
import {Link} from "react-router-dom";
import Content from "../../components/Content";
import ContentCard from "../../components/ContentCard";
import {Nav, TabContent, TabPane} from "reactstrap";
import TabLink from "../../components/TabLink";
import UsersTabContainer from "../UsersTabContainer";

interface ConfigManagementContainerPropsInterface {

}

interface ConfigManagementContainerStateInterface {
    activeTab: number;
}

class ConfigManagementContainer extends React.PureComponent<ConfigManagementContainerPropsInterface, ConfigManagementContainerStateInterface> {

    state = {
        activeTab: 1
    }

    setActiveTab = (id: number) => {
        this.setState((prevState) => ({
            ...prevState,
            activeTab: id
        }));
    }

    renderBreadcrumb = () => {
        return (
            <span>
                <Link to="/" className="plain-link">Home</Link>
            </span>
        )
    }

    renderTabContent = (tabId: number, component: JSX.Element): JSX.Element => {
        if (this.state.activeTab === tabId) {
            return component;
        }

        return <></>;
    }

    render() {
        return (
            <Content title="Configuration" subtitle={this.renderBreadcrumb()}>
                <ContentCard className="ml-5 mr-5 mb-5" style={{minHeight: "calc(100vh - 26vh)"}}>
                    <Nav tabs className="mt-2 ml-2">
                        <TabLink id={1} title="Users" activeTab={this.state.activeTab} setActiveTab={this.setActiveTab} />
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId={1}>
                            {this.renderTabContent(1, <UsersTabContainer />)}
                        </TabPane>
                    </TabContent>
                </ContentCard>
            </Content>
        );
    }
}

export default ConfigManagementContainer;