import React from "react";
import {Plain2DPointType} from "../../models/Plain2DPoint";
import {
    cMetricQuery,
    mapMetricQueryFromJS,
    mapMetricQueryToJS,
    metricQueryDefault,
    MetricQueryInterface,
    MetricQueryType,
    metricValidationSchema
} from "../../models/MetricQuery";
import {cMetricReducer} from "../../api/MetricApi/constants";
import {connect} from "react-redux";
import {resetMetricValue} from "../../api/MetricApi/actions";
import {Button, Col, Form, Row} from "reactstrap";
import {cProjectTag, ProjectTagType} from "../../models/ProjectTag";
import {Pagination} from "../../models/Pagination";
import {cProjectTagReducer} from "../../api/ProjectTagApi/constants";
import {getAllProjectTagsByTaskId} from "../../api/ProjectTagApi/actions";
import {cPage, PageType} from "../../models/Page";
import {List} from "immutable";
import {RouteComponentProps, withRouter} from "react-router-dom";
import Content from "../../components/Content";
import ContentCard from "../../components/ContentCard";
import {PlotControlWrapper, PlotTypeSelectControl} from "../../components/PlotControl";
import {SuccessEventCallback} from "../../models/Event";
import {getVisualizationById, resetVisualizationValue, saveVisualization} from "../../api/VisualizationApi/actions";
import VisualizationSaveModal from "../../components/VisualizationSaveModal";
import {push} from "connected-react-router";
import {Formik, FormikProps} from "formik";
import {cVisualizationReducer} from "../../api/VisualizationApi/constants";
import NavigationBackwardLink from "../../components/NavigationBackwardLink";
import MetricPlot from "../MetricPlotContainer";

interface MetricsPlotBuilderContainerPropsInterface {
    projectTags: PageType<ProjectTagType>;
    visualization: MetricQueryType;
    lineMetrics: List<Plain2DPointType>;
    metricHistogram: number[];

    saveVisualization: (visualization: MetricQueryType, successCallbacks?: SuccessEventCallback<number>[]) => void;
    getVisualizationById: (id: number, successCallbacks?: SuccessEventCallback<MetricQueryInterface>[]) => void;
    getAllProjectTagsByTaskId: (taskId: string, page?: Pagination) => void;
    resetMetricValue: (field: string) => void;
    resetVisualizationValue: (field: string) => void;
}

interface MetricsPlotBuilderContainerStateInterface {
    id?: number;
    taskId?: string;
    isSaveModalOpen: boolean;
}

class MetricsPlotBuilderContainer extends React.PureComponent<RouteComponentProps & MetricsPlotBuilderContainerPropsInterface, MetricsPlotBuilderContainerStateInterface> {

    formikRef: React.RefObject<FormikProps<MetricQueryInterface>>;

    constructor(props: any) {
        super(props);
        const idParam = new URLSearchParams(this.props.match.params).get("id");

        const id = idParam ? parseInt(idParam) : undefined;
        const taskId = new URLSearchParams(this.props.match.params).get("taskId") ?? undefined;

        this.formikRef = React.createRef();

        this.state = {
            id,
            taskId,
            isSaveModalOpen: false
        };
    }

    componentDidMount() {
        if (this.formikRef.current) {
            if (this.state.taskId) {
                this.props.getAllProjectTagsByTaskId(this.state.taskId, {size: 5000});
                this.formikRef.current.setFieldValue(cMetricQuery.taskId, this.state.taskId);
            } else if (this.state.id) {
                this.props.getVisualizationById(
                    this.state.id,
                    [
                        (visualization) => getAllProjectTagsByTaskId(visualization?.[cMetricQuery.taskId] || "", {size: 5000}),
                    ]
                )
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<RouteComponentProps & MetricsPlotBuilderContainerPropsInterface>, prevState: Readonly<MetricsPlotBuilderContainerStateInterface>, snapshot?: any) {
        if (this.formikRef.current && this.state.id && this.formikRef.current.values[cMetricQuery.id] === null) {
            const visualization = mapMetricQueryToJS(this.props.visualization);
            this.formikRef.current.setValues(visualization);
        }
    }

    componentWillUnmount() {
        this.props.resetMetricValue(cMetricReducer.plain2DMetrics);
        this.props.resetMetricValue(cMetricReducer.metricHistogram);
        this.props.resetVisualizationValue(cVisualizationReducer.visualization);
    }

    handleGoBack = () => {
        if (this.props.history.action === "PUSH") {
            this.props.history.goBack();
        } else {
            let taskId;
            if (this.state.taskId) taskId = this.state.taskId;
            else taskId = this.props.visualization.get(cMetricQuery.taskId);
            this.props.history.push(`/task/${taskId}`);
        }
    }

    handleSaveModalToggle = () => {
        this.setState((prevState) => ({
            ...prevState,
            isSaveModalOpen: !prevState.isSaveModalOpen
        }));
    }

    getDataSeriesLabel = (tagId: number) => {
        return this.props.projectTags.get(cPage.content)
            .find(e => e.get(cProjectTag.id) === parseInt(tagId + ""))
            ?.get(cProjectTag.name) || "";
    }

    render() {
        return (
            <Content title="Create plot" subtitle={<NavigationBackwardLink subtitle="Plot builder" onGoBack={this.handleGoBack} />}>
                <Formik
                    initialValues={mapMetricQueryToJS(metricQueryDefault())}
                    validationSchema={metricValidationSchema}
                    validateOnChange={true}
                    validateOnBlur={true}
                    enableReinitialize={false}
                    innerRef={this.formikRef}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(true);
                        this.props.saveVisualization(
                            mapMetricQueryFromJS(values),
                            [() => push(`/task/${values[cMetricQuery.taskId]}`)]
                        );
                    }}
                >

                    {
                        ({values, errors, handleChange, handleSubmit, handleBlur, isSubmitting, setFieldValue}) => {
                            const handleQueryChange = (event: { target: { name: string; value?: any } }, refresh: boolean = true) => {
                                handleChange(event);
                            }

                            return (
                                <Row className="m-0 d-flex justify-content-start no-gutters">
                                    <Col xs={3}>
                                        <ContentCard className="ml-5" style={{height: "650px"}}>
                                            <Form className="m-4">
                                                <PlotTypeSelectControl
                                                    metricQuery={values}
                                                    errors={errors}
                                                    projectTags={this.props.projectTags.get(cPage.content)}
                                                    onQueryChange={handleQueryChange}
                                                />
                                                <PlotControlWrapper
                                                    metricQuery={values}
                                                    errors={errors}
                                                    projectTags={this.props.projectTags.get(cPage.content)}
                                                    onQueryChange={handleQueryChange}
                                                />
                                                <div className="d-flex justify-content-end">
                                                    <Button className="mr-2" onClick={this.handleGoBack}>Cancel</Button>
                                                    <Button color="primary"
                                                            onClick={this.handleSaveModalToggle}>Save</Button>
                                                </div>
                                                {
                                                    this.state.isSaveModalOpen
                                                        ? <VisualizationSaveModal
                                                            isOpen={this.state.isSaveModalOpen}
                                                            visualization={values}
                                                            onToggle={this.handleSaveModalToggle}
                                                            onValueChange={(e) => handleQueryChange(e, false)}
                                                            onSaveVisualization={handleSubmit}
                                                            isSubmitting={isSubmitting}
                                                        />
                                                        : null
                                                }
                                            </Form>
                                        </ContentCard>
                                    </Col>
                                    <Col xs={9}>
                                        <ContentCard className="ml-4 mr-5" style={{height: "650px"}}>
                                            <div className="h-100">
                                                <MetricPlot
                                                    metricQuery={values}
                                                    dataSeriesLabel={this.getDataSeriesLabel(values[cMetricQuery.tagId])}
                                                />
                                            </div>
                                        </ContentCard>
                                    </Col>
                                </Row>
                            );
                        }
                    }
                </Formik>
            </Content>
        );
    }
}

const mapStateToProps = (state: any) => ({
    projectTags: state.projectTagApiReducer.get(cProjectTagReducer.projectTags),
    visualization: state.visualizationApiReducer.get(cVisualizationReducer.visualization),
    lineMetrics: state.metricApiReducer.get(cMetricReducer.plain2DMetrics),
    metricHistogram: state.metricApiReducer.get(cMetricReducer.metricHistogram)
});

const mapDispatchToProps = (dispatch: any) => ({
    saveVisualization: (visualization: MetricQueryType, callbacks?: SuccessEventCallback<number>[]) => dispatch(saveVisualization(
        visualization,
        {onSuccess: callbacks}
    )),
    getVisualizationById: (id: number, successCallbacks?: SuccessEventCallback<MetricQueryInterface>[]) => dispatch(getVisualizationById(
        id,
        {onSuccess: successCallbacks}
        )),
    getAllProjectTagsByTaskId: (taskId: string, page?: Pagination) => dispatch(getAllProjectTagsByTaskId(taskId, page)),
    resetMetricValue: (field: string) => dispatch(resetMetricValue(field)),
    resetVisualizationValue: (field: string) => dispatch(resetVisualizationValue(field))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)
(withRouter(MetricsPlotBuilderContainer));