import {all, call, put, takeLatest} from "redux-saga/effects";
import {
    PROJECT_TAG_DELETE_BY_ID,
    PROJECT_TAG_GET_ALL_BY_PROJECT_ID,
    PROJECT_TAG_GET_ALL_BY_TASK_ID,
    PROJECT_TAG_GET_BY_ID,
    PROJECT_TAG_SAVE
} from "./constants";
import {
    DeleteProjectTagByIdActionInterface,
    GetAllProjectTagsByProjectIdActionInterface,
    GetAllProjectTagsByTaskIdActionInterface,
    GetProjectTagByIdActionInterface,
    SaveProjectTagActionInterface
} from "./actions";
import {Network} from "../../internal/network";
import {cProjectTag, mapProjectTagFromJS, mapProjectTagToJS, ProjectTagInterface} from "../../models/ProjectTag";
import Links from "../../global/links";
import {Actions} from "../../global/actions";
import {Alert} from "../../containers/AlertContainer/actions";
import {Callback} from "../../models/Event";
import {mapPageFromJS, PageInterface} from "../../models/Page";
import {getExceptionMessage} from "../../models/ExceptionCode";

function* save(action: SaveProjectTagActionInterface) {
    try {
        let projectTagId = action.projectTag.get(cProjectTag.id);
        const payload = {body: JSON.stringify(mapProjectTagToJS(action.projectTag))};
        if (projectTagId) {
            yield call(Network.put(Links.projectTag(), payload));
        } else {
            projectTagId = yield call(Network.post(Links.projectTag(), payload));
        }
        yield put(Actions.fulfilled(action.type, projectTagId));
        yield put(Alert.success("Project tag has been successfully saved."))
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* deleteById(action: DeleteProjectTagByIdActionInterface) {
    try {
        yield call(Network.del(Links.projectTagById(action.id)));
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("Project tag has been successfully deleted."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* getById(action: GetProjectTagByIdActionInterface) {
    try {
        const projectTag: ProjectTagInterface = yield call(Network.get(Links.projectTagById(action.id)));
        yield put(Actions.fulfilled(action.type, mapProjectTagFromJS(projectTag)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

function* getAllByProjectId(action: GetAllProjectTagsByProjectIdActionInterface) {
    try {
        const projectTags: PageInterface<ProjectTagInterface> = yield call(Network.get(Links.projectTagsByProjectId(action.projectId, action.page?.page, action.page?.size)));
        yield put(Actions.fulfilled(action.type, mapPageFromJS(projectTags, mapProjectTagFromJS)))
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

function* getAllByTaskId(action: GetAllProjectTagsByTaskIdActionInterface) {
    try {
        const projectTags: PageInterface<ProjectTagInterface> = yield call(Network.get(Links.projectTagsByTaskId(action.taskId, action.page?.page, action.page?.size)));
        yield put(Actions.fulfilled(action.type, mapPageFromJS(projectTags, mapProjectTagFromJS)))
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

export default function* saga() {
    yield all([
        yield takeLatest(PROJECT_TAG_SAVE, save),
        yield takeLatest(PROJECT_TAG_DELETE_BY_ID, deleteById),
        yield takeLatest(PROJECT_TAG_GET_BY_ID, getById),
        yield takeLatest(PROJECT_TAG_GET_ALL_BY_PROJECT_ID, getAllByProjectId),
        yield takeLatest(PROJECT_TAG_GET_ALL_BY_TASK_ID, getAllByTaskId)
    ])
}