export enum cSshCredentials {
    key = "key",
    username = "username",
    passphrase = "passphrase"
}

export interface SshCredentialsInterface {
    [cSshCredentials.key]?: string;
    [cSshCredentials.username]?: string;
    [cSshCredentials.passphrase]?: string;
}