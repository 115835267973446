import {
    cFileReducer,
    FILE_GET_ALL_BY_PROJECT_ID,
    FILE_GET_ALL_BY_PROJECT_ID_AND_SEARCH_STRING,
    FILE_GET_ALL_PROJECT_FILES_BY_TASK_ID_AND_SEARCH_STRING,
    FILE_GET_BY_ID,
    FILE_RESET_VALUE
} from "./constants";
import {pageDefault, PageType} from "../../models/Page";
import {Immutable} from "../../models/Immutable";
import {genericFileDefault, GenericFileType} from "../../models/GenericFile";
import {FULFILLED} from "../../global/constants";

interface InitialStateInterface {
    [cFileReducer.file]: GenericFileType;
    [cFileReducer.files]: PageType<GenericFileType>;
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
    [cFileReducer.file]: genericFileDefault(),
    [cFileReducer.files]: pageDefault<GenericFileType>()
})

const reducer = (state: InitialStateType = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case `${FILE_GET_BY_ID}${FULFILLED}`: {
            return state.set(cFileReducer.file, action.payload);
        }
        case `${FILE_GET_ALL_BY_PROJECT_ID}${FULFILLED}`: {
            return state.set(cFileReducer.files, action.payload);
        }
        case `${FILE_GET_ALL_PROJECT_FILES_BY_TASK_ID_AND_SEARCH_STRING}${FULFILLED}`: {
            return state.set(cFileReducer.files, action.payload);
        }
        case `${FILE_GET_ALL_BY_PROJECT_ID_AND_SEARCH_STRING}${FULFILLED}`: {
            return state.set(cFileReducer.files, action.payload);
        }
        case FILE_RESET_VALUE: {
            return state.set(action.field, initialState.get(action.field));
        }
        default:
            return state;
    }
}

export default reducer;