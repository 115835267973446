import {cModalReducer, MODAL_TOGGLE} from "./constants";
import {Immutable} from "../../models/Immutable";

interface InitialStateInterface {
    [cModalReducer.isModalOpen]: boolean;
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
    [cModalReducer.isModalOpen]: false
});

const reducer = (state: InitialStateType = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case MODAL_TOGGLE: {
            return state.set(cModalReducer.isModalOpen, !state.get(cModalReducer.isModalOpen));
        }
        default:
            return state;
    }
}

export default reducer;