import {all, call, put, takeLatest} from "redux-saga/effects";
import {
    DeleteProjectAssignmentByIdActionInterface,
    GetAllProjectAssignmentsByProjectIdActionInterface,
    SaveAllProjectAssignmentsActionInterface
} from "./actions";
import {
    PROJECT_ASSIGNMENT_DELETE_BY_ID,
    PROJECT_ASSIGNMENT_GET_BY_PROJECT_ID,
    PROJECT_ASSIGNMENT_SAVE_ALL
} from "./constatns";
import {Network} from "../../internal/network";
import Links from "../../global/links";
import {Actions} from "../../global/actions";
import {mapPageFromJS, PageInterface} from "../../models/Page";
import {
    mapProjectAssignmentFromJS,
    mapProjectAssignmentToJS,
    ProjectAssignmentInterface
} from "../../models/ProjectAssignment";
import {Callback} from "../../models/Event";
import {Alert} from "../../containers/AlertContainer/actions";
import {getExceptionMessage} from "../../models/ExceptionCode";

function* saveAll(action: SaveAllProjectAssignmentsActionInterface) {
    try {
        const payload = {body: JSON.stringify(action.projectAssignments.map(mapProjectAssignmentToJS))};
        yield call(Network.post(Links.saveAllProjectAssignments(), payload));
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("All changes have been saved."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* deleteById(action: DeleteProjectAssignmentByIdActionInterface) {
    try {
        yield call(Network.del(Links.projectAssignmentById(action.id)));
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("Student has been successfully removed from project."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* getAllByProjectId(action: GetAllProjectAssignmentsByProjectIdActionInterface) {
    try {
        const projectAssignments: PageInterface<ProjectAssignmentInterface> =
            yield call(Network.get(Links.projectAssignmentByProjectId(action.projectId, action.page?.page, action.page?.size)));
        yield put(Actions.fulfilled(action.type, mapPageFromJS(projectAssignments, mapProjectAssignmentFromJS)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

export default function* saga() {
    yield all([
        yield takeLatest(PROJECT_ASSIGNMENT_SAVE_ALL, saveAll),
        yield takeLatest(PROJECT_ASSIGNMENT_DELETE_BY_ID, deleteById),
        yield takeLatest(PROJECT_ASSIGNMENT_GET_BY_PROJECT_ID, getAllByProjectId)
    ])
}