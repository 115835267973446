import React from "react";
import "./style.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faFolder, faHome, faInfo} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import ProtectedContainer from "../../containers/ProtectedContainer";
import {Role} from "../../models/Role";

const SidebarNavigation = (props: any) => (
    <React.Fragment>
        <div className="sidebar-container">
            <div className="sidebar-navigation">
                <ul className="sidebar-navigation">
                    <li>
                        <Link to="/">
                            <FontAwesomeIcon className="sidebar-item-logo" icon={faHome} aria-hidden />
                            Homepage
                        </Link>
                    </li>
                    <li>
                        <Link to="/project-list">
                            <FontAwesomeIcon className="sidebar-item-logo" icon={faFolder} aria-hidden />
                            Projects
                        </Link>
                    </li>
                    <ProtectedContainer requiredRole={Role.ADMIN}>
                        <li>
                            <Link to="/configuration">
                                <FontAwesomeIcon className="sidebar-item-logo" icon={faCog} aria-hidden/>
                                Settings
                            </Link>
                        </li>
                    </ProtectedContainer>
                    <li>
                        <Link to="/about">
                            <FontAwesomeIcon className="sidebar-item-logo" icon={faInfo} aria-hidden />
                            About
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
        <div className="content-container">
            {props.children}
        </div>
    </React.Fragment>
)

export const withSidebarNavigation = (WrappedComponent: any) => {
    return (props: any) => (<SidebarNavigation><WrappedComponent {...props} /></SidebarNavigation>)
};

export default SidebarNavigation;