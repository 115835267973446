import React from "react";
import DatePicker from "react-datepicker";
import {CustomInput, FormFeedback, FormGroup, Input, InputGroup, Label} from "reactstrap";
import {
    ChartType,
    cMetricQuery,
    MetricAggregationField,
    MetricQueryInterface,
    TimeUnit
} from "../../models/MetricQuery";
import {capitalize} from "../../global/utils";
import {cProjectTag, ProjectTagType} from "../../models/ProjectTag";
import moment from "moment";
import {DATE_TIME_WITHOUT_TIMEZONE_FORMAT} from "../../global/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FormikErrors, FormikValues} from "formik";

interface FormControlPropsInterface {
    metricQuery: MetricQueryInterface,
    errors: FormikErrors<FormikValues>,
    projectTags: ProjectTagType[],
    onQueryChange: (event: {target: {name: string; value?: any}}) => void;
}

export const PlotControlWrapper = (props: FormControlPropsInterface) => {
    switch (props.metricQuery[cMetricQuery.chartType]) {
        case ChartType.LINE:
            return (
                <ScatterPlotControl {...props} />
            )
        case ChartType.BAR:
            return (
                <BarPlotControl {...props} />
            )
        case ChartType.HISTOGRAM:
            return (
                <HistogramPlotControl {...props} />
            )
        default:
            return (<></>)
    }
}

export const ScatterPlotControl = (props: FormControlPropsInterface): JSX.Element => {
    let controlComponent = null;

    switch (props.metricQuery[cMetricQuery.groupBy]) {
        case MetricAggregationField.TIME:
            controlComponent = (
                <>
                    <BucketPlotControl {...props} />
                    <TimeRangeSelectPlotControl {...props} />
                </>
            );break;
        case MetricAggregationField.EPOCH:
            controlComponent = (
                <EpochRangeFormControl {...props} />
            );break;
    }

    return (
        <>
            <CoordinatesPlotControl {...props} />
            {controlComponent}
        </>
    )
}

export const BarPlotControl = (props: FormControlPropsInterface): JSX.Element => <ScatterPlotControl {...props} />

export const HistogramPlotControl = (props: FormControlPropsInterface): JSX.Element => {
    return (
        <>
            <MetricPlotControl {...props} />
            <FormGroup>
                <Label for="fromField" size="sm">Range</Label>
                <InputGroup>
                    <Input id="fromField" name="histogramFrom" type="number" bsSize="sm" invalid={!!props.errors[cMetricQuery.histogramFrom]}
                           value={props.metricQuery[cMetricQuery.histogramFrom]}
                           onChange={props.onQueryChange}
                    />
                    <FormFeedback>{props.errors[cMetricQuery.histogramFrom]}</FormFeedback>
                    <Input
                        id="toField" name="histogramTo" type="number" bsSize="sm" invalid={!!props.errors[cMetricQuery.histogramTo]}
                        value={props.metricQuery[cMetricQuery.histogramTo]}
                        onChange={props.onQueryChange}
                    />
                    <FormFeedback>{props.errors[cMetricQuery.histogramTo]}</FormFeedback>
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <Label for="bucketCountField" size="sm">Bucket count</Label>
                <Input id="bucketCountField" name="histogramBucketCount" type="number" bsSize="sm" invalid={!!props.errors[cMetricQuery.histogramBucketCount]}
                        value={props.metricQuery[cMetricQuery.histogramBucketCount]}
                       onChange={props.onQueryChange}
                />
                <FormFeedback>{props.errors[cMetricQuery.histogramBucketCount]}</FormFeedback>
            </FormGroup>
        </>
    )
}

export const PlotTypeSelectControl = (props: FormControlPropsInterface): JSX.Element => {
    return (
        <FormGroup>
            <Label for="chartTypeField" size="sm">Chart type</Label>
            <CustomInput id="chartTypeField" name="chartType" type="select" bsSize="sm"
                         value={props.metricQuery[cMetricQuery.chartType]}
                         onChange={props.onQueryChange}>
                {Object.values(ChartType).map(e => <option key={e}
                                                           value={e}>{capitalize(e.toLowerCase())}</option>)}
            </CustomInput>
        </FormGroup>
    )
}

export const CoordinatesPlotControl = (props: FormControlPropsInterface): JSX.Element => {
    return (
        <>
            <FormGroup>
                <Label for="xAxisField" size="sm">X-Axis</Label>
                <CustomInput id="xAxisField" name="groupBy" type="select" bsSize="sm"
                             value={props.metricQuery[cMetricQuery.groupBy]}
                             onChange={props.onQueryChange}>
                    {Object.values(MetricAggregationField).map(e => <option key={e}
                                                                            value={e}>{capitalize(e.toLowerCase())}</option>)}
                </CustomInput>
            </FormGroup>
            <FormGroup>
                <Label for="yAxisField" size="sm">Y-Axis</Label>
                <CustomInput id="yAxisField" name="tagId" type="select" bsSize="sm"
                             value={props.metricQuery[cMetricQuery.tagId]}
                             onChange={props.onQueryChange}>
                    <option key="none" value={undefined}/>
                    {
                        props.projectTags
                            .map(e =>
                                <option key={e.get(cProjectTag.id)} value={e.get(cProjectTag.id)}>
                                    {e.get(cProjectTag.name)}
                                </option>)
                    }
                </CustomInput>
            </FormGroup>
        </>
    )
}

export const BucketPlotControl = (props: FormControlPropsInterface): JSX.Element => {
    return (
        <FormGroup>
            <Label for="bucketWidthField" size="sm">Time bucket width</Label>
            <InputGroup>
                <Input id="bucketWidthField" name="bucketWidth" type="number" bsSize="sm"
                       value={props.metricQuery[cMetricQuery.bucketWidth]}
                       onChange={props.onQueryChange}
                       invalid={!!props.errors[cMetricQuery.bucketWidth]}
                />
                <CustomInput id="unit" name="timeUnit" type="select" bsSize="sm"
                             value={props.metricQuery[cMetricQuery.timeUnit]}
                             onChange={props.onQueryChange}>
                    {Object.values(TimeUnit).map(e => <option key={e}
                                                              value={e}>{e.toLowerCase()}</option>)}
                </CustomInput>
                <FormFeedback>{props.errors[cMetricQuery.bucketWidth]}</FormFeedback>
            </InputGroup>
        </FormGroup>
    )
}

export const MetricPlotControl = (props: FormControlPropsInterface): JSX.Element => {
    return (
        <FormGroup>
            <Label for="metricField" size="sm">Metric</Label>
            <CustomInput id="metricField" name="tagId" type="select" bsSize="sm"
                         value={props.metricQuery[cMetricQuery.tagId]}
                         onChange={props.onQueryChange}>
                <option key="none" value={undefined}/>
                {
                    props.projectTags
                        .map(e =>
                            <option key={e.get(cProjectTag.id)} value={e.get(cProjectTag.id)}>
                                {e.get(cProjectTag.name)}
                            </option>)
                }
            </CustomInput>
        </FormGroup>
    )
}

export const TimeRangeSelectPlotControl = (props: FormControlPropsInterface): JSX.Element => {
    const {timeFrom, timeTo} = props.metricQuery;
    return (
        <>
            <FormGroup>
                <Label for="timeFromField" size="sm">Time from</Label>
                <InputGroup>
                    <DatePicker
                        id="timeFromField"
                        name="timeFrom"
                        showTimeInput
                        wrapperClassName="form-control"
                        placeholderText={moment("2020-06-30T07:00:00Z").format(DATE_TIME_WITHOUT_TIMEZONE_FORMAT)}
                        customInput={<Input id="timeFromField" name="timeFrom" type="datetime" bsSize="sm"/>}
                        selected={timeFrom ? new Date(timeFrom) : undefined}
                        dateFormat={"yyyy-MM-dd HH:mm:ss"}
                        maxDate={new Date(timeTo || "")}
                        onChange={(date: Date) => props.onQueryChange({
                            target: {
                                name: "timeFrom",
                                value: date.toISOString()
                            }
                        })}
                    />
                    <FontAwesomeIcon className="m-2 h-100 cursor-pointer" color="gray" icon={faTimes} onClick={() => props.onQueryChange({
                        target: {
                            name: "timeFrom",
                            value: undefined
                        }
                    })}/>
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <Label for="timeToField" size="sm">Time to</Label>
                <InputGroup>
                    <DatePicker
                        id="timeToField"
                        name="timeTo"
                        showTimeInput
                        wrapperClassName="form-control"
                        placeholderText={moment("2020-06-30T07:00:00Z").format(DATE_TIME_WITHOUT_TIMEZONE_FORMAT)}
                        customInput={<Input id="timeToField" name="timeTo" type="datetime" bsSize="sm"/>}
                        selected={timeTo ? new Date(timeTo) : undefined}
                        dateFormat={"yyyy-MM-dd HH:mm:ss"}
                        minDate={new Date(timeFrom || "")}
                        onChange={(date: Date) => props.onQueryChange({
                            target: {
                                name: "timeTo",
                                value: date.toISOString()
                            }
                        })}
                    />
                    <FontAwesomeIcon className="m-2 h-100 cursor-pointer" color="gray" icon={faTimes} onClick={() => props.onQueryChange({
                        target: {
                            name: "timeTo",
                            value: undefined
                        }
                    })}/>
                </InputGroup>
            </FormGroup>
        </>
    )
}

export const EpochRangeFormControl = (props: FormControlPropsInterface): JSX.Element => {
    return (
        <>
          <FormGroup>
              <Label for="epochFromField">Epoch range</Label>
              <InputGroup>
                  <Input id="epochFromField" name="epochFrom" type="number" bsSize="sm"
                         value={props.metricQuery[cMetricQuery.epochFrom] || 0} onChange={props.onQueryChange} invalid={!!props.errors[cMetricQuery.epochFrom]} />
                  <FormFeedback>{props.errors[cMetricQuery.epochFrom]}</FormFeedback>
                  <Input id="epochToField" name="epochTo" type="number" bsSize="sm"
                         value={props.metricQuery[cMetricQuery.epochTo] || 0} onChange={props.onQueryChange} invalid={!!props.errors[cMetricQuery.epochTo]}/>
                  <FormFeedback>{props.errors[cMetricQuery.epochTo]}</FormFeedback>
              </InputGroup>
          </FormGroup>
        </>
    )
}