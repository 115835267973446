import React from "react";
import {
    cGenericFile,
    GenericFileType,
    genericFileValidationSchema,
    mapGenericFileFromJS
} from "../../models/GenericFile";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import {Formik} from "formik";

interface ProjectFileFormModalPropsInterface {
    projectId: string;
    className?: string;

    isOpen: boolean;
    file: GenericFileType;
    fileContent?: File;

    onToggle: () => void;
    onFileSave: (file: GenericFileType, fileContent?: File) => void;
}

class ProjectFileFormModal extends React.PureComponent<ProjectFileFormModalPropsInterface> {

    initFileName = (file: GenericFileType): GenericFileType => {
        if (this.props.fileContent && file.get(cGenericFile.id) === null) {
            return file.set(cGenericFile.name, this.props.fileContent.name);
        }
        return file;
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle} className={this.props.className}>
                <Formik
                    initialValues={this.initFileName(this.props.file).toJS()}
                    validationSchema={genericFileValidationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize
                    onSubmit={(values, {setSubmitting}) => {
                        let file = mapGenericFileFromJS(values);
                        if (file.get(cGenericFile.id)) {
                            file = file.withMutations((f: GenericFileType) => {
                                f.set(cGenericFile.projectId, null)
                                    .set(cGenericFile.size, null)
                                    .set(cGenericFile.createdOn, null);
                            })
                        } else {
                            file = file.set(cGenericFile.projectId, this.props.projectId);
                        }
                        this.props.onFileSave(file, this.props.fileContent);
                    }}
                >
                    {
                        ({values, errors, handleChange, handleSubmit, handleBlur, isSubmitting, setFieldValue}) => (
                            <React.Fragment>
                                <Form onSubmit={handleSubmit}>
                                    <ModalHeader>{this.props.file.get(cGenericFile.id) ? "Edit file" : "Add file"}</ModalHeader>
                                    <ModalBody>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="nameField">Name</Label>
                                                    <Input
                                                        id="nameField"
                                                        name="name"
                                                        placeholder="File name"
                                                        value={values[cGenericFile.name]}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={!!errors[cGenericFile.name]}
                                                    />
                                                    <FormFeedback>{errors[cGenericFile.name]}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="descriptionField">Description</Label>
                                                    <Input
                                                        id="descriptionField"
                                                        name="description"
                                                        type="textarea"
                                                        placeholder="File description"
                                                        value={values[cGenericFile.description]}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        invalid={!!errors[cGenericFile.description]}
                                                    />
                                                    <FormFeedback>{errors[cGenericFile.description]}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button onClick={this.props.onToggle}>Cancel</Button>
                                        <Button color="primary" type="submit" disabled={isSubmitting}>Save</Button>
                                    </ModalFooter>
                                </Form>
                            </React.Fragment>
                        )
                    }
                </Formik>
            </Modal>
        );
    }
}

export default ProjectFileFormModal;