import {all} from 'redux-saga/effects';
import AuthApiSaga from "./api/AuthApi/saga";
import DashboardApiSaga from "./api/DashboardApi/saga";
import DashboardItemApiSaga from "./api/DashboardItemApi/saga";
import FileApiSaga from "./api/FileApi/saga";
import InputProcessingRequestApiSaga from "./api/InputProcessingRequestApi/saga";
import MetricApiSaga from "./api/MetricApi/saga";
import ParameterApiSaga from "./api/ParameterApi/saga";
import ProjectApiSaga from "./api/ProjectApi/saga";
import ProjectAssignmentSaga from "./api/ProjectAssignmentApi/saga";
import ProjectTagSaga from "./api/ProjectTagApi/saga";
import TaskSaga from "./api/TaskApi/saga";
import TaskFileSaga from "./api/TaskFileApi/saga";
import UserSaga from "./api/UserApi/saga";
import VisualizationSaga from "./api/VisualizationApi/saga";
import DashboardContainerSaga from "./containers/DashboardContainer/saga";
import ProjectStudentsTabContainerSaga from "./containers/ProjectStudentsTabContainer/saga";
import ProjectTasksTabContainerSaga from "./containers/ProjectTasksTabContainer/saga";
import TaskFilesTabContainerSaga from "./containers/TaskFilesTabContainer/saga";
import TaskFormContainerSaga from "./containers/TaskFormContainer/saga";
import AlertContainerSaga from "./containers/AlertContainer/saga";

export default function* rootSaga() {
    yield all([
        AuthApiSaga(),
        DashboardApiSaga(),
        DashboardItemApiSaga(),
        FileApiSaga(),
        InputProcessingRequestApiSaga(),
        MetricApiSaga(),
        ParameterApiSaga(),
        ProjectApiSaga(),
        ProjectAssignmentSaga(),
        ProjectTagSaga(),
        TaskSaga(),
        TaskFileSaga(),
        UserSaga(),
        VisualizationSaga(),
        DashboardContainerSaga(),
        ProjectStudentsTabContainerSaga(),
        ProjectTasksTabContainerSaga(),
        TaskFilesTabContainerSaga(),
        TaskFormContainerSaga(),
        AlertContainerSaga()
    ])
}