import {all, call, debounce, put, takeLatest} from "redux-saga/effects";
import {
    FILE_DELETE_BY_ID,
    FILE_DOWNLOAD_BY_ID,
    FILE_GET_ALL_BY_PROJECT_ID,
    FILE_GET_ALL_BY_PROJECT_ID_AND_SEARCH_STRING,
    FILE_GET_ALL_PROJECT_FILES_BY_TASK_ID_AND_SEARCH_STRING,
    FILE_GET_BY_ID,
    FILE_SAVE,
    FILE_SEARCH_PROJECT_FILES
} from "./constants";
import {
    DeleteFileByIdActionInterface,
    DownloadFileByIdActionInterface,
    GetAllFilesByProjectIdActionInterface,
    GetAllFilesByProjectIdAndSearchStringActionInterface,
    getAllProjectFilesByTaskIdAndSearchString as getAllFilesByTaskIdAndSearchString,
    GetAllProjectFilesByTaskIdAndSearchStringActionInterface,
    GetFileByIdActionInterface,
    SaveFileActionInterface,
    SearchProjectFilesByTaskIdAndSearchStringActionInterface
} from "./actions";
import {Network} from "../../internal/network";
import Links from "../../global/links";
import {Actions} from "../../global/actions";
import {cGenericFile, GenericFileInterface, mapGenericFileFromJS, mapGenericFileToJS} from "../../models/GenericFile";
import {Alert} from "../../containers/AlertContainer/actions";
import {mapPageFromJS, PageInterface} from "../../models/Page";
import {Callback} from "../../models/Event";
import {saveAs} from "file-saver";
import {getExceptionMessage} from "../../models/ExceptionCode";

function* save(action: SaveFileActionInterface) {
    try {
        let fileId: string | undefined = action.file.get(cGenericFile.id);
        if (fileId) {
            const payload = {body: JSON.stringify(mapGenericFileToJS(action.file))};
            yield call(Network.put(Links.file(), payload));
        } else {
            yield put(Alert.success("Upload is in progress. Please wait..."));
            const file = action.file;
            const fileContent = action.fileContent;
            const projectId = file.get(cGenericFile.projectId);
            const name = file.get(cGenericFile.name);
            const description = file.get(cGenericFile.description);

            const formData = new FormData();
            formData.append(cGenericFile.name, name);
            if (description) formData.append(cGenericFile.description, description);
            formData.append(cGenericFile.projectId, projectId);
            if (fileContent) formData.append("file", fileContent);

            const payload = {body: formData};
            fileId = yield call(Network.postRaw(Links.file(), payload));
        }
        yield put(Actions.fulfilled(action.type, fileId));
        yield put(Alert.success("File has been successfully saved."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* getById(action: GetFileByIdActionInterface) {
    try {
        const file: GenericFileInterface = yield call(Network.get(Links.fileById(action.fileId)));
        yield put(Actions.fulfilled(action.type, mapGenericFileFromJS(file)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

function* deleteById(action: DeleteFileByIdActionInterface) {
    try {
        yield call(Network.del(Links.fileById(action.fileId)));
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("File has been successfully deleted."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* getAllByProjectId(action: GetAllFilesByProjectIdActionInterface) {
    try {
        const files: PageInterface<GenericFileInterface> = yield call(Network.get(Links.filesByProjectId(action.projectId, action.page?.page, action.page?.size)));
        yield put(Actions.fulfilled(action.type, mapPageFromJS(files, mapGenericFileFromJS)));
    } catch (e) {
        yield put(Actions.rejected(action.type));
        yield put(Alert.error());
    }
}

function* getAllByProjectIdAndSearchString(action: GetAllFilesByProjectIdAndSearchStringActionInterface) {
    try {
        const files: PageInterface<GenericFileInterface> = yield call(Network.get(Links.filesByProjectIdAndSearchString(action.projectId, action.searchString, action.page?.page, action.page?.size)));
        yield put(Actions.fulfilled(action.type, mapPageFromJS(files, mapGenericFileFromJS)));
    } catch (e) {
        yield put(Actions.rejected(action.type));
        yield put(Alert.error());
    }
}

function* getAllProjectFilesByTaskIdAndSearchString(action: GetAllProjectFilesByTaskIdAndSearchStringActionInterface) {
    try {
        const files: PageInterface<GenericFileInterface> = yield call(Network.get(Links.projectFilesByTaskIdAndSearchString(action.taskId, action.searchString, action.page?.page, action.page?.size)));
        yield put(Actions.fulfilled(action.type, mapPageFromJS(files, mapGenericFileFromJS)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

function* searchProjectFilesByTaskIdAndSearchString(action: SearchProjectFilesByTaskIdAndSearchStringActionInterface) {
    yield put(getAllFilesByTaskIdAndSearchString(action.taskId, action.searchString, action.page))
}

function* downloadFileById(action: DownloadFileByIdActionInterface) {
    try {
        yield put(Alert.success("Processing request. This may take while..."));
        const response: Blob = yield call(Network.download(Links.fileStreamById(action.id)));
        yield put(Actions.fulfilled(action.type));
        saveAs(response, action.filename);
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

export default function* saga() {
    yield all([
        yield takeLatest(FILE_SAVE, save),
        yield takeLatest(FILE_GET_BY_ID, getById),
        yield takeLatest(FILE_DELETE_BY_ID, deleteById),
        yield takeLatest(FILE_GET_ALL_BY_PROJECT_ID, getAllByProjectId),
        yield takeLatest(FILE_GET_ALL_BY_PROJECT_ID_AND_SEARCH_STRING, getAllByProjectIdAndSearchString),
        yield takeLatest(FILE_GET_ALL_PROJECT_FILES_BY_TASK_ID_AND_SEARCH_STRING, getAllProjectFilesByTaskIdAndSearchString),
        yield debounce(1000, FILE_SEARCH_PROJECT_FILES, searchProjectFilesByTaskIdAndSearchString),
        yield takeLatest(FILE_DOWNLOAD_BY_ID, downloadFileById)
    ])
}