import {all, call, cancel, delay, fork, put, take, takeLatest} from "redux-saga/effects";
import {DownloadProcessingRequestResultActionInterface} from "./actions";
import {
    PROJECT_TASKS_TAB_CONTAINER_CANCEL_REQUEST_RESULT_DOWNLOAD,
    PROJECT_TASKS_TAB_CONTAINER_DOWNLOAD_REQUEST_RESULT
} from "./constants";
import {Network} from "../../internal/network";
import Links from "../../global/links";
import {PageInterface} from "../../models/Page";
import {GenericFileInterface} from "../../models/GenericFile";
import {downloadFileById} from "../../api/FileApi/actions";
import {Alert} from "../AlertContainer/actions";
import {Actions} from "../../global/actions";

function* downloadProcessingRequestResult(action: DownloadProcessingRequestResultActionInterface) {
    try {
        const searchString = "description:" + action.requestId + ",";
        let files: PageInterface<GenericFileInterface>;
        do {
            files = yield call(Network.get(Links.filesByProjectIdAndSearchString(action.projectId, searchString, 0, 1)));
            console.log(files, files.size);
            yield delay(5000);
        } while (files && files.numberOfElements === 0);

        const file = files.content.pop();
        if (file && file.id) {
            yield put(downloadFileById(file.id, file.name));
        }
        yield put(Actions.fulfilled(action.type));
    } catch (e) {
        yield put(Actions.rejected(action.type));
        yield put(Alert.error());
    }
}

function* watchDownloadProcessingRequestResult(action: DownloadProcessingRequestResultActionInterface) {
    const task = yield fork(() => downloadProcessingRequestResult(action))
    yield take(PROJECT_TASKS_TAB_CONTAINER_CANCEL_REQUEST_RESULT_DOWNLOAD);
    yield cancel(task);
}

export default function* saga() {
    yield all(
        yield takeLatest(PROJECT_TASKS_TAB_CONTAINER_DOWNLOAD_REQUEST_RESULT, watchDownloadProcessingRequestResult)
    )
}