import {getToken, handleRedirectToAuthServiceLogin} from "../api/AuthApi/utils";

enum HttpMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE"
}

const defaultHeaders = {
    "Content-Type": "application/json"
};

export const request = (url: string, args: RequestInit = {}) => async () => {
    const response: Response = await fetch(url, args);
    const text: string = await response.text();
    const json = text.length ? JSON.parse(text) : {};

    if (response.status > 300 && response.status < 600) {
        if (response.status === 401) {
            handleRedirectToAuthServiceLogin();
        }
        throw Error(json.exceptionCode);
    }

    return json;
}

export const downloadRequest = (url: string, args: RequestInit = {}) => async () => {
    const response: Response = await fetch(url, args);
    const blob: Blob = await response.blob();

    if (response.status > 300 && response.status < 600) {
        if (response.status === 401) {
            handleRedirectToAuthServiceLogin();
        }
        throw Error();
    }

    return blob;
}

const withDefaultHeaders = (args: any) => ({
    ...args,
    headers: {
        ...defaultHeaders,
        ...args.headers
    }
});

const withAuthHeaders = (args: any) => ({
    ...args,
    headers: {
        "Authorization": "Bearer " + getToken().access_token,
        ...args.headers
    }
});

export const get = (url: string, args = {}) => request(url, withAuthHeaders({method: HttpMethod.GET, ...args}));
export const post = (url: string, args = {}) => request(url, withAuthHeaders(withDefaultHeaders({method: HttpMethod.POST, ...args})));
export const postRaw = (url: string, args = {}) => request(url, withAuthHeaders({method: HttpMethod.POST, ...args}));
export const put = (url: string, args = {}) => request(url, withAuthHeaders(withDefaultHeaders({method: HttpMethod.PUT, ...args})));
export const del = (url: string, args = {}) => request(url, withAuthHeaders({method: HttpMethod.DELETE, ...args}));
export const download = (url: string, args = {}) => downloadRequest(url, withAuthHeaders({method: HttpMethod.GET, ...args}))

export const Network = {
    get, post, postRaw, put, del, download
}