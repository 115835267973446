import React from "react";
import {Button} from "reactstrap";
import ContentCard from "../../components/ContentCard";
import Content from "../../components/Content";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {cProjectReducer} from "../../api/ProjectApi/constants";
import {cPage, PageType} from "../../models/Page";
import {cProject, ProjectType} from "../../models/Project";
import {connect} from "react-redux";
import {getAllOwnAssignedProjects, getAllProjects} from "../../api/ProjectApi/actions";
import PaginationControl from "../../components/PaginationControl";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {Pagination} from "../../models/Pagination";
import TableView, {ColumnDefinition} from "../../components/TableView";
import {cUser, UserType} from "../../models/User";
import {cAuthReducer} from "../../api/AuthApi/constants";
import {Role} from "../../models/Role";
import ProtectedContainer from "../ProtectedContainer";

interface ProjectListContainerPropsInterface {
    projects: PageType<ProjectType>;
    currentUser: UserType;
    getAllProjects: (page?: Pagination) => any;
    getAllOwnAssignedProjects: (page?: Pagination) => any;
}

class ProjectListContainer extends React.PureComponent<ProjectListContainerPropsInterface & RouteComponentProps> {

    componentDidMount() {
        // const page = new URLSearchParams(this.props.location.search).get("page") || "0";
        // if (page) console.log("Page: " + page);

        if (this.props.currentUser.get(cUser.role) === Role.ADMIN) {
            this.props.getAllProjects({page: 0});
        } else {
            this.props.getAllOwnAssignedProjects({page: 0});
        }
    }

    renderBreadcrumb = () => {
        return (
            <span>
                <Link to="/" className="plain-link">Home</Link>
                {" / Projects"}
            </span>
        )
    }

    renderControlPanel = () => {
        return (
            <ProtectedContainer requiredRole={Role.ADMIN}>
                <Link to="/project-form">
                    <Button color="primary" className="mt-4">
                        <FontAwesomeIcon icon={faPlus} className="mr-2"/>
                        Create project
                    </Button>
                </Link>
            </ProtectedContainer>
        )
    }

    columnDefinitions: ColumnDefinition<ProjectType>[] = [
        {
            attributeName: cProject.name,
            renderHeader: () => "Name",
            renderCell: rowObject => rowObject.get(cProject.name)
        },
        {
            attributeName: cProject.description,
            renderHeader: () => "Description",
            renderCell: rowObject => rowObject.get(cProject.description)
        },
        {
            attributeName: cProject.createdOn,
            renderHeader: () => "Created on",
            renderCell: rowObject => rowObject.get(cProject.createdOn)
        },
    ]

    render() {
        return (
            <Content
                title="Projects"
                subtitle={this.renderBreadcrumb()}
                controlPanel={this.renderControlPanel}
            >
                <ContentCard className="ml-5 mr-5 mb-5">
                    <div className="m-5">
                        <TableView
                            rows={this.props.projects.get(cPage.content)}
                            rowKeyAttribute={cProject.id}
                            columnDefinitions={this.columnDefinitions}
                            onRowClick={rowObject => this.props.history.push(`/project/${rowObject.get(cProject.id)}`)}
                        />
                        <PaginationControl page={this.props.projects} onPageChange={(page) => this.props.getAllProjects({page: page})} />
                    </div>
                </ContentCard>
            </Content>
        );
    }
}

const mapStateToProps = (state: any) => ({
    projects: state.projectApiReducer.get(cProjectReducer.projects),
    currentUser: state.authApiReducer.get(cAuthReducer.currentUser)
});

const mapDispatchToProps = (dispatch: any) => ({
    getAllProjects: (page?: Pagination) => dispatch(getAllProjects(page)),
    getAllOwnAssignedProjects: (page?: Pagination) => dispatch(getAllOwnAssignedProjects(page))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ProjectListContainer));