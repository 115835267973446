import {all, call, put, takeLatest} from "redux-saga/effects";
import {
    USER_DELETE_BY_ID,
    USER_GET_ALL,
    USER_GET_ALL_BY_SEARCH_STRING,
    USER_GET_BY_ID,
    USER_SAVE,
    USER_UPDATE_ROLE_BY_ID
} from "./constants";
import {Actions} from "../../global/actions";
import {
    DeleteUserByIdActionInterface,
    GetAllUsersActionInterface,
    GetAllUsersBySearchStringActionInterface,
    GetUserByIdActionInterface,
    SaveUserActionInterface,
    UpdateUserRoleByIdActionInterface
} from "./actions";
import {Alert} from "../../containers/AlertContainer/actions";
import {Network} from "../../internal/network";
import Links from "../../global/links";
import {mapPageFromJS} from "../../models/Page";
import {cUser, mapUserFromJS, mapUserToJS} from "../../models/User";
import {Callback} from "../../models/Event";
import {getExceptionMessage} from "../../models/ExceptionCode";

function* save(action: SaveUserActionInterface) {
    try {
        let userId = action.user.get(cUser.id);
        const payload = {body: JSON.stringify(mapUserToJS(action.user))};
        if (userId) {
            yield call(Network.put(Links.user(), payload));
        } else {
            userId = yield call(Network.post(Links.user(), payload));
        }
        yield put(Actions.fulfilled(action.type, userId));
        yield put(Alert.success("User has been successfully saved."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* updateUserRoleById(action: UpdateUserRoleByIdActionInterface) {
    try {
        const payload = {body: "\"" + action.role + "\""};
        yield call(Network.put(Links.userRoleById(action.id), payload));
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("User role has been successfully changed."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* deleteById(action: DeleteUserByIdActionInterface) {
    try {
        yield call(Network.del(Links.userById(action.id)));
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("User has been successfully deleted."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* getById(action: GetUserByIdActionInterface) {
    try {
        const user = yield call(Network.get(Links.userById(action.id)));
        yield put(Actions.fulfilled(action.type, mapUserFromJS(user)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

function* getAll(action: GetAllUsersActionInterface) {
    try {
        const users = yield call(Network.get(Links.allUsers(action.page?.page, action.page?.size)));
        yield put(Actions.fulfilled(action.type, mapPageFromJS(users, mapUserFromJS)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

function* getAllBySearchString(action: GetAllUsersBySearchStringActionInterface) {
    try {
        const users = yield call(Network.get(Links.allUsersBySearchString(action.searchString, action.page?.page, action.page?.size)));
        yield put(Actions.fulfilled(action.type, mapPageFromJS(users, mapUserFromJS)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

export default function* saga() {
    yield all([
        yield takeLatest(USER_SAVE, save),
        yield takeLatest(USER_UPDATE_ROLE_BY_ID, updateUserRoleById),
        yield takeLatest(USER_DELETE_BY_ID, deleteById),
        yield takeLatest(USER_GET_BY_ID, getById),
        yield takeLatest(USER_GET_ALL, getAll),
        yield takeLatest(USER_GET_ALL_BY_SEARCH_STRING, getAllBySearchString)
    ])
}