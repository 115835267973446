import {fromJS} from "immutable";
import {Mapper} from "./Mapper";
import {Immutable} from "./Immutable";

export enum cPage {
    content = "content",
    totalPages = "totalPages",
    last = "last",
    totalElements = "totalElements",
    size = "size",
    number = "number",
    first = "first",
    numberOfElements = "numberOfElements"
}

export interface PageInterface<T> {
    content: T[],
    totalPages: number,
    last: boolean;
    totalElements: number;
    size: number;
    number: number;
    first: boolean;
    numberOfElements: number;
}

export type PageType<T> = Immutable<PageInterface<T>>;

export const mapPageFromJS = <T>(src: PageInterface<T>, contentMapper: Mapper): PageType<T> => {
    return fromJS({
        [cPage.content]: src.content.map(contentMapper),
        [cPage.totalPages]: src.totalPages,
        [cPage.last]: src.last,
        [cPage.totalElements]: src.totalElements,
        [cPage.size]: src.size,
        [cPage.number]: src.number,
        [cPage.first]: src.first,
        [cPage.numberOfElements]: src.numberOfElements
    })
}

export const pageDefault = <T>(): PageType<T> => {
    return fromJS({
        [cPage.content]: [],
        [cPage.totalPages]: 0,
        [cPage.last]: false,
        [cPage.totalElements]: 0,
        [cPage.size]: 0,
        [cPage.number]: 0,
        [cPage.first]: true,
        [cPage.numberOfElements]: 0
    })
}