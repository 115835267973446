import {ProjectType} from "../../models/Project";
import {Pagination} from "../../models/Pagination";
import {
    PROJECT_DELETE_BY_ID,
    PROJECT_GET_ALL,
    PROJECT_GET_ALL_OWN_ASSIGNED,
    PROJECT_GET_BY_ID,
    PROJECT_RESET_VALUE,
    PROJECT_SAVE
} from "./constants";
import {CallbackInterface} from "../../models/Event";

export interface SaveProjectActionInterface {
    type: string;
    project: ProjectType;
    callbacks?: CallbackInterface<string>;
}

export interface DeleteProjectByIdActionInterface {
    type: string;
    id: string;
    callbacks?: CallbackInterface<void>;
}

export interface GetProjectByIdActionInterface {
    type: string;
    id: string;
}

export interface GetAllProjectsActionInterface {
    type: string;
    page?: Pagination;
}

export interface GetAllOwnAssignedProjectsActionInterface {
    type: string;
    page?: Pagination;
}

export interface ResetProjectValueActionInterface {
    type: string;
}

export const saveProject = (project: ProjectType, callbacks?: CallbackInterface<string>): SaveProjectActionInterface => ({
    type: PROJECT_SAVE,
    project,
    callbacks
});

export const deleteProjectById = (id: string, callbacks?: CallbackInterface<void>): DeleteProjectByIdActionInterface => ({
    type: PROJECT_DELETE_BY_ID,
    id,
    callbacks
});

export const getProjectById = (id: string): GetProjectByIdActionInterface => ({
    type: PROJECT_GET_BY_ID,
    id
});

export const getAllProjects = (page?: Pagination): GetAllProjectsActionInterface => ({
    type: PROJECT_GET_ALL,
    page
});

export const getAllOwnAssignedProjects = (page?: Pagination): GetAllOwnAssignedProjectsActionInterface => ({
    type: PROJECT_GET_ALL_OWN_ASSIGNED,
    page
});

export const resetProjectValue = (): ResetProjectValueActionInterface => ({
    type: PROJECT_RESET_VALUE,
})