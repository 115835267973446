import {all, debounce, put} from "redux-saga/effects";
import {DASHBOARD_CONTAINER_SEARCH_VISUALIZATIONS} from "./constants";
import {SearchVisualizationsByProjectIdAndSearchStringActionInterface} from "./actions";
import {getAllVisualizationsByProjectIdAndSearchString} from "../../api/VisualizationApi/actions";

function* searchVisualizationsByProjectIdAndSearchString(action: SearchVisualizationsByProjectIdAndSearchStringActionInterface) {
    yield put(getAllVisualizationsByProjectIdAndSearchString(action.projectId, action.searchString, action.page));
}

export default function* saga() {
    yield all([
        yield debounce(1000, DASHBOARD_CONTAINER_SEARCH_VISUALIZATIONS, searchVisualizationsByProjectIdAndSearchString)
    ]);
}