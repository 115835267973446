import React from "react";
import {List} from "immutable";
import {AlertType, cAlert} from "../../models/Alert";
import {cAlertReducer} from "./constants";
import {connect} from "react-redux";
import {Alert} from "reactstrap";
import "./style.css";

interface AlertContainerPropsInterface {
    alerts: List<AlertType>;
}

class AlertContainer extends React.PureComponent<AlertContainerPropsInterface> {

    render() {
        const {alerts} = this.props;

        if (alerts.isEmpty()) {
            return null;
        }

        const alertCards = alerts.map((alert: AlertType, index: number) =>
            <Alert key={index} color={alert.get(cAlert.type)}>
                {alert.get(cAlert.message)}
            </Alert>
        );

        return (
            <div className="alert-container">
                {alertCards}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
   alerts: state.alertContainerReducer.get(cAlertReducer.alerts)
});

const mapDispatchToProps = (_: any) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertContainer);