import {
    cDashboardReducer,
    DASHBOARD_GET_ALL_BY_PROJECT_ID,
    DASHBOARD_GET_BY_ID,
    DASHBOARD_RESET_VALUE
} from "./constants";
import {dashboardDefault, DashboardType} from "../../models/Dashboard";
import {pageDefault, PageType} from "../../models/Page";
import {Immutable} from "../../models/Immutable";
import {FULFILLED} from "../../global/constants";

interface InitialStateInterface {
    [cDashboardReducer.dashboard]: DashboardType;
    [cDashboardReducer.dashboards]: PageType<DashboardType>;
}

type InitialStateType = Immutable<InitialStateInterface>;

const initialState: InitialStateType = Immutable({
    [cDashboardReducer.dashboard]: dashboardDefault(),
    [cDashboardReducer.dashboards]: pageDefault<DashboardType>()
});

const reducer = (state: InitialStateType = initialState, action: any): InitialStateType => {
    switch (action.type) {
        case `${DASHBOARD_GET_BY_ID}${FULFILLED}`: {
            return state.set(cDashboardReducer.dashboard, action.payload);
        }
        case `${DASHBOARD_GET_ALL_BY_PROJECT_ID}${FULFILLED}`: {
            return state.set(cDashboardReducer.dashboards, action.payload);
        }
        case `${DASHBOARD_RESET_VALUE}`: {
            return state.set(action.field, initialState.get(action.field));
        }
        default: {
            return state;
        }
    }
}

export default reducer;