import {Immutable} from "./Immutable";
import * as Yup from "yup";
import {fromJS} from "immutable";
import {Role} from "./Role";

export enum cUser {
    id = "id",
    firstName = "firstName",
    lastName = "lastName",
    login = "login",
    email = "email",
    role = "role",
    password = "password"
}

export interface UserInterface {
    [cUser.id]?: string;
    [cUser.firstName]: string;
    [cUser.lastName]: string;
    [cUser.login]: string;
    [cUser.email]: string;
    [cUser.role]?: string;
    [cUser.password]?: string;
}

export type UserType = Immutable<UserInterface>;

export const userValidationSchema = Yup.object().shape({
    [cUser.firstName]: Yup.string().required(),
    [cUser.lastName]: Yup.string().required(),
    [cUser.login]: Yup.string().required(),
    [cUser.email]: Yup.string().email().required(),
    [cUser.password]: Yup.string().when([cUser.id], {
        is: (value) => value === null,
        then: Yup.string().required()
    }).min(4)
})

export const mapUserFromJS = (src: UserInterface): UserType => {
    return fromJS({
        [cUser.id]: src[cUser.id],
        [cUser.firstName]: src[cUser.firstName],
        [cUser.lastName]: src[cUser.lastName],
        [cUser.login]: src[cUser.login],
        [cUser.email]: src[cUser.email],
        [cUser.role]: src[cUser.role],
        [cUser.password]: src[cUser.password]
    })
}

export const mapUserToJS = (src: UserType): UserInterface => {
    return {
        [cUser.id]: src.get(cUser.id),
        [cUser.firstName]: src.get(cUser.firstName),
        [cUser.lastName]: src.get(cUser.lastName),
        [cUser.login]: src.get(cUser.login),
        [cUser.email]: src.get(cUser.email),
        [cUser.role]: src.get(cUser.role),
        [cUser.password]: src.get(cUser.password)
    }
}

export const userDefault = (): UserType => fromJS({
   [cUser.id]: null,
   [cUser.firstName]: "",
   [cUser.lastName]: "",
   [cUser.login]: "",
   [cUser.email]: "",
   [cUser.role]: Role.USER,
   [cUser.password]: undefined
});