import {Pagination} from "../../models/Pagination";
import {PARAMETER_DELETE_BY_ID, PARAMETER_GET_ALL_BY_TASK_ID, PARAMETER_SAVE} from "./constants";
import {ParameterType} from "../../models/Parameter";
import {CallbackInterface} from "../../models/Event";

export interface SaveParameterActionInterface {
    type: string;
    parameter: ParameterType;
    callbacks?: CallbackInterface<number>;
}

export interface DeleteParameterByIdActionInterface {
    type: string;
    id: number;
    callbacks?: CallbackInterface<number>;
}

export interface GetAllParametersByTaskIdActionInterface {
    type: string;
    taskId: string;
    page?: Pagination
}

export const saveParameter = (parameter: ParameterType, callbacks?: CallbackInterface<number>): SaveParameterActionInterface => ({
   type: PARAMETER_SAVE,
   parameter,
   callbacks
});

export const deleteParameterById = (id: number, callbacks?: CallbackInterface<number>): DeleteParameterByIdActionInterface => ({
    type: PARAMETER_DELETE_BY_ID,
    id,
    callbacks
});

export const getAllParametersByTaskId = (taskId: string, page?: Pagination): GetAllParametersByTaskIdActionInterface => ({
    type: PARAMETER_GET_ALL_BY_TASK_ID,
    taskId,
    page
});