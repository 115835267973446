import React from "react";
import {cPage, PageType} from "../../models/Page";
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";

interface PaginationPropsInterface {
    page: PageType<any>;
    onPageChange: (page: number) => void;
}

class PaginationControl extends React.PureComponent<PaginationPropsInterface> {

    render() {
        const pageNumber = this.props.page.get(cPage.number);
        const totalPages = Math.max(this.props.page.get(cPage.totalPages), 1);

        return (
            <Pagination className="d-flex justify-content-center">
                <PaginationItem>
                    <PaginationLink
                        onClick={() => this.props.onPageChange(pageNumber - 1)}
                        disabled={pageNumber <= 0}
                    >
                        &laquo;
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink>{pageNumber + 1}/{totalPages}</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        onClick={() => this.props.onPageChange(pageNumber + 1)}
                        disabled={pageNumber >= totalPages - 1}
                    >
                        &raquo;
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        );
    }
}

export default PaginationControl;