import {DashboardType} from "../../models/Dashboard";
import {CallbackInterface} from "../../models/Event";
import {
    DASHBOARD_ADD_METRIC_VISUALIZATION,
    DASHBOARD_DELETE_BY_ID,
    DASHBOARD_GET_ALL_BY_PROJECT_ID,
    DASHBOARD_GET_BY_ID,
    DASHBOARD_REMOVE_METRIC_VISUALIZATION,
    DASHBOARD_RESET_VALUE,
    DASHBOARD_SAVE
} from "./constants";
import {Pagination} from "../../models/Pagination";

export interface SaveDashboardActionInterface {
    type: string;
    dashboard: DashboardType;
    callbacks?: CallbackInterface<number>;
}

export interface DeleteDashboardByIdActionInterface {
    type: string;
    id: number;
    callbacks?: CallbackInterface<void>;
}

export interface AddMetricVisualizationToDashboardActionInterface {
    type: string;
    metricVisualizationId: number;
    dashboardId: number;
    callbacks?: CallbackInterface<void>;
}

export interface RemoveMetricVisualizationFromDashboardActionInterface {
    type: string;
    metricVisualizationId: number;
    dashboardId: number;
    callbacks?: CallbackInterface<void>;
}

export interface GetDashboardByIdActionInterface {
    type: string;
    id: number;
}

export interface GetAllDashboardsByProjectIdActionInterface {
    type: string;
    projectId: string;
    page?: Pagination;
}

export interface ResetDashboardValueActionInterface {
    type: string;
    field: string;
}

export const saveDashboard = (dashboard: DashboardType, callbacks?: CallbackInterface<number>): SaveDashboardActionInterface => ({
    type: DASHBOARD_SAVE,
    dashboard,
    callbacks: callbacks
})

export const deleteDashboardById = (id: number, callbacks?: CallbackInterface<void>): DeleteDashboardByIdActionInterface => ({
    type: DASHBOARD_DELETE_BY_ID,
    id,
    callbacks
});

export const addMetricVisualizationToDashboard = (metricVisualizationId: number, dashboardId: number, callbacks?: CallbackInterface<void>): AddMetricVisualizationToDashboardActionInterface => ({
    type: DASHBOARD_ADD_METRIC_VISUALIZATION,
    metricVisualizationId,
    dashboardId,
    callbacks
});

export const removeMetricVisualizationFromDashboard = (metricVisualizationId: number, dashboardId: number, callbacks?: CallbackInterface<void>): RemoveMetricVisualizationFromDashboardActionInterface => ({
    type: DASHBOARD_REMOVE_METRIC_VISUALIZATION,
    metricVisualizationId,
    dashboardId,
    callbacks
});

export const getDashboardById = (id: number): GetDashboardByIdActionInterface => ({
    type: DASHBOARD_GET_BY_ID,
    id
});

export const getAllDashboardsByProjectId = (projectId: string, page?: Pagination): GetAllDashboardsByProjectIdActionInterface => ({
    type: DASHBOARD_GET_ALL_BY_PROJECT_ID,
    projectId,
    page
});

export const resetDashboardValue = (field: string): ResetDashboardValueActionInterface => ({
    type: DASHBOARD_RESET_VALUE,
    field
});