import {dashboardDefault, DashboardType} from "./Dashboard";
import {metricQueryDefault, MetricQueryType} from "./MetricQuery";
import {Immutable} from "./Immutable";
import * as Yup from "yup";
import {fromJS} from "immutable";

export enum cDashboardItem {
    id = "id",
    position = "position",
    dashboardId = "dashboardId",
    visualizationId = "visualizationId",
    dashboard = "dashboard",
    visualization = "visualization"
}

export interface DashboardItemInterface {
    [cDashboardItem.id]?: number;
    [cDashboardItem.position]?: number;
    [cDashboardItem.dashboardId]: number;
    [cDashboardItem.visualizationId]: number;
    [cDashboardItem.dashboard]?: DashboardType;
    [cDashboardItem.visualization]?: MetricQueryType;
}

export type DashboardItemType = Immutable<DashboardItemInterface>;

export const dashboardItemValidationSchema = Yup.object().shape({
   [cDashboardItem.position]: Yup.number().optional().nullable().positive()
});

export const mapDashboardItemFromJS = (src: DashboardItemInterface): DashboardItemType => {
    return fromJS({
        [cDashboardItem.id]: src[cDashboardItem.id],
        [cDashboardItem.position]: src[cDashboardItem.position],
        [cDashboardItem.dashboardId]: src[cDashboardItem.dashboardId],
        [cDashboardItem.visualizationId]: src[cDashboardItem.visualizationId],
        [cDashboardItem.dashboard]: src[cDashboardItem.dashboard],
        [cDashboardItem.visualization]: src[cDashboardItem.visualization]
    });
}

export const mapDashboardItemToJS = (src: DashboardItemType): DashboardItemInterface => ({
    [cDashboardItem.id]: src.get(cDashboardItem.id),
    [cDashboardItem.position]: src.get(cDashboardItem.position),
    [cDashboardItem.dashboardId]: src.get(cDashboardItem.dashboardId),
    [cDashboardItem.visualizationId]: src.get(cDashboardItem.visualizationId),
    [cDashboardItem.dashboard]: src.get(cDashboardItem.dashboard),
    [cDashboardItem.visualization]: src.get(cDashboardItem.visualization)
});

export const dashboardItemDefault = (): DashboardItemType => fromJS({
    [cDashboardItem.id]: null,
    [cDashboardItem.position]: null,
    [cDashboardItem.dashboardId]: null,
    [cDashboardItem.visualizationId]: null,
    [cDashboardItem.dashboard]: dashboardDefault(),
    [cDashboardItem.visualization]: metricQueryDefault()
});