import React from "react";
import {Role} from "../../models/Role";
import {connect} from "react-redux";
import {cUser, UserType} from "../../models/User";
import {cAuthReducer} from "../../api/AuthApi/constants";

interface ProtectedContainerPropsInterface {
    requiredRole: Role;
    authUser: UserType;
    authUserLoading: boolean;
    children: JSX.Element;
}

class ProtectedContainer extends React.PureComponent<ProtectedContainerPropsInterface> {

    render() {
        if (this.props.authUserLoading || this.props.authUser.get(cUser.role) !== this.props.requiredRole) {
            return null;
        }

        return this.props.children;
    }
}

const mapStateToProps = (state: any) => ({
    authUser: state.authApiReducer.get(cAuthReducer.currentUser),
    authUserLoading: state.authApiReducer.get(cAuthReducer.loading)
});

const mapDispatchToProps = (dispatch: any) => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProtectedContainer);