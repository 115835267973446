import {all, call, put, takeLatest} from "redux-saga/effects";
import {
    VISUALIZATION_DELETE_BY_ID,
    VISUALIZATION_GET_ALL_BY_PROJECT_ID_AND_SEARCH_STRING,
    VISUALIZATION_GET_ALL_BY_TASK_ID,
    VISUALIZATION_GET_BY_ID,
    VISUALIZATION_SAVE
} from "./constants";
import {Network} from "../../internal/network";
import {
    DeleteVisualizationByIdActionInterface,
    GetAllVisualizationsByProjectIdAndSearchStringActionInterface,
    GetAllVisualizationsByTaskIdActionInterface,
    GetVisualizationByIdActionInterface,
    SaveVisualizationActionInterface
} from "./actions";
import {cMetricQuery, mapMetricQueryFromJS, mapMetricQueryToJS, MetricQueryInterface} from "../../models/MetricQuery";
import Links from "../../global/links";
import {Actions} from "../../global/actions";
import {Alert} from "../../containers/AlertContainer/actions";
import {Callback} from "../../models/Event";
import {mapPageFromJS, PageInterface} from "../../models/Page";
import {getExceptionMessage} from "../../models/ExceptionCode";

function* save(action: SaveVisualizationActionInterface) {
    try {
        let visualizationId: number | undefined = action.visualization.get(cMetricQuery.id);
        const payload = {body: JSON.stringify(mapMetricQueryToJS(action.visualization))};
        if (visualizationId) {
            yield call(Network.put(Links.visualization(), payload));
        } else {
            visualizationId = yield call(Network.post(Links.visualization(), payload));
        }
        yield put(Actions.fulfilled(action.type, visualizationId));
        yield put(Alert.success("Visualization has been successfully saved."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* deleteById(action: DeleteVisualizationByIdActionInterface) {
    try {
        yield call(Network.del(Links.visualizationById(action.id)));
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("Visualization has been successfully deleted."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* getById(action: GetVisualizationByIdActionInterface) {
    try {
        const visualization: MetricQueryInterface = yield call(Network.get(Links.visualizationById(action.id)));
        yield put(Actions.fulfilled(action.type, mapMetricQueryFromJS(visualization)));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks, visualization));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* getAllByTaskId(action: GetAllVisualizationsByTaskIdActionInterface) {
    try {
        const visualizations: PageInterface<MetricQueryInterface> = yield call(Network.get(Links.visualizationsByTaskId(action.taskId, action.page?.page, action.page?.size)));
        yield put(Actions.fulfilled(action.type, mapPageFromJS(visualizations, mapMetricQueryFromJS)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

function* getAllByProjectIdAndSearchString(action: GetAllVisualizationsByProjectIdAndSearchStringActionInterface) {
    try {
        const visualizations: PageInterface<MetricQueryInterface> = yield call(Network.get(Links.visualizationsByProjectIdAndSearchString(action.projectId, action.searchString, action.page?.page, action.page?.size)));
        yield put(Actions.fulfilled(action.type, mapPageFromJS(visualizations, mapMetricQueryFromJS)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

export default function* saga() {
    yield all([
        yield takeLatest(VISUALIZATION_SAVE, save),
        yield takeLatest(VISUALIZATION_DELETE_BY_ID, deleteById),
        yield takeLatest(VISUALIZATION_GET_BY_ID, getById),
        yield takeLatest(VISUALIZATION_GET_ALL_BY_TASK_ID, getAllByTaskId),
        yield takeLatest(VISUALIZATION_GET_ALL_BY_PROJECT_ID_AND_SEARCH_STRING, getAllByProjectIdAndSearchString)
    ])
}