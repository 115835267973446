import React from "react";
import {Map, Set} from "immutable";
import {cPage, PageType} from "../../models/Page";
import {cGenericFile, GenericFileType, getFileSizeWithUnit} from "../../models/GenericFile";
import {getPaginationFromPage, Pagination} from "../../models/Pagination";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import TableView, {ColumnDefinition} from "../TableView";
import moment from "moment";
import {DATE_TIME_WITHOUT_TIMEZONE_FORMAT} from "../../global/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Immutable} from "../../models/Immutable";

interface TaskFileTableModalContainerProps {
    classname?: string;
    headerText: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    isMultiselect: boolean;

    isOpen: boolean;
    foundFiles: PageType<GenericFileType>;

    onToggle: () => void;
    onSearchProjectFilesBySearchString: (searchString: string, page: Pagination) => void;
    onSaveAllTaskFiles: (taskFiles: Set<GenericFileType>) => void;
    onResetFoundFiles: () => void;
}

interface TaskFileTableModalContainerState {
    addedFiles: Map<string, GenericFileType>;
    isSearchDropdownOpen: boolean;
}

class TaskFileTableModal extends React.PureComponent<TaskFileTableModalContainerProps, TaskFileTableModalContainerState> {

    state = {
        addedFiles: Map<string, GenericFileType>(),
        isSearchDropdownOpen: false
    }

    componentDidMount() {
        this.props.onResetFoundFiles();
    }

    handleSearchInputChange = (event: any) => {
        const textValue = event.target.value;
        const searchString = `name:${textValue},`;
        this.props.onSearchProjectFilesBySearchString(searchString, getPaginationFromPage(this.props.foundFiles));
    }

    handleDropdownToggle = () => {
        this.setState(prevState => ({
            ...prevState,
            isSearchDropdownOpen: !prevState.isSearchDropdownOpen
        }));
    }

    handleSave = () => {
        this.props.onSaveAllTaskFiles(this.state.addedFiles.toSet());
    }

    handleAddProjectFile = (file: GenericFileType) => {
        const fileId = file.get(cGenericFile.id);
        if (fileId) {
            this.setState(prevState => ({
                ...prevState,
                addedFiles: this.props.isMultiselect
                    ? prevState.addedFiles.set(fileId, file)
                    : prevState.addedFiles.clear().set(fileId, file)
            }));
        }
    }

    handleRemoveProjectFile = (fileId: string) => {
        this.setState(prevState => ({
            ...prevState,
            addedFiles: prevState.addedFiles.remove(fileId)
        }));
    }

    renderSearchResult = (): JSX.Element => {
        if (this.props.foundFiles.get(cPage.numberOfElements) > 0) {
            const searchResults = this.props.foundFiles.get(cPage.content)
                .map(e => (
                    <DropdownItem key={e.get(cGenericFile.id)} onClick={() => this.handleAddProjectFile(e)}>
                        {e.get(cGenericFile.name)}
                    </DropdownItem>
                ));
            return (
                <>
                    {searchResults}
                </>
            )
        }
        return <DropdownItem>No results</DropdownItem>
    }

    renderOperationsColumn = (rowObject: GenericFileType) => {
        return (
            <FontAwesomeIcon icon={faTimes} className="cursor-pointer" onClick={() => this.handleRemoveProjectFile(rowObject.get(cGenericFile.id) || "")} />
        )
    }

    columnDefinitions: ColumnDefinition<GenericFileType>[] = [
        {
            attributeName: cGenericFile.name,
            renderHeader: () => "File name",
            renderCell: rowObject => rowObject.get(cGenericFile.name)
        },
        {
            attributeName: cGenericFile.size,
            renderHeader: () => "Size",
            renderCell: rowObject => getFileSizeWithUnit(rowObject.get(cGenericFile.size) || 0)
        },
        {
            attributeName: cGenericFile.createdOn,
            renderHeader: () => "Created on",
            renderCell: rowObject => moment(rowObject.get(cGenericFile.createdOn)).format(DATE_TIME_WITHOUT_TIMEZONE_FORMAT)
        },
        {
            attributeName: "operations",
            renderHeader: () => "",
            renderCell: this.renderOperationsColumn
        }
    ]

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle} className={this.props.classname} size="lg">
                <ModalHeader>{this.props.headerText}</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Input type="text" placeholder="Search for project files" onClick={this.handleDropdownToggle} onChange={this.handleSearchInputChange} />
                            <Dropdown isOpen={this.state.isSearchDropdownOpen} toggle={this.handleDropdownToggle}>
                                <DropdownToggle tag="span" />
                                <DropdownMenu>
                                    {this.renderSearchResult()}
                                </DropdownMenu>
                            </Dropdown>
                        </FormGroup>
                    </Form>
                    <div className="overflow-auto" style={{maxHeight: "15rem"}}>
                        <TableView
                            rows={this.state.addedFiles.valueSeq().map(e => Immutable(e)).toArray()}
                            rowKeyAttribute={cGenericFile.id}
                            columnDefinitions={this.columnDefinitions}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.onToggle}>{this.props.cancelButtonText || "Cancel"}</Button>
                    <Button color="primary" onClick={this.handleSave}>{this.props.confirmButtonText || "Save"}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default TaskFileTableModal;