import {all, call, put, takeLatest} from "redux-saga/effects";
import {
    TASK_DELETE_BY_ID,
    TASK_GET_ALL_AVAILABLE_TASK_RUNNERS,
    TASK_GET_ALL_BY_PROJECT_ID,
    TASK_GET_BY_ID,
    TASK_SAVE,
    TASK_UPDATE_STATE
} from "./constants";
import {Network} from "../../internal/network";
import Links from "../../global/links";
import {
    DeleteTaskByIdActionInterface,
    GetAllAvailableTaskRunnersActionInterface,
    GetAllTasksByProjectIdActionInterface,
    GetTaskByIdActionInterface,
    SaveTaskActionInterface,
    UpdateTaskStateActionInterface
} from "./actions";
import {Actions} from "../../global/actions";
import {cTask, mapTaskFromJS, mapTaskToJS} from "../../models/Task";
import {mapPageFromJS} from "../../models/Page";
import {Alert} from "../../containers/AlertContainer/actions";
import {Callback} from "../../models/Event";
import {TaskState} from "../../models/TaskState";
import {cSshCredentials} from "../../models/SshCredentials";
import {getExceptionMessage} from "../../models/ExceptionCode";
import {Set} from "immutable";

function* save(action: SaveTaskActionInterface) {
    try {
        let taskId: string | undefined = action.task.get(cTask.id);
        const payload = {body: JSON.stringify(mapTaskToJS(action.task))}
        if (taskId) {
            yield call(Network.put(Links.task(), payload));
        } else {
            const response = yield call(Network.post(Links.task(), payload));
            taskId = mapTaskFromJS(response).get(cTask.id);
        }
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("Task has been successfully created."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks, taskId))
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks))
    }
}

function* updateState(action: UpdateTaskStateActionInterface) {
    try {
        let headers;
        const sshCredentials = action.credentials;
        if (sshCredentials && sshCredentials.key && sshCredentials.passphrase) {
            headers = {
                key: sshCredentials[cSshCredentials.key],
                username: sshCredentials[cSshCredentials.username],
                pass: sshCredentials[cSshCredentials.passphrase]
            };
        }
        const payload = {body: `"${action.state}"`, headers: {...headers}}
        yield call(Network.put(Links.taskState(action.id), payload));
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success(`Task has been successfully ${action.state === TaskState.STARTING ? "started" : "stopped"}.`));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks, action.state));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
    }
}

function* deleteById(action: DeleteTaskByIdActionInterface) {
    try {
        yield call(Network.del(Links.taskById(action.id)));
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("Task has been successfully deleted."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* getById(action: GetTaskByIdActionInterface) {
    try {
        const task = yield call(Network.get(Links.taskById(action.id)));
        yield put(Actions.fulfilled(action.type, mapTaskFromJS(task)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

function* getAllByProjectId(action: GetAllTasksByProjectIdActionInterface) {
    try {
        const tasks = yield call(Network.get(Links.tasksByProjectId(action.projectId, action.page?.size, action.page?.page)));
        yield put(Actions.fulfilled(action.type, mapPageFromJS(tasks, mapTaskFromJS)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

function* getAllAvailableTaskRunners(action: GetAllAvailableTaskRunnersActionInterface) {
    try {
        const availableRunners: string[] = yield call(Network.get(Links.tasksAvailableRunners()));
        yield put(Actions.fulfilled(action.type, Set(availableRunners)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

export default function* saga() {
    yield all([
        yield takeLatest(TASK_SAVE, save),
        yield takeLatest(TASK_UPDATE_STATE, updateState),
        yield takeLatest(TASK_DELETE_BY_ID, deleteById),
        yield takeLatest(TASK_GET_BY_ID, getById),
        yield takeLatest(TASK_GET_ALL_BY_PROJECT_ID, getAllByProjectId),
        yield takeLatest(TASK_GET_ALL_AVAILABLE_TASK_RUNNERS, getAllAvailableTaskRunners)
    ]);
}