import {Pagination} from "../../models/Pagination";
import {TASK_FORM_CONTAINER_SEARCH_PROJECT_FILES} from "./constants";

export interface SearchProjectFilesByTaskIdAndSearchStringActionInterface {
    type: string;
    taskId: string;
    searchString: string;
    page?: Pagination
}

export const searchProjectFilesByTaskIdAndSearchString = (taskId: string, searchString: string, page?: Pagination): SearchProjectFilesByTaskIdAndSearchStringActionInterface => ({
    type: TASK_FORM_CONTAINER_SEARCH_PROJECT_FILES,
    taskId,
    searchString,
    page
});