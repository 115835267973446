import {PROJECT_STUDENTS_TAB_SEARCH_USER, PROJECT_STUDENTS_TAB_TOGGLE_ASSIGNMENT_MODAL} from "./constants";

export interface ToggleProjectAssignmentModalActionInterface {
    type: string;
}

export interface SearchUserBySearchStringActionInterface {
    type: string;
    searchString: string;
}

export const toggleProjectAssignmentModal = (): ToggleProjectAssignmentModalActionInterface => ({
    type: PROJECT_STUDENTS_TAB_TOGGLE_ASSIGNMENT_MODAL
})

export const searchUserBySearchString = (searchString: string):SearchUserBySearchStringActionInterface => ({
    type: PROJECT_STUDENTS_TAB_SEARCH_USER,
    searchString
})