import {ProjectInterface} from "./Project";
import {Immutable} from "./Immutable";
import * as Yup from "yup";
import {fromJS} from "immutable";

export enum cDashboard {
    id = "id",
    name = "name",
    createdOn = "createdOn",
    projectId = "projectId",
    project = "project"
}

export interface DashboardInterface {
    [cDashboard.id]?: number;
    [cDashboard.name]: string;
    [cDashboard.createdOn]?: string;
    [cDashboard.projectId]: string;
    [cDashboard.project]?: ProjectInterface;
}

export type DashboardType = Immutable<DashboardInterface>;

export const dashboardValidationSchema = Yup.object().shape({
    [cDashboard.name]: Yup.string().required()
});

export const mapDashboardFromJS = (src: DashboardInterface): DashboardType => {
    return fromJS({
       [cDashboard.id]: src[cDashboard.id],
       [cDashboard.name]: src[cDashboard.name],
       [cDashboard.createdOn]: src[cDashboard.createdOn],
       [cDashboard.projectId]: src[cDashboard.projectId],
       [cDashboard.project]: src[cDashboard.project]
    });
}

export const mapDashboardToJS = (src: DashboardType): DashboardInterface => ({
    [cDashboard.id]: src.get(cDashboard.id),
    [cDashboard.name]: src.get(cDashboard.name),
    [cDashboard.createdOn]: src.get(cDashboard.createdOn),
    [cDashboard.projectId]: src.get(cDashboard.projectId),
    [cDashboard.project]: src.get(cDashboard.project)
});

export const dashboardDefault = (): DashboardType => fromJS({
    [cDashboard.id]: null,
    [cDashboard.name]: "",
    [cDashboard.createdOn]: null,
    [cDashboard.projectId]: null,
    [cDashboard.project]: null
});