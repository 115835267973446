import {AUTH_FETCH_MYSELF, AUTH_LOGIN_BY_CODE, AUTH_LOGOUT} from "./constants";

export interface LoginByCodeActionInterface {
    type: string;
    code: string;
}

export interface LogoutActionInterface {
    type: string;
}

export interface FetchMyselfActionInterface {
    type: string;
}

export const loginByCode = (code: string): LoginByCodeActionInterface => ({
    type: AUTH_LOGIN_BY_CODE,
    code
});

export const logout = (): LogoutActionInterface => ({
    type: AUTH_LOGOUT
});

export const fetchMyself = (): FetchMyselfActionInterface => ({
    type: AUTH_FETCH_MYSELF
});