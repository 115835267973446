import {ProjectType} from "./Project";
import {Immutable} from "./Immutable";
import {fromJS} from "immutable";
import * as Yup from "yup";

export enum cProjectTag {
    id = "id",
    name = "name",
    value = "value",
    description = "description",
    projectId = "projectId",
    project = "project"
}

export interface ProjectTagInterface {
    [cProjectTag.id]?: number;
    [cProjectTag.name]: string;
    [cProjectTag.value]: string;
    [cProjectTag.description]?: string;
    [cProjectTag.projectId]: string;
    [cProjectTag.project]?: ProjectType;
}

export type ProjectTagType = Immutable<ProjectTagInterface>;

export const projectTagValidationSchema = Yup.object().shape({
    [cProjectTag.name]: Yup.string().required(),
    [cProjectTag.value]: Yup.string().required(),
    [cProjectTag.description]: Yup.string().optional()
})

export const mapProjectTagFromJS = (src: ProjectTagInterface): ProjectTagType => {
    return fromJS({
        [cProjectTag.id]: src[cProjectTag.id],
        [cProjectTag.name]: src[cProjectTag.name],
        [cProjectTag.value]: src[cProjectTag.value],
        [cProjectTag.description]: src[cProjectTag.description] || "",
        [cProjectTag.projectId]: src[cProjectTag.projectId],
        [cProjectTag.project]: src[cProjectTag.project]
    })
};

export const mapProjectTagToJS = (src: ProjectTagType): ProjectTagInterface => ({
    [cProjectTag.id]: src.get(cProjectTag.id),
    [cProjectTag.name]: src.get(cProjectTag.name),
    [cProjectTag.value]: src.get(cProjectTag.value),
    [cProjectTag.description]: src.get(cProjectTag.description)?.length !== 0 ? src.get(cProjectTag.description) : undefined,
    [cProjectTag.projectId]: src.get(cProjectTag.projectId),
    [cProjectTag.project]: src.get(cProjectTag.project)
});

export const projectTagDefault = (): ProjectTagType => fromJS({
    [cProjectTag.id]: null,
    [cProjectTag.name]: "",
    [cProjectTag.value]: "",
    [cProjectTag.description]: "",
    [cProjectTag.projectId]: null,
    [cProjectTag.project]: null
})