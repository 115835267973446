import {Pagination} from "../../models/Pagination";
import {
    TASK_DELETE_BY_ID,
    TASK_GET_ALL_AVAILABLE_TASK_RUNNERS,
    TASK_GET_ALL_BY_PROJECT_ID,
    TASK_GET_BY_ID,
    TASK_RESET_VALUE,
    TASK_SAVE,
    TASK_UPDATE_STATE
} from "./constants";
import {TaskType} from "../../models/Task";
import {CallbackInterface} from "../../models/Event";
import {TaskState} from "../../models/TaskState";
import {SshCredentialsInterface} from "../../models/SshCredentials";

export interface SaveTaskActionInterface {
    type: string;
    task: TaskType;
    callbacks?: CallbackInterface<string>;
}

export interface UpdateTaskStateActionInterface {
    type: string;
    id: string;
    state: TaskState;
    credentials?: SshCredentialsInterface;
    callbacks?: CallbackInterface<TaskState>;
}

export interface DeleteTaskByIdActionInterface {
    type: string;
    id: string;
    callbacks?: CallbackInterface<void>;
}

export interface GetTaskByIdActionInterface {
    type: string;
    id: string;
}

export interface GetAllTasksByProjectIdActionInterface {
    type: string;
    projectId: string;
    page?: Pagination
}

export interface GetAllAvailableTaskRunnersActionInterface {
    type: string;
}

export interface ResetTaskValueActionInterface {
    type: string;
    field: string;
}

export const saveTask = (task: TaskType, callbacks?: CallbackInterface<string>): SaveTaskActionInterface => ({
   type: TASK_SAVE,
   task,
   callbacks
});

export const updateTaskState = (id: string, state: TaskState, credentials?: SshCredentialsInterface, callbacks?: CallbackInterface<TaskState>): UpdateTaskStateActionInterface => ({
   type: TASK_UPDATE_STATE,
   id,
   state,
   credentials,
   callbacks
});

export const deleteTaskById = (id: string, callbacks?: CallbackInterface<void>): DeleteTaskByIdActionInterface => ({
    type: TASK_DELETE_BY_ID,
    id,
    callbacks
});

export const getTaskById = (id: string): GetTaskByIdActionInterface => ({
    type: TASK_GET_BY_ID,
    id
});

export const getAllTasksByProjectId = (projectId: string, page?: Pagination): GetAllTasksByProjectIdActionInterface => ({
    type: TASK_GET_ALL_BY_PROJECT_ID,
    projectId,
    page
});

export const getAllAvailableTaskRunners = (): GetAllAvailableTaskRunnersActionInterface => ({
    type: TASK_GET_ALL_AVAILABLE_TASK_RUNNERS
});

export const resetTaskValue = (field: string): ResetTaskValueActionInterface => ({
    type: TASK_RESET_VALUE,
    field
});