import React from "react";
import {
    cDashboardItem,
    DashboardItemType,
    dashboardItemValidationSchema,
    mapDashboardItemFromJS,
    mapDashboardItemToJS
} from "../../models/DashboardItem";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import {Formik} from "formik";

interface DashboardItemFormModalActionInterface {
    isOpen: boolean;
    dashboardItem: DashboardItemType;
    totalItems: number;

    onToggle: () => void;
    onSaveDashboardItem: (dashboardItem: DashboardItemType) => void;
}

class DashboardItemFormModal extends React.PureComponent<DashboardItemFormModalActionInterface> {

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle}>
                <Formik
                    initialValues={mapDashboardItemToJS(this.props.dashboardItem.set(cDashboardItem.position, 1))}
                    validationSchema={dashboardItemValidationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(true);
                        let dashboardItem = mapDashboardItemFromJS(values);
                        if (dashboardItem.get(cDashboardItem.id)) {
                            dashboardItem = dashboardItem
                                .withMutations((item: DashboardItemType) => {
                                    return item
                                        .set(cDashboardItem.visualizationId, null)
                                        .set(cDashboardItem.dashboardId, null)
                                        .set(cDashboardItem.visualization, null)
                                        .set(cDashboardItem.dashboard, null)
                                })

                        }
                        this.props.onSaveDashboardItem(dashboardItem);
                    }}
                >
                    {
                        ({values, errors, handleChange, handleSubmit, handleBlur, isSubmitting, setFieldValue}) => (
                            <Form onSubmit={handleSubmit}>
                                <ModalHeader>Edit visualization</ModalHeader>
                                <ModalBody>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="positionField">Position</Label>
                                                <Input
                                                    id="positionField" name="position" type="select"
                                                    value={values[cDashboardItem.position] !== null ? values[cDashboardItem.position] : 1} onChange={handleChange}
                                                    onBlur={handleBlur} invalid={!!errors[cDashboardItem.position]}
                                                >
                                                    {
                                                        Array.from({length: this.props.totalItems}, (v, i) =>
                                                            <option value={i + 1} key={i}>{i + 1}</option>
                                                        )
                                                    }
                                                </Input>
                                                <FormFeedback>{errors[cDashboardItem.position]}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={this.props.onToggle}>Cancel</Button>
                                    <Button color="primary" type="submit" disabled={isSubmitting}>Save</Button>
                                </ModalFooter>
                            </Form>
                        )
                    }
                </Formik>
            </Modal>
        );
    }
}

export default DashboardItemFormModal;