import React from "react";
import Content from "../../components/Content";
import ContentCard from "../../components/ContentCard";
import {connect} from "react-redux";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {Nav, TabContent, TabPane} from "reactstrap";
import TabLink from "../../components/TabLink";
import ProjectDetailTabContainer from "../ProjectDetailTabContainer";
import ProjectStudentsTabContainer from "../ProjectStudentsTabContainer";
import ProjectTasksTabContainer from "../ProjectTasksTabContainer";
import ProjectFilesTabContainer from "../ProjectFilesTabContainer";
import ProjectTagsTabContainer from "../ProjectTagsTabContainer";
import {cProjectReducer} from "../../api/ProjectApi/constants";
import {cProject, ProjectType} from "../../models/Project";
import ProjectDashboardsTabContainer from "../ProjectDashboardsTabContainer";

interface ProjectManagementContainerPropsInterface {
    project: ProjectType;
}

interface ProjectManagementContainerStateInterface {
    activeTab: number;
}

class ProjectManagementContainer extends React.PureComponent<ProjectManagementContainerPropsInterface & RouteComponentProps, ProjectManagementContainerStateInterface> {

    state = {
        activeTab: 1
    }

    setActiveTab = (id: number) => {
        this.setState({
            ...this.state,
            activeTab: id
        });
    }

    renderBreadcrumb = () => {
        return (
            <span>
                <Link to="/" className="plain-link">Home</Link>
                {" / "}
                <Link to="/project-list" className="plain-link">Projects</Link>
                {" / "}
                {this.props.project.get(cProject.name)}
            </span>
        )
    }

    renderTabContent = (tabId: number, component: JSX.Element): JSX.Element => {
        if (this.state.activeTab === tabId) {
            return component;
        }

        return <></>;
    }

    render() {
        const projectId: string = new URLSearchParams(this.props.match.params).get("id") || "";

        return (
            <Content title="Project" subtitle={this.renderBreadcrumb()}>
                <ContentCard className="ml-5 mr-5 mb-5" style={{minHeight: "calc(100vh - 26vh)"}}>
                    <Nav tabs className="mt-2 ml-2">
                        <TabLink id={1} title="Detail" activeTab={this.state.activeTab} setActiveTab={this.setActiveTab} />
                        <TabLink id={2} title="Students" activeTab={this.state.activeTab} setActiveTab={this.setActiveTab} />
                        <TabLink id={3} title="Files" activeTab={this.state.activeTab} setActiveTab={this.setActiveTab} />
                        <TabLink id={4} title="Tags" activeTab={this.state.activeTab} setActiveTab={this.setActiveTab} />
                        <TabLink id={5} title="Tasks" activeTab={this.state.activeTab} setActiveTab={this.setActiveTab} />
                        <TabLink id={6} title="Dashboards" activeTab={this.state.activeTab} setActiveTab={this.setActiveTab} />
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId={1}>
                            {this.renderTabContent(1, <ProjectDetailTabContainer projectId={projectId} />)}
                        </TabPane>
                        <TabPane tabId={2}>
                            {this.renderTabContent(2, <ProjectStudentsTabContainer projectId={projectId}/>)}
                        </TabPane>
                        <TabPane tabId={3}>
                            {this.renderTabContent(3, <ProjectFilesTabContainer projectId={projectId} />)}
                        </TabPane>
                        <TabPane tabId={4}>
                            {this.renderTabContent(4, <ProjectTagsTabContainer projectId={projectId} />)}
                        </TabPane>
                        <TabPane tabId={5}>
                            {this.renderTabContent(5, <ProjectTasksTabContainer projectId={projectId} />)}
                        </TabPane>
                        <TabPane tabId={6}>
                            {this.renderTabContent(6, <ProjectDashboardsTabContainer projectId={projectId} />)}
                        </TabPane>
                    </TabContent>
                </ContentCard>
            </Content>
        );
    }
}

const mapStateToProps = (state: any) => ({
    project: state.projectApiReducer.get(cProjectReducer.project)
});

const mapDispatchToProps = (dispatch: any) => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ProjectManagementContainer));