import {all, call, put, takeLatest} from "redux-saga/effects";
import {PARAMETER_DELETE_BY_ID, PARAMETER_GET_ALL_BY_TASK_ID, PARAMETER_SAVE} from "./constants";
import {Network} from "../../internal/network";
import Links from "../../global/links";
import {
    DeleteParameterByIdActionInterface,
    GetAllParametersByTaskIdActionInterface,
    SaveParameterActionInterface
} from "./actions";
import {Actions} from "../../global/actions";
import {mapPageFromJS} from "../../models/Page";
import {cParameter, mapParameterFromJS, mapParameterToJS} from "../../models/Parameter";
import {Alert} from "../../containers/AlertContainer/actions";
import {Callback} from "../../models/Event";
import {getExceptionMessage} from "../../models/ExceptionCode";

function* save(action: SaveParameterActionInterface) {
    try {
        let parameterId = action.parameter.get(cParameter.id);
        const payload = {body: JSON.stringify(mapParameterToJS(action.parameter))};
        if (parameterId) {
            yield call(Network.put(Links.parameter(), payload));
        } else {
            parameterId = yield call(Network.post(Links.parameter(), payload));
        }
        yield put(Actions.fulfilled(action.type, parameterId));
        yield put(Alert.success("Parameter has been successfully saved."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* deleteById(action: DeleteParameterByIdActionInterface) {
    try {
        yield call(Network.del(Links.parameterById(action.id)));
        yield put(Actions.fulfilled(action.type));
        yield put(Alert.success("Parameter has been successfully deleted."));
        yield all(Callback.invokeSuccessCallbacks(action.callbacks));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error(getExceptionMessage(e.message)));
        yield all(Callback.invokeErrorCallbacks(action.callbacks));
    }
}

function* getAllByTaskId(action: GetAllParametersByTaskIdActionInterface) {
    try {
        const parameters = yield call(Network.get(Links.parametersByTaskId(action.taskId, action.page?.page, action.page?.size)));
        yield put(Actions.fulfilled(action.type, mapPageFromJS(parameters, mapParameterFromJS)));
    } catch (e) {
        yield put(Actions.rejected(action.type, e));
        yield put(Alert.error());
    }
}

export default function* saga() {
    yield all([
        yield takeLatest(PARAMETER_SAVE, save),
        yield takeLatest(PARAMETER_DELETE_BY_ID, deleteById),
        yield takeLatest(PARAMETER_GET_ALL_BY_TASK_ID, getAllByTaskId)
    ])
}